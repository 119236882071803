import Flex from "core/components/common/Flex";
import styled from "styled-components";

interface FormItemProps {
  title: string;
  description?: string;
  children?: React.ReactNode;
}

export default function FormItem({
  title,
  children,
  description,
}: FormItemProps) {
  return (
    <Flex direction="column" gap="8px">
      <Flex width="100%" justify="space-between" align="center">
        <Title>{title}</Title>
        {description && <Description>{description}</Description>}
      </Flex>
      {children}
    </Flex>
  );
}

const Title = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #222222;
`;

const Description = styled.div`
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #999999;
`;
