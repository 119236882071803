import Config from "@/env/Config";
import Button from "core/components/common/Button";
import { ModalType } from "core/constants/enums";
import { fileToDataURL } from "core/utils/index";
import React, { useState } from "react";
import styled, { css } from "styled-components";
import CP from ".";
import CustomFileUploadWrapper from "../fileupload/CustomFileUploadWrapper";
import CustomFileViewer from "../fileupload/CustomFileViewer";
// import Config from "../../env/Config";

interface IProps {
  style?: object;
  textStyle?: object;
  value?: UploadFileType[];
  onChange?: (e: any) => void;
  isButton?: boolean;
  deleteYn?: boolean; //삭제 가능여부, 가능 시 태그로 표시
  onDelete?: (e: any) => void;
  buttonVariant?: "active" | "outline" | "ghost";
  buttonStyle?: any;
  disabled?: boolean;
  isPreview?: boolean;
  onClickPreview?: (item: any) => void;
  multiple?: boolean;
  baseUploadUrl?: string;
  isViewer?: boolean;
  onClickButton?: () => void;
}

export interface UploadFileType {
  name: string; //보여줄 이름
  file?: any; //파일 전체
  data?: string; //Base64
  type?: string; //확장자
  idx?: string; //key
}
const UPLOAD_URL = Config.publicBaseUploadUrl + "/staff_photo";

export default function Upload({
  style,
  onChange,
  textStyle,
  isButton = true,
  value,
  multiple = false,
  buttonVariant,
  buttonStyle,
  deleteYn = false,
  onDelete,
  disabled,
  isPreview = false,
  onClickPreview,
  baseUploadUrl = UPLOAD_URL,
  isViewer = false,
  onClickButton
}: IProps) {
  const fileInput = React.useRef(null);
  const aRef = React.useRef(null);

  const [fileName, setFileName] = useState<UploadFileType[]>([]);
  const [showWindow, setShowWindow] = useState(false);
  const [showFile, setShowFile] = useState(undefined);

  const toggleWindow = (bool: boolean) => {
    setShowWindow(bool);
    if (!bool) {
      setShowFile(undefined);
    }
  };

  const handleInputClick = async (e: any) => {
    if (disabled) return;
    fileInput.current.click();
  };
  const handleButtonClick = async (e: any) => {
    if (isViewer) return onClickButton();
    else if (disabled) return;
    fileInput.current.click();
  };

  const handleChange = async (e: any) => {
    const files = e.target.files;

    let addFiles = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (onChange && file) {
        const blobImage = await fileToDataURL(file);
        addFiles.push({
          name: file?.name,
          data: blobImage?.source,
          file: file,
          type: file?.name
            ? file?.name.substring(
                file.name.lastIndexOf(".") + 1,
                file.name.length
              )
            : ""
        });
      } else {
        setFileName(fileName.concat([{ name: file?.name }]));
      }
    }

    if (onChange) {
      onChange(addFiles);
    }
  };

  const fileTextSetting = (data: UploadFileType[]) => {
    let msg = "";
    data?.map((item) => {
      if (msg !== "") {
        msg += ", ";
      }
      msg += item.name;
    });
    return msg;
  };

  const handleOnClickPreview = (e: any) => {
    let viewData;
    if (e) {
      const { data, name } = e;
      if (!(data || name)) {
        CP.alert("오류가 발생하였습니다. ", {});
        return;
      }

      viewData = data ? data : name;
    }

    if (viewData.endsWith(".")) {
      CP.alert("오류가 발생하였습니다.", {});
    }
    //이미지 파일이거나 새로 올린 파일일 경우
    if (
      viewData.startsWith("data:image") ||
      viewData.endsWith("png") ||
      viewData.endsWith("jpg") ||
      viewData.endsWith("PNG") ||
      viewData.endsWith("JPG") ||
      viewData.endsWith("gif") ||
      viewData.endsWith("GIF") ||
      viewData.endsWith("jpeg") ||
      viewData.endsWith("JPEG")
    ) {
      setShowFile(e);
      setTimeout(() => {
        setShowWindow(true);
      }, 300);

      return;
    } else {
    }
  };

  return (
    <CustomFileUploadWrapper
      title=""
      files={value}
      modalType={disabled ? ModalType.View : ModalType.Update}
      onChange={(e) => onChange(e)}
      onDelete={(idx, file) => {
        onDelete(file);
      }}
    />
  );
  return (
    <>
      {deleteYn ? (
        <>
          <StyledDiv
            wrap={typeof value !== "string" && value?.length > 0 ? true : false}
          >
            <div
              style={{
                width:
                  typeof value !== "string" && value?.length > 0
                    ? "auto"
                    : "100%",
                display: "flex",
                gap: 8,
                // justifyContent: 'space-between',
                padding:
                  typeof value !== "string" && value?.length > 0 && "0px 16px "
              }}
            >
              {typeof value !== "string" && value?.length > 0 ? (
                <TagDiv>
                  {value.map((item) => {
                    // console.log({ item }, isArray(item));

                    // if (isArray(item) && item?.length > 0) {
                    //   item = item[0];
                    // }

                    const getImageName = () => {
                      const split = item?.name.split("/");

                      const name = split[split.length - 1];
                      if (!name) return "file." + item.type;

                      return name.length > 20
                        ? name.substring(0, 20) + "..."
                        : name;
                    };
                    return (
                      <div
                        style={{
                          height: "100%",
                          padding: "4px 0px"
                        }}
                      >
                        <CP.Tag
                          value={item}
                          deleteYn={disabled ? false : deleteYn}
                          onDelete={disabled ? () => {} : onDelete}
                          onClick={
                            onClickPreview
                              ? onClickPreview
                              : handleOnClickPreview
                          }
                          style={{
                            maxHeight: 24
                          }}
                          textStyle={{
                            fontSize: "13px",
                            lineHeight: "16px",
                            whiteSpace: "nowrap"
                          }}
                        >
                          {getImageName()}
                        </CP.Tag>
                      </div>
                    );
                  })}
                </TagDiv>
              ) : (
                <>
                  <StyledInput
                    className="qwewe"
                    onClick={handleInputClick}
                    style={{ border: isViewer ? "0px" : "1px solid #999" }}
                  />
                </>
              )}
            </div>

            {isButton && !disabled && (
              <StyledButton
                onClick={handleButtonClick}
                style={buttonStyle}
                variant={buttonVariant ? buttonVariant : "active"}
              >
                파일찾기
              </StyledButton>
            )}

            {isButton &&
              typeof value !== "string" &&
              value?.length > 0 &&
              isViewer && (
                <StyledButton
                  onClick={handleButtonClick}
                  style={buttonStyle}
                  variant={buttonVariant ? buttonVariant : "active"}
                >
                  파일보기
                </StyledButton>
              )}

            <input
              type="file"
              multiple={multiple}
              ref={fileInput}
              onChange={handleChange}
              style={{ display: "none" }}
            />
          </StyledDiv>
        </>
      ) : (
        <StyledDiv>
          <StyledInput
            // type="text"
            onClick={handleInputClick}
            // disabled={true}
            style={textStyle}
          >
            {value && value?.length > 0
              ? fileTextSetting(value)
              : fileTextSetting(fileName)}
          </StyledInput>
          {isButton && (
            <StyledButton
              onClick={handleButtonClick}
              style={buttonStyle}
              variant={buttonVariant ? buttonVariant : "active"}
            >
              파일찾기
            </StyledButton>
          )}

          <input
            type="file"
            multiple={multiple}
            ref={fileInput}
            onChange={handleChange}
            style={{ display: "none" }}
          />
        </StyledDiv>
      )}

      {showFile && (
        <a
          target="_blank"
          href={
            showFile?.data
              ? showFile.data
              : `${baseUploadUrl}${showFile?.name.replace("//uploads", "")}`
          }
          ref={aRef}
          download
          style={{ display: "none" }}
          rel="noreferrer"
        />
      )}

      {showWindow && (
        <CustomFileViewer
          isPaging={false}
          files={[showFile]}
          path={``}
          isOpen={showWindow}
          title={"IMAGE"}
          close
          setIsOpen={() => setShowWindow(false)}
          modalType={ModalType.View}
          edit={false}
          download
        />
        // <DepWindow
        //   title="증빙파일 미리보기"
        //   width={600}
        //   height={600}
        //   closeWindowPortal={() => toggleWindow(false)}
        // >
        //   <img
        //     src={
        //       showFile.data
        //         ? showFile.data
        //         : `${baseUploadUrl}${showFile.name.replace('//uploads', '')}`
        //     }
        //     style={{ width: '100%', height: '100%', objectFit: 'fill' }}
        //   />
        // </DepWindow>
      )}
    </>
  );
}

const TagDiv = styled.div`
  // background: #faa;
  display: flex;
  gap: 4px;
  overflow-x: auto;
  ::-webkit-scrollbar {
    display: none;
  }
`;
const StyledDiv = styled.div<{ wrap?: boolean }>`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  overflow-x: auto;
  justify-content: space-between;
  position: relative;
`;
const StyledInput = styled.div`
  background-color: #fff;
  border: 1px solid #999;
  border-radius: 4px;
  height: 32px;
  width: 100%;
  max-width: 100%;
  padding: 5px;
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  // margin-right: 85px;
`;

const StyledButton = styled(Button)<{ variant?: string }>`
  white-space: nowrap;
  ${(props) =>
    !props.variant &&
    css`
      background-color: #999999;
      border: 1px solid #555555;
    `}
  border-radius: 4px;
  padding: 8px 16px;
  height: 32px;
  font-size: 13px;
  font-weight: 400;
  height: 32px;
  width: 77px;
  position: sticky;
  right: 0;
`;
