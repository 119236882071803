import styled from "styled-components";
import FormItem from "core/components/fileupload/FormItem";
import { ModalType } from "core/constants/enums";
import { UploadFileType } from "../common/Upload";
import ImageFileUpload from "./CustomFileUpload";

interface Props {
  title?: string;
  description?: string;
  files: UploadFileType[];
  modalType?: ModalType;
  onChange: (e: any) => void;
  onDelete: (idx: number, file: UploadFileType) => void;
  isPossibleUploadFile?: boolean
  isPossibleDeleteFile?: boolean
}
const CustomFileUploadWrapper = ({
  title = "이미지 정보",
  description = "전체 10MB 이하 최대 8장까지 등록",
  files,
  modalType = ModalType.View,
  onChange,
  onDelete,
  isPossibleUploadFile = true,
  isPossibleDeleteFile = true,
}: Props) => {
  return (
    <FormItem title={title} description={description}>
      <ImagesBox>
        <ImageFileUpload
          path={``}
          files={files}
          disabled={modalType === ModalType.View}
          // onChange={e => {
          //   setImageFileList(imageFileList.concat(e));
          //   if (!changeImageFile) setChangeImageFile(true);
          // }}
          onChange={onChange}
          onDelete={onDelete}
          isPossibleUploadFile={isPossibleUploadFile}
          isPossibleDeleteFile={isPossibleDeleteFile}
        />
      </ImagesBox>
    </FormItem>
  );
};

const ImagesBox = styled.div`
  padding: 8px;
  background-color: #fefefe;
  border-radius: 8px;
  border: 1px solid #f3f3f3;
`;

export default CustomFileUploadWrapper;
