import classnames from "classnames/bind";
import CP from "core/components/common";
import Flex from "core/components/common/Flex";
import { ArrowUpIcon, CloseIcon } from "core/components/common/icons";
import * as S from "core/components/Styled";
import { ModalType } from "core/constants/enums";
import { throttle } from "lodash";
import { useEffect, useRef, useState } from "react";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import styled from "styled-components";
import styles from "./BottomMenu.module.scss";

const cx = classnames.bind(styles);
interface Props {
  open: boolean; //바텀 메뉴 오픈
  onDismiss: () => void; //바텀 메뉴 닫기
  onDismissFnc?: () => void;
  height?: number; //바텀 높이 % /  ex.60% => 60
  title?: string | any; //상단 타이틀
  subTitle?: any; //타이틀 옆 영역
  header?: any; // 헤더 오른쪽 영역 (확장 버튼 왼쪽으로 들어가짐)
  close?: boolean; // 헤더 닫기 x버튼 노출 여부
  expansion?: boolean; //헤더 확장 버튼 노출 여부  높이 90%
  children?: any; // 컨텐츠 영역
  bottom?: any; // 하단 영역
  bottomSpace?: "start" | "center" | "end"; // 하단 영역 노출 위치
  bottomStyle?: any;
  printRef?: any;
  divide?: any;
  divideChildren?: any;
  modalType?: ModalType;
  onClickEdit?: (e: any) => void;
  editYn?: boolean;
  // form?: boolean;

  bottomYn?: boolean;
  onCancel?: (e: any) => void; //취소
  onUpdate?: (e: any) => void; //저장
  onCreate?: (e: any) => void; //등록
  onDelete?: (e: any) => void; //삭제
  otherButton?: any; //바텀 왼쪽 다른 버튼 넣을 때 버튼 자체 넣기
}

export default function BottomMenu({
  open,
  onDismiss,
  height,
  title,
  subTitle,
  header,
  close = true,
  expansion = false,
  children,
  bottom = undefined,
  bottomSpace = "end",
  bottomStyle = {},
  printRef,
  onDismissFnc,
  divide, //사용안함
  divideChildren, //사용안함
  modalType = ModalType.None,
  onClickEdit,
  editYn = true,
  bottomYn = true,
  onCancel,
  onUpdate,
  onCreate,
  onDelete,
  otherButton,
}: // form,
Props) {
  const [expansionYn, setExpansionYn] = useState(false);
  const ref = useRef([]);
  const [bottomHeight, setBottomHeight] = useState(0);

  useEffect(() => {
    lockScroll(open);
    if (!open) return;
    resieFnc();
  }, [open]);

  useEffect(() => {
    if (!ref) return;
    handleResize();
  }, [ref]);

  useEffect(() => {
    window.addEventListener("load", resieFnc);
    window.addEventListener("resize", resieFnc);
  }, []);

  const lockScroll = (isLock: boolean) => {
    try {
      const divTables = document.getElementsByTagName("table");
      if (divTables) {
        for (let i = 0; i < divTables.length; i++) {
          const obj = divTables[i];
          if (!obj) return;
          else if (!obj.parentElement) return;
          else if (isLock) obj.parentElement.style.overflow = "hidden";
          else obj.parentElement.style.overflow = "auto";
        }
      }
    } catch (err) {}
  };
  const resieFnc = () => {
    setTimeout(() => {
      handleResize();
    }, 100);
  };

  const handleResize = throttle(() => {
    let _height: number = 0;
    // if (ref && ref?.current?.length > 0) {

    ref?.current?.map((item: any) => {
      const _offsetHeight: any = item?.offsetHeight;

      if(!_offsetHeight)return;

      _height = _offsetHeight > _height ? _offsetHeight : _height;
    });

    _height += 56 + 32; //헤더, 푸터, 패딩값 더하기

    if (bottomYn) {
      _height += 72;
    }
    _height += 10; //여백
    // }
    setBottomHeight(_height);
  }, 100);

  return (
    <SW
      open={open}
      initialFocusRef={false}
      expansionYn={expansionYn}
      expandOnContentDrag={true}
      onDismiss={
        onDismissFnc
          ? () => {
              onDismissFnc();
              onDismiss();
            }
          : onDismiss
      }
      // height={height ? height : expansionYn && 90}
      height={
        !height ? bottomHeight + "px" : expansionYn ? "90%" : height + "%"
      }
      scrollLocking={true}
      header={false}
    >
      <Flex direction="column" align="flex-start" style={{ height: "100%" }}>
        <Header>
          <Flex direction="row" align="center" justify="flex-start">
            {typeof title === "string" ? (
              <S.StyledText type="title" style={{ marginRight: 32 }}>
                {title}
              </S.StyledText>
            ) : (
              title
            )}
            {subTitle && subTitle}
          </Flex>

          <ControlArea>
            {header && header}
            {expansion && (
              <ArrowUpIcon
                onClick={() => setExpansionYn(!expansionYn)}
                style={{
                  transform: expansionYn ? "rotate(180deg)" : "rotate(0deg)",
                }}
              />
            )}

            {editYn && modalType === ModalType.View ? (
              <CP.Flex gap="8px">
                <CP.Icon.EditIcon onClick={onClickEdit} />
                {close && (
                  <CloseIcon
                    width={24}
                    height={24}
                    onClick={(e: any) => {
                      e.stopPropagation();
                      onDismissFnc
                        ? () => {
                            onDismissFnc();
                            onDismiss();
                          }
                        : onDismiss();
                    }}
                  />
                )}
              </CP.Flex>
            ) : (
              close && (
                <CloseIcon
                  width={24}
                  height={24}
                  onClick={(e: any) => {
                    e.stopPropagation();
                    onDismissFnc
                      ? () => {
                          onDismissFnc();
                          onDismiss();
                        }
                      : onDismiss();
                  }}
                />
              )
            )}
          </ControlArea>
        </Header>
        <ContentArea style={{ marginBottom: bottom ? "72px" : "0px" }}>
          <Content
            className="bottomContent"
            scroll={true}
            bottom={bottom ? 72 : 0}
          >
            <StyleRefDiv ref={(el: any) => (ref.current[0] = el)}>
              <div className={cx("refDiv", styles.printDiv)} ref={printRef}>
                {children}
              </div>
            </StyleRefDiv>
          </Content>
        </ContentArea>
        {bottomYn && (
          <Bottom space={bottomSpace} style={bottomStyle}>
            {bottom ? (
              bottom
            ) : (
              <>
                {modalType && (
                  <CP.Flex
                    style={{ width: "100%" }}
                    justify={
                      !onDelete && !otherButton ? "flex-end" : "space-between"
                    }
                  >
                    <CP.Flex gap={"8px"}>
                      {onDelete && modalType === ModalType.View && (
                        <CP.Button
                          variant="outline"
                          color={"black"}
                          height={40}
                          onClick={onDelete}
                          style={{ width: 105, padding: "8px" }}
                        >
                          삭제
                        </CP.Button>
                      )}
                      {otherButton && otherButton}
                    </CP.Flex>

                    <CP.Flex gap={"8px"}>
                      {modalType === ModalType.View && (
                        <CP.Button
                          variant="outline"
                          onClick={onDismiss && onDismiss}
                          color={"black"}
                          style={{ width: 105, padding: "8px" }}
                          height={40}
                        >
                          닫기
                        </CP.Button>
                      )}

                      {modalType === ModalType.Update && (
                        <>
                          {onCancel && (
                            <CP.Button
                              variant="outline"
                              onClick={onCancel}
                              color={"black"}
                              style={{ width: 105, padding: "8px" }}
                              height={40}
                            >
                              취소
                            </CP.Button>
                          )}

                          {onUpdate && (
                            <CP.Button
                              type="submit"
                              style={{
                                display: "flex",
                                gap: 4,
                                width: 105,
                              }}
                              height={40}
                              onClick={onUpdate}
                            >
                              저장
                            </CP.Button>
                          )}
                        </>
                      )}

                      {modalType === ModalType.Create && (
                        <>
                          <CP.Button
                            variant="outline"
                            onClick={onDismiss && onDismiss}
                            color={"black"}
                            style={{ width: 105, padding: "8px" }}
                            height={40}
                          >
                            취소
                          </CP.Button>

                          {onCreate && (
                            <CP.Button
                              onClick={onCreate}
                              color={"black"}
                              style={{ width: 105, padding: "8px" }}
                              height={40}
                            >
                              등록
                            </CP.Button>
                          )}
                        </>
                      )}
                    </CP.Flex>
                  </CP.Flex>
                )}
              </>
            )}
          </Bottom>
        )}
      </Flex>
    </SW>
  );
}

const SW = styled(BottomSheet)<{ height?: string; expansionYn?: boolean }>`
  [data-rsbs-overlay] {
    max-height: 95%;
    height: ${({ height, expansionYn }) =>
      expansionYn ? "90%" : height ? height : "auto"};

    border-radius: 20px 20px 0px 0px;
    z-index: 4; // 기본값은 3이지만 backdrop에 맞춤
  }
  [data-rsbs-scroll] {
    overflow: hidden;
  }
  [data-rsbs-content] {
    height: 100%;
  }
  [data-rsbs-backdrop] {
    z-index: 4; // 기본값은 3이지만 메인 페이지의 가장 높은 값인 헤더가 4라 그에 맞춤
  }

  &.table {
    overflow: hidden !important;
  }
`;

const Header = styled.div`
  background-color: #fff;
  border-radius: 20px 20px 0px 0px;
  width: 100%;
  padding: 0px 24px;
  min-height: 56px;
  height: 56px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  z-index: 99;
  overflow: hidden;
`;

const ControlArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  overflow: hidden;
  height: 100%;
`;

const ContentArea = styled.div`
  // margin-top: 74px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
`;
const Content = styled.div<{
  flex?: number;
  padding?: number;
  scroll?: boolean;
  bottom?: number;
  // divide?: number;
}>`
  position: absolute;
  flex: ${(props) => props.flex};
  width: 100%;
  height: calc(100% - 56px - ${({ bottom }) => `${bottom ? bottom : 0}px `});

  padding: ${({ padding }) => padding || "0px"};
  // padding: 0px;
  overflow: ${({ scroll }) => (scroll ? "auto" : "hidden")};
`;
const Bottom = styled(S.StyledBottom)<{ space?: string }>`
  z-index: 1;
  justify-content: ${(props) =>
    props.space
      ? props.space === "center"
        ? props.space
        : "flex-" + props.space
      : ""};
`;

const StyleRefDiv = styled.div`
  // height: -webkit-fill-available;
`;
