import * as S from "core/components/Styled";
import styled from "styled-components";

interface IProps {
  data: {
    name: string;
    value: string | number | any;

    addonBefore?: string; //해당 데이터 뒤에 붙는 수식이 있을 때
    addonAfter?: string; //해당 데이터 앞에 붙는 수식이 있을 때
  }[];
  addonBefore?: string; //공통적으로 뒤에 붙는 수식이 있을 때
  addonAfter?: string; //공통적으로 앞에 붙는 수식이 있을 때
  title?: string;
}

export default function Radio({
  data,
  addonBefore = "",
  addonAfter = "",
  title = "",
}: IProps) {
  return (
    <Container>
      {title && <Heading>{title}</Heading>}
      <StyleTable>
        <tr>
          {data.map((datum, idx) => (
            <td key={idx}>
              <StyleText type="title" weight={"400"} color={"#453F85"}>
                {datum.name}
              </StyleText>

              {typeof datum.value === "string" ||
              typeof datum.value === "number" ? (
                <StyleText
                  type="bold"
                  size={"20px"}
                  lineHeight={"24px"}
                  color={"#453F85"}
                >
                  {datum?.addonBefore ? datum.addonBefore : addonBefore}
                  {datum.value && datum.value.toLocaleString()}
                  {datum?.addonAfter ? datum.addonAfter : addonAfter}
                </StyleText>
              ) : (
                datum.value
              )}
            </td>
          ))}
        </tr>
      </StyleTable>
    </Container>
  );
}
const Container = styled.div`
  padding: 16px 24px;
  margin-left: -24px;
  margin-right: -24px;
  border-bottom: 1px solid #ccc;
  // border-top: 1px solid #ccc;
  // width: 100%;
`;

const Heading = styled.h2`
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  color: ${({ theme }) => theme.colors.black222};
  padding-bottom: 16px;
`;

const StyleText = styled(S.StyledText)`
  text-wrap: nowrap;
`;
const StyleTable = styled.table`
  // margin: 16px 0;
  width: 100%;
  height: 66px;

  > tr {
    display: flex;
    width: 100%;

    border: 1px solid #6c62d1;
    border-radius: 4px;
    > td {
      display: flex;
      flex-direction: column;
      flex: 1;
      padding: 8px;
      justify-content: center;
      align-items: center;
      border-right: 1px solid #6c62d1;
      gap: 4px;
      &:last-child {
        border-right: 0px;
      }
    }
  }
`;
