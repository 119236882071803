import * as S from "adpia/components/style";
import { Checkbox as CheckboxComp } from "antd";
import { theme } from "core/styles/theme";
import styled from "styled-components";

interface CheckBoxProps {
  checked?: boolean;
  onClick?: (value: any, e?: any) => void;
  ref?: any;
  label?: string;
  value?: any;
  disabled?: boolean;
  style?: any;
  labelStyle?: any;
  error?: boolean;
}

export type CheckBoxItem = {
  label?: string;
  value: string | number;
  disabled?: boolean;
};

interface CheckBoxListProps {
  list?: CheckBoxItem[] | string[] | string;
  checked?: CheckBoxItem | string;
  onClick?: (value: CheckBoxItem) => void;
  ref?: any;
  error?: boolean;
}

export default function CheckBox({
  checked = undefined,
  onClick,
  ref,
  disabled,
  label,
  value,
  style,
  labelStyle,
  error
}: CheckBoxProps) {
  return (
    <>
      <StyledCheckbox
        disabled={disabled}
        value={value && value}
        onClick={onClick}
        checked={checked && checked}
        style={style}
        error={error}
      >
        {label && (
          <S.StyledText
            type="subTitle"
            color={theme.colors.black222}
            style={labelStyle}
          >
            {label}
          </S.StyledText>
        )}
      </StyledCheckbox>
    </>
  );
}

const StyledCheckbox = styled(CheckboxComp)<{ error?: boolean }>`
  & .ant-checkbox-checked .ant-checkbox-inner {
    border-color: ${({ theme, error }) =>
      error ? "#f00" : theme.colors.primary} !important;
    background-color: ${({ theme, error }) =>
      error ? "#f00" : theme.colors.primary} !important;
  }

  .ant-checkbox-disabled .ant-checkbox-inner::after {
    border-color: #fff !important;
  }
`;
