import { DocumentApprovalListType } from "core/constants/enums";

export type Menu = {
  name: string;
  link?: string;
  icon?: string | JSX.Element;
  children?: Menu[];
  breadcrumb?: string;
};

export const SIDEBAR_DATA: Menu[] = [
  {
    name: "경영관리",
    icon: "/images/hr.svg",
    link: "/adpia/hr",
    children: [
      {
        name: "인사관리",
        link: "/adpia/hr/employee",
        children: [
          {
            name: "사원관리",
            link: "/adpia/hr/employee/employee-list"
          },
          {
            name: "채용관리",
            link: "/adpia/hr/employee/hire"
          },
          {
            name: "급여관리",
            link: "/adpia/hr/employee/salary",
            children: [
              {
                name: "정산관리",
                link: "/adpia/hr/employee/salary/manual"
              }
            ]
          },
          {
            name: "연차관리",
            link: "/adpia/hr/employee/vacation",
            children: [
              {
                name: "월별현황(리스트)",
                link: "/adpia/hr/employee/vacation/usage"
              },
              {
                name: "월별현황(캘린더)",
                link: "/adpia/hr/employee/vacation/schedule"
              },
              {
                name: "결재문서",
                link: "/adpia/hr/employee/vacation/approval"
              }
            ]
          },
          {
            name: "수당관리",
            link: "/adpia/hr/employee/overtime",
            children: [
              {
                name: "결재문서",
                link: "/adpia/hr/employee/overtime/approval"
              }
            ]
          },

          {
            name: "조직관리",
            link: "/adpia/hr/employee/group"
          },
          {
            name: "근태관리",
            link: "/adpia/hr/employee/attendance",
            children: [
              {
                name: "외근현황(월별)",
                link: "/adpia/hr/employee/attendance/outside"
              }
            ]
          },
          {
            name: "근무조관리",
            link: "/adpia/hr/employee/workTime"
          },
          {
            name: "일용급여",
            link: "/adpia/hr/employee/parttime"
          },
          {
            name: "도급관리",
            link: "/adpia/hr/employee/contract"
          },
          {
            name: "도급급여",
            link: "/adpia/hr/employee/contractSalary"
          }
        ]
      },
      {
        name: "문서관리",
        link: "/adpia/hr/docs",
        children: [
          {
            name: "문서관리",
            link: "/adpia/hr/document/document"
          },
          {
            name: "기획물제작관리",
            link: "/adpia/hr/document/project"
          }
        ]
      },
      {
        name: "회계관리",
        link: "/adpia/hr/account",
        children: []
      },
      {
        name: "운영관리",
        link: "/adpia/hr/operation",
        children: [
          {
            name: "법인카드관리",
            link: "/adpia/hr/operation/corporation-card",
            children: [
              {
                name: "법인카드관리 (상세)",
                link: "/adpia/hr/operation/corporation-card/detail"
              }
            ]
          },
          {
            name: "시설관리",
            link: "/adpia/hr/operation/facility"
          },
          {
            name: "사내공지",
            link: "/adpia/hr/operation/notification"
          },
          {
            name: "교육일지",
            link: "/adpia/hr/operation/education"
          },
          {
            name: "연간연차계획",
            link: "/adpia/hr/operation/annualPlan"
          },
          {
            name: "임원스케줄관리",
            link: "/adpia/hr/operation/executiveSchedule"
          },
          ,
          {
            name: "스케줄관리",
            link: "/adpia/hr/operation/schedule"
          }
        ]
      },
      {
        name: "전자결재",
        link: "/adpia/hr/e-approval",
        children: [
          {
            name: "전체문서",
            link:
              "/adpia/hr/e-approval/list?smode=" + DocumentApprovalListType.All
          },
          {
            name: "문서작성",
            link: "/adpia/hr/e-approval/create-document-list"
          },
          {
            name: "미결내역",
            link: "/adpia/hr/e-approval/pending-history"
          },
          {
            name: "상신함",
            link:
              "/adpia/hr/e-approval/list?smode=" +
              DocumentApprovalListType.Regist
          },
          {
            name: "수신/참조함",
            link:
              "/adpia/hr/e-approval/list?smode=" +
              DocumentApprovalListType.Receive
          },
          {
            name: "기결함",
            link:
              "/adpia/hr/e-approval/list?smode=" +
              DocumentApprovalListType.Accept
          },
          {
            name: "반려함",
            link:
              "/adpia/hr/e-approval/list?smode=" +
              DocumentApprovalListType.Reject
          },
          {
            name: "미결함",
            link:
              "/adpia/hr/e-approval/list?smode=" +
              DocumentApprovalListType.Undecid
          },
          {
            name: "시행함",
            link:
              "/adpia/hr/e-approval/list?smode=" +
              DocumentApprovalListType.Implement
          },
          {
            name: "인감사용내역",
            link: "/adpia/hr/e-approval/seal-usage-history"
          },
          {
            name: "계약직전환현황",
            link: "/adpia/hr/e-approval/convertion-contract-report"
          }
        ]
      },
      {
        name: "전자결재문서관리",
        link: "/adpia/hr/e-approval/approval",
        children: [
          {
            name: "전자결재문서관리",
            link: "/adpia/hr/e-approval/approval"
          },
          {
            name: "기본결재관리",
            link: "/adpia/hr/e-approval/approval/basic"
          }
        ]
      }
    ]
  },
  {
    name: "주문접수",
    icon: "/images/order.svg",
    link: "/adpia/order",
    children: [
      {
        name: "주문내역",
        link: "/adpia/order/",
        children: [
          {
            name: "주문조회",
            link: "/adpia/order/list"
          },
          {
            name: "방문접수",
            link: "/adpia/order/visit"
          },
          {
            name: "관심주문",
            link: "/adpia/order/fav"
          },
          {
            name: "누락주문",
            link: "/adpia/order/omission"
          },
          {
            name: "완성품주문",
            link: "/adpia/order/goods"
          },
          {
            name: "CS요청리스트",
            link: "/adpia/order/csrequest"
          }
        ]
      },
      {
        name: "AS/수정",
        link: "/adpia/orderas",
        children: [
          {
            name: "수정요청내역",
            link: "/adpia/orderas/list"
          },
          {
            name: "수정처리내역",
            link: "/adpia/orderas/jobfinish"
          },
          {
            name: "주문취소내역",
            link: "/adpia/orderas/jobcancel"
          },
          {
            name: "재작업내역",
            link: "/adpia/orderas/rejob"
          },
          {
            name: "상담요청내역",
            link: "/adpia/orderas/qnajob"
          },
          {
            name: "고객재접수/취소내역",
            link: "/adpia/orderas/csrequest"
          },
          {
            name: "별도금액처리내역",
            link: "/adpia/orderas/separate"
          }
        ]
      },
      {
        name: "원청관리",
        link: "/adpia/orderprime",
        children: [
          {
            name: "가결제주문내역",
            link: "/adpia/orderprime/temppayment"
          },
          {
            name: "세금계산서발행내역",
            link: "/adpia/orderprime/taxinvoice"
          }
        ]
      }
    ]
  },
  {
    name: "생산공정",
    icon: "/images/production.svg",
    link: "/adpia/production",
    children: [
      {
        name: "조판관리",
        link: "/adpia/production/typesetting",
        children: [
          {
            name: "조판내역",
            link: "/adpia/production/typesetting/"
          },
          {
            name: "자동조판내역",
            link: "/adpia/production/typesetting/"
          },
          {
            name: "핫폴더리스트",
            link: "/adpia/production/typesetting/"
          },
          {
            name: "장비기본핫폴더",
            link: "/adpia/production/typesetting/"
          },
          {
            name: "실사주문리스트",
            link: "/adpia/production/typesetting/"
          },
          {
            name: "실사 조판내역",
            link: "/adpia/production/typesetting/"
          },
          {
            name: "자동조판설정",
            link: "/adpia/production/typesetting/"
          },
          {
            name: "조판검수",
            link: "/adpia/production/typesetting/"
          },
          {
            name: "당일[일일]조판내역",
            link: "/adpia/production/typesetting/"
          },
          {
            name: "옵셋배정관리",
            link: "/adpia/production/typesetting/"
          },
          {
            name: "전차스리스트",
            link: "/adpia/production/typesetting/"
          },
          {
            name: "라벨조판리스트",
            link: "/adpia/production/typesetting/"
          },
          {
            name: "조판관리 메뉴얼",
            link: "/adpia/production/typesetting/"
          }
        ]
      },
      {
        name: "출력/인쇄관리",
        link: "/adpia/production/print",
        children: [
          {
            name: "옵셋기기배정",
            link: "/adpia/production/print/"
          },
          {
            name: "디지털기기배정",
            link: "/adpia/production/print/"
          },
          {
            name: "인쇄진행내역",
            link: "/adpia/production/print/"
          },
          {
            name: "JDF_디지털기기배정",
            link: "/adpia/production/print/"
          },
          {
            name: "출력진행내역",
            link: "/adpia/production/print/"
          },
          {
            name: "CTP추가내역",
            link: "/adpia/production/print/"
          },
          {
            name: "인쇄하청지시서",
            link: "/adpia/production/print/"
          }
        ]
      },
      {
        name: "후가공관리",
        link: "/adpia/production/post",
        children: [
          {
            name: "후가공내역",
            link: "/adpia/production/post/"
          },
          {
            name: "동판관리",
            link: "/adpia/production/post/"
          }
        ]
      },
      {
        name: "재단/포장",
        link: "/adpia/production/cut",
        children: [
          {
            name: "재단진행내역",
            link: "/adpia/production/cut/"
          },
          {
            name: "라벨출력",
            link: "/adpia/production/cut/"
          }
        ]
      },
      {
        name: "생산관리",
        link: "/adpia/production/production",
        children: [
          {
            name: "생산현황",
            link: "/adpia/production/cut/"
          },
          {
            name: "생산요청내역",
            link: "/adpia/production/cut/"
          }
        ]
      },

      {
        name: "외주작업",
        link: "/adpia/production/outsource",
        children: [
          {
            name: "외주하청내역",
            link: "/adpia/production/outsource/"
          },
          {
            name: "외주작업지시서",
            link: "/adpia/production/outsource/"
          },
          {
            name: "생산아르바이트",
            link: "/adpia/production/outsource/"
          },
          {
            name: "보유목형,동판관리",
            link: "/adpia/production/outsource/"
          },
          {
            name: "결재리스트",
            link: "/adpia/production/outsource/"
          },
          {
            name: "월마감리스트",
            link: "/adpia/production/outsource/"
          },
          {
            name: "업체계산서업로드",
            link: "/adpia/production/outsource/"
          },
          {
            name: "업체결재리스트",
            link: "/adpia/production/outsource/"
          },
          {
            name: "외주업체관리",
            link: "/adpia/production/outsource/"
          }
        ]
      }
    ]
  },
  {
    name: "배송출고",
    icon: "/images/delivery.svg",
    link: "/adpia/delivery",
    children: [
      {
        name: "배송현황",
        link: "/adpia/delivery/status",
        children: [
          {
            name: "배송조회",
            link: "/adpia/delivery/status/tracking"
          },
          {
            name: "택배사고",
            link: "/adpia/delivery/status/accident"
          },
          {
            name: "회사배송부담",
            link: "/adpia/delivery/status/comdlv"
          },
          {
            name: "PDA로그",
            link: "/adpia/delivery/status/pda"
          },
          {
            name: "배송통계",
            link: "/adpia/delivery/status/stats"
          },
          {
            name: "택배사배송통계",
            link: "/adpia/delivery/status/dlvstats"
          },
          {
            name: "퀵 배송통계",
            link: "/adpia/delivery/status/quickstats"
          },
          {
            name: "쿽견적관리",
            link: "/adpia/delivery/status/quickadmin"
          },
          {
            name: "사고배송",
            link: "/adpia/delivery/status/accdlv"
          },
          {
            name: "반품리스트",
            link: "/adpia/delivery/status/returnlist"
          },
          {
            name: "택배월마감",
            link: "/adpia/delivery/status/monthly"
          },
          {
            name: "사고택배발송",
            link: "/adpia/delivery/status/accdlvsend"
          },
          {
            name: "경동화물리스트",
            link: "/adpia/delivery/status/kdlist"
          },
          {
            name: "해외배송리스트",
            link: "/adpia/delivery/status/abroadlist"
          },
          {
            name: "EMS통계",
            link: "/adpia/delivery/status/ems"
          },
          {
            name: "성원배송내역",
            link: "/adpia/delivery/status/swdlvhistory"
          }
        ]
      },
      {
        name: "완성품배송현황",
        link: "/adpia/delivery/finished/",
        children: [
          {
            name: "완성품배송조회",
            link: "/adpia/delivery/finished/tracking"
          },
          {
            name: "완성품재고",
            link: "/adpia/delivery/finished/stock"
          }
        ]
      },
      {
        name: "월배송",
        link: "/adpia/delivery/monthly",
        children: [
          {
            name: "월배송내역",
            link: "/adpia/delivery/monthly/history"
          },
          {
            name: "신청내역",
            link: "/adpia/delivery/monthly/requesthistory"
          }
        ]
      },
      {
        name: "주간배송",
        link: "/adpia/delivery/weekly",
        children: [
          {
            name: "주간배송신청",
            link: "/adpia/delivery/weekly/request"
          },
          {
            name: "주간배송내역",
            link: "/adpia/delivery/weekly/history"
          },
          {
            name: "배송지역요약",
            link: "/adpia/delivery/weekly/summary"
          }
        ]
      },
      {
        name: "월배송관리",
        link: "/adpia/delivery/monthadmin",
        children: [
          {
            name: "배송지역요약",
            link: "/adpia/delivery/monthadmin/summary"
          }
        ]
      },
      {
        name: "배송포인트관리",
        link: "/adpia/delivery/point",
        children: [
          {
            name: "포인트내역",
            link: "/adpia/delivery/point/history"
          }
        ]
      }
    ]
  },
  {
    name: "자재·자산",
    icon: "/images/material-asset.svg",
    link: "/adpia/material-asset",
    children: [
      {
        name: "자산관리",
        link: "/adpia/material-asset/asset",
        children: [
          {
            name: "정보기기관리",
            link: "/adpia/material-asset/asset/device"
          },
          {
            name: "장비관리",
            link: "/adpia/material-asset/asset/equipment"
          },
          {
            name: "생산터치정보",
            link: "/adpia/material-asset/asset/production-touch"
          },
          {
            name: "부품정보",
            link: "/adpia/material-asset/asset/part"
          },
          {
            name: "부동산관리",
            link: "/adpia/material-asset/asset/real-estate"
          },
          {
            name: "IP자산관리",
            link: "/adpia/material-asset/asset/ip-asset"
          },
          {
            name: "회사관리",
            link: "/adpia/material-asset/asset/company"
          },
          {
            name: "카테고리관리",
            link: "/adpia/material-asset/asset/category"
          }
        ]
      },
      {
        name: "원자재관리",
        link: "/adpia/material-asset/raw",
        children: []
      },
      {
        name: "부자재관리",
        link: "/adpia/material-asset/sub",
        children: []
      },
      {
        name: "폐자재관리",
        link: "/adpia/material-asset/waste",
        children: []
      },
      {
        name: "소모품관리",
        link: "/adpia/material-asset/consumable",
        children: []
      },
      {
        name: "기타관리",
        link: "/adpia/material-asset/other",
        children: []
      }
    ]
  },
  {
    name: "홈페이지 관리",
    icon: "/images/webpage.svg",
    link: "/adpia/webpage",
    children: [
      {
        name: "게시물관리",
        link: "/adpia/webpage/post",
        children: []
      },
      {
        name: "상품관리",
        link: "/adpia/webpage/product",
        children: []
      },
      {
        name: "작업가이드",
        link: "/adpia/webpage/guide",
        children: []
      },
      {
        name: "지식플러스",
        link: "/adpia/webpage/knowledge",
        children: []
      },
      {
        name: "배너",
        link: "/adpia/webpage/banner",
        children: []
      }
    ]
  },
  {
    name: "장비관리",
    icon: "/images/equipment.svg",
    link: "/adpia/equipment",
    children: [
      {
        name: "AS관리",
        link: "/adpia/equipment/as",
        children: [
          {
            name: "AS내역관리",
            link: "/adpia/equipment/as/as-list"
          },
          {
            name: "부품별AS내역관리",
            link: "/adpia/equipment/as/as-parts-list"
          },
          {
            name: "부품교체리스트",
            link: "/adpia/equipment/as/parts-change-list"
          },
          {
            name: "부품재고",
            link: "/adpia/equipment/as/parts-static-list"
          },
          {
            name: "AS통계",
            link: "/adpia/equipment/as/as-statistics"
          },
          {
            name: "전체통계",
            link: "/adpia/equipment/as/all-statistics"
          },
          {
            name: "업체관리",
            link: "/adpia/equipment/as/as-comp-list"
          },
          {
            name: "부품등록",
            link: "/adpia/equipment/as/as-part-list"
          },
          {
            name: "기타장비관리",
            link: "/adpia/equipment/as/else-equip-list"
          },
          {
            name: "CMS관리",
            link: "/adpia/equipment/as/cms-list"
          },
          {
            name: "월마감리스트",
            link: "/adpia/equipment/as/as-month-confirm-main-list"
          },
          {
            name: "결재리스트",
            link: "/adpia/equipment/as/as-month-list"
          }
        ]
      },
      {
        name: "칼관리",
        link: "/adpia/equipment/knife",
        children: [
          {
            name: "칼/철형리스트",
            link: "/adpia/equipment/cal/cal-list"
          },
          {
            name: "칼형월마감",
            link: "/adpia/equipment/cal/cal-month-list"
          },
          {
            name: "칼교체리스트",
            link: "/adpia/equipment/cal/pkc-list"
          }
        ]
      }
    ]
  },
  {
    name: "현황·통계",
    icon: "/images/status.svg",
    link: "/adpia/status",
    children: [
      {
        name: "주문현황",
        link: "/adpia/status/order",
        children: []
      },
      {
        name: "회원현황",
        link: "/adpia/status/member",
        children: []
      },
      {
        name: "생산현황",
        link: "/adpia/status/production",
        children: []
      },
      {
        name: "운영현황",
        link: "/adpia/status/operate",
        children: [
          {
            name: "근태현황",
            link: "/adpia/status/operate/attendance"
          },
          {
            name: "근태집계현황",
            link: "/adpia/status/operate/totalAttendance"
          },
          {
            name: "콜센터통계현황",
            link: "/adpia/status/operate/callCenter"
          },
          {
            name: "수당현황",
            link: "/adpia/status/operate/bunos"
          }
        ]
      },
      {
        name: "업무현황",
        link: "/adpia/status/task",
        children: []
      },

      {
        name: "회계현황",
        link: "/adpia/status/account",
        children: []
      },
      {
        name: "자재현황",
        link: "/adpia/status/material",
        children: []
      },
      {
        name: "재작업현황",
        link: "/adpia/status/rework",
        children: []
      }
    ]
  },
  {
    name: "고객관리",
    icon: "/images/customer.svg",
    link: "/adpia/customer",
    children: [
      {
        name: "회원관리",
        link: "/adpia/customer/member",
        children: [
          {
            name: "1:1 상담문의",
            link: "/adpia/customer/member/oneonone"
          },
          {
            name: "1:1 상담(A/S)문의",
            link: "/adpia/customer/member/oneonas"
          },
          {
            name: "모범답변관리",
            link: "/adpia/customer/member/modelanswer"
          }
        ]
      },
      {
        name: "고객문의",
        link: "/adpia/customer/question",
        children: [
          {
            name: "회원정보조회",
            link: "/adpia/customer/question/memberlist"
          },
          {
            name: "회원탈퇴조회",
            link: "/adpia/customer/question/withdraw"
          },
          {
            name: "환급계좌관리",
            link: "/adpia/customer/question/refundaccount"
          }
        ]
      },
      {
        name: "결제관리",
        link: "/adpia/customer/payment",
        children: [
          {
            name: "결제내역조회",
            link: "/adpia/customer/payment/list"
          },
          {
            name: "S머니내역",
            link: "/adpia/customer/payment/smoney"
          },
          {
            name: "가상계좌입금",
            link: "/adpia/customer/payment/virtual"
          },
          {
            name: "방문결제내역",
            link: "/adpia/customer/payment/visit"
          },
          {
            name: "온라인카드내역",
            link: "/adpia/customer/payment/onlinecard"
          },
          {
            name: "계좌이체내역",
            link: "/adpia/customer/payment/accounttransfer"
          },
          {
            name: "S포인트내역",
            link: "/adpia/customer/payment/spoint"
          },
          {
            name: "무인결제내역",
            link: "/adpia/customer/payment/unmanned"
          }
        ]
      },
      {
        name: "세금계산서",
        link: "/adpia/customer/tax",
        children: []
      },
      {
        name: "환불관리",
        link: "/adpia/customer/refund",
        children: [
          {
            name: "환불내역",
            link: "/adpia/customer/refund/list"
          },
          {
            name: "자동환불내역",
            link: "/adpia/customer/refund/autorefund"
          },
          {
            name: "자동환불통계",
            link: "/adpia/customer/refund/autorefundstat"
          }
        ]
      }
    ]
  },
  {
    name: "시스템관리",
    icon: "/images/customer.svg",
    link: "/adpia/system",
    children: [
      {
        name: "메뉴관리",
        link: "/adpia/system/menu",
        children: [
          {
            name: "메뉴관리",
            link: "/adpia/system/menu/menu-list"
          },
          {
            name: "메뉴권한관리",
            link: "/adpia/system/menu/auth"
          },
          {
            name: "업무처리메뉴관리",
            link: "/adpia/system/menu/work"
          }
        ]
      }
    ]
  }
];
