import { StyledText } from "core/components/Styled";
import { Bar, BarChart, CartesianGrid, Cell, XAxis, YAxis } from "recharts";
import styled from "styled-components";

export interface DataProps {
  name: string;
  data: number;
  fill?: string; //색상
}
export interface IProps {
  gap?: number;
  min?: number;
  max?: number;
  data: DataProps[];
  title?: string | any;
  subTitle?: string | any;
  style?: any;
  onClick?: (e: any) => void;
  background?: string;
  width?: number;
  height?: number;
}

export default function Chart({
  gap = 10,
  min = 0,
  max = 50,
  data,
  title,
  subTitle,
  style,
  onClick,
  background,
  width,
  height,
}: IProps) {
  const tickFormat = () => {
    if (!(data && data?.length > 0)) return;
    const tickList: number[] = [];

    tickList.push(min);

    const num = max / gap;

    //  console.log({num});

    [...Array(num)].map((num, idx) => {
      tickList.push((idx + 1) * gap);
    });
    return tickList;
  };

  if (!data) return;

  return (
    <div style={style}>
      <TitleWrapper>
        {title && (
          <>
            {typeof title === "string" ? (
              <StyledText color={"#222"} type="title">
                {title}
              </StyledText>
            ) : (
              title
            )}
          </>
        )}

        {subTitle && (
          <>
            {typeof subTitle === "string" ? (
              <StyledText color={"#222"} style={{ whiteSpace: "nowrap" }}>
                {subTitle}
              </StyledText>
            ) : (
              subTitle
            )}
          </>
        )}
      </TitleWrapper>

      <StyleChartWrapper
        onClick={onClick}
        background={background}
        button={onClick ? true : false}
      >
        <BarChart
          width={width ? width : 400}
          height={height ? height : 116}
          data={data}
          margin={{
            // top: 20,
            // right: 30,
            left: -30,
            bottom: -10,
          }}
        >
          <CartesianGrid strokeDasharray="0" />
          <XAxis dataKey="name" />
          <YAxis ticks={tickFormat()} />

          <Bar dataKey="core/data" label={{ position: "top" }}>
            {data?.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={entry?.fill ? entry.fill : "#999"}
              >
                {entry.name}
              </Cell>
            ))}
          </Bar>
        </BarChart>

        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            padding: "0px 16px",
          }}
        >
          {data?.map((item) => {
            return (
              <div
                style={{
                  display: "flex",
                  gap: "4px",
                  alignItems: "center",
                  padding: "0px 16px",
                }}
              >
                <ColorTag color={item.fill} />
                <StyledText weight="700">{item.name}</StyledText>
              </div>
            );
          })}
        </div>
      </StyleChartWrapper>
    </div>
  );
}

const ChartWrapper = styled.button<{ button?: boolean }>`
  background: transparent;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
  width: 100%;
`;
const ColorTag = styled.div<{ color: string; size?: string }>`
  background-color: ${({ color }) => color};
  width: ${({ size }) => size || "8px"};
  height: ${({ size }) => size || "8px"};
  border-radius: 100px;
`;

const StyleChartWrapper = styled.button<{
  background?: string;
  button?: boolean;
}>`
  background: ${({ background }) => (background ? background : "transparent")};
  cursor: ${({ button }) => (button ? "pointer" : "default")} !important;
  border: 1px solid #ccc;
  padding: 24px 8px;
  border-radius: 8px;
  // width : fit-content;

  & .recharts-wrapper {
    cursor: ${({ button }) => (button ? "pointer" : "default")} !important;
  }
  & .recharts-cartesian-grid-vertical {
    display: none;
  }

  & .xAxis {
    display: none;
  }
  & .yAxis {
    & .recharts-cartesian-axis-line {
      display: none;
    }
    & .recharts-cartesian-axis-ticks {
      & .recharts-cartesian-axis-tick-line {
        display: none;
      }
    }
  }

  & .recharts-label {
    fill: #222;
    text-align: center;
    font-family: Pretendard;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  & .recharts-cartesian-grid-horizontal > line:last-child {
    // display : none;
  }
`;
