import * as S from "core/components/Styled";
import styled, { css } from "styled-components";
// import  './Select.scss';
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
// import { Dialog, MenuItem, Select } from '@material-ui/core';
import _, { isArray } from "lodash";
import { useState } from "react";
import uuid from "react-uuid";
import CP from "..";

export type ListItem = { name: string; value: string; key?: string };

interface SelectProps {
  list: ListItem[];
  selected?: ListItem | string | string[]; //| number;
  setSelect?: (value: ListItem) => void;
  className?: string;
  disabled?: boolean;
  status?: "placeholder" | "active";
  style?: any;
  wrapperStyle?: any;
  optionHeight?: number;
  placeHolder?: string;
  error?: boolean;
  minWidth?: number;
  multiple?: boolean;
  readOnly?: boolean;
}

export default function SelectComponent({
  className,
  list,
  selected,
  setSelect,
  disabled = false,
  readOnly = false,
  style,
  wrapperStyle,
  optionHeight,
  placeHolder = "선택",
  error = false,
  minWidth = 130,
  multiple = false
}: SelectProps) {
  const [open, setOpen] = useState(false);

  const isEmpty = selected === "" ? true : false;

  const [id, setId] = useState(uuid());

  const getIsValueEmpty = () => {
    let res: boolean = false;
    if (typeof selected === "string") {
      if (_.isEmpty(selected)) return true;
      res = _.isEmpty(selected);
    } else if (!isArray(selected) && typeof selected == "object")
      res = selected?.value === "" ? true : false;
    else if (_.isEmpty(selected)) return true;
    return res;
  };

  return (
    <SelectWrapper
      open={open}
      error={error}
      style={wrapperStyle}
      minWidth={minWidth}
    >
      <FormControl fullWidth>
        {getIsValueEmpty() && (
          <InputLabel disableAnimation shrink={false} focused={false} id={id}>
            {placeHolder}
          </InputLabel>
        )}
        {/* <InputLabel id="demo-multiple-name-label">{placeHolder}</InputLabel> */}
        <Select
          style={style}
          readOnly={readOnly}
          multiple={multiple}
          autoFocus={false}
          disabled={disabled}
          labelId={id}
          className={`dropdown ${className}`}
          placeholder={placeHolder}
          // displayEmpty={true}
          // renderValue={value => value?.length ? Array.isArray(value) ? value.join(', ') : value : placeHolder}
          value={
            isArray(selected)
              ? selected
              : !selected
              ? ""
              : typeof selected === "string"
              ? selected
              : selected?.value
          }
          // value={selected}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          IconComponent={(props) => {
            return (
              <CP.Icon.ArrowIcon
                className={`${props?.className} selectIcon`}
                style={{
                  transform: !open ? "rotate(90deg)" : "rotate(270deg)",
                  top: "auto"
                }}
              />
            );
          }}
          // labelId="demo-multiple-name-label"
          onChange={(e: any) => {
            // console.log({e}, e?.target.value,  list,  list?.find(item => e.target.value === item.value));

            if (multiple) {
              const val = e?.target.value;

              if (val && val?.length > 0) {
                setSelect(
                  list?.find((item) => val[val?.length - 1] === item.value)
                );
              }
            }

            setSelect(list?.find((item) => e.target.value === item.value));
          }}
        >
          {list?.map((item) => (
            <MenuItem value={item.value} key={uuid()}>
              <S.StyledText>{item.name}</S.StyledText>
            </MenuItem>
          ))}
        </Select>
        {/* </Dialog> */}
      </FormControl>
    </SelectWrapper>
  );
}

const SelectWrapper = styled.div<{
  open: boolean;
  error?: boolean;
  minWidth: number;
}>`
  & .MuiFormLabel-root {
    &[data-shrink="true"] {
      display: none;
    }
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    font-family: Pretendard;
    color: #999;
    transform: inherit;
    top: 8px;
    left: 8px;
  }
  & .MuiButtonBase-root {
    padding: 4px !important;
  }
  & .MuiOutlinedInput-root {
    margin: 0px;
    width: 100%;
    min-width: ${({ minWidth }) => minWidth + "px"};
    height: 32px;
    background: #fff;
  }
  & .MuiPaper-root {
    box-shadow: none;
  }
  &..Mui-disabled {
    background: #eaeaea !important;
    .MuiOutlinedInput-notchedOutline {
      border-color: #999 !important;
    }
  }
  & .MuiInputBase-root {
    // padding-right : 28px;
    & .MuiSelect-select {
      padding: 8px;
      padding-right: 28px;
      background-image: url("public/icon/arrow.svg");
      padding-right: 8px !important;
      display: flex;
      overflow-x: auto;
      gap: 2px;

      & > h1 {
        white-space: nowrap;
      }
    }
    & .MuiOutlinedInput-notchedOutline {
      border-color: ${({ error }) => (error ? "#FF6969" : "#999")};
    }
  }
  .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #6c62d1 !important;
    border-width: 1px !important;
  }

  & .MuiSvgIcon-root {
    // display: none;
  }

  ${({ open }) =>
    open &&
    css`
      & .MuiOutlinedInput-notchedOutline {
        border-color: #6c62d1;
        border-width: 1px !important;
        border-bottom: 0px;
        border-radius: 4px 4px 0px 0px;
      }
    `}
`;
