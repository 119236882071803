import {
  CompanyInfo,
  OrgInfo,
  ResCodeInfo,
  StaffInfo
} from "core/models/Common";

//코드 로 코드 정보 하나 찾기
export const findCodeInfo = (codeInfo: any, code: string): ResCodeInfo => {
  const codeList = codeInfo?.code;
  if (!(codeList && codeList.length > 0)) return undefined;

  return codeList?.find((item: ResCodeInfo) => item.code + "" === code + "");
};

//h_code, ref_key로 해당하는 코드들 리스트 찾기
export const findCodeList = (
  codeInfo: any,
  hCode: string,
  refKey?: string | string[]
): ResCodeInfo[] => {
  const codeList = codeInfo?.code;

  let list: ResCodeInfo[] = [];
  if (!(codeList && codeList.length > 0)) return [];

  if (refKey) {
    if (typeof refKey === "string") {
      list = codeList.filter(
        (item: any) => item.h_code === hCode && item.ref_key + "" === refKey
      );
    } else {
      list = codeList.filter(
        (item: any) =>
          item.h_code === hCode && refKey.indexOf(item.ref_key + "") !== -1
      );
    }
  } else {
    list = codeList.filter((item: any) => item.h_code === hCode);
  }

  return list;
};

//org_no로 부서정보 하나 찾기
export const findOrgInfo = (
  codeInfo: any,
  org_no: string | number
): OrgInfo => {
  // const [codeInfo, setCodeInfo] = useRecoilState(codeState);
  const orgList = codeInfo?.org;

  if (!(orgList && orgList.length > 0)) return undefined;

  const orgNo = org_no + "";

  return orgList.find((item: OrgInfo) => item.org_no + "" === orgNo);
};

//3사 서비스에서 부서에서 찾기
export const findOrgInfoAll = (
  codeInfo: any,
  org_no: string | number
): OrgInfo => {
  // const [codeInfo, setCodeInfo] = useRecoilState(codeState);
  const orgList = codeInfo?.orgAll;

  if (!(orgList && orgList.length > 0)) return undefined;

  const orgNo = org_no + "";

  return orgList.find((item: OrgInfo) => item.org_no + "" === orgNo);
};

//3사 서비스에 있는 특정 부서에서 찾기
export const findOrgInfoAllByOneCompany = (
  codeInfo: any,
  org_no: string | number,
  company: string | number
): OrgInfo => {
  const orgList = codeInfo?.orgAll;

  if (!(orgList && orgList.length > 0)) return undefined;

  return orgList.find((item: OrgInfo) => item.org_no + "" === org_no + "" && item.company + "" === company + "");
};

//staff_id로 사원정보 하나 찾기
export const findStaffInfo = (codeInfo: any, staff_id: string): StaffInfo => {
  const staffList = codeInfo?.staffAll;

  if (!(staffList && staffList.length > 0)) return undefined;
  return staffList.find((item: StaffInfo) => item.staff_id === staff_id);
};

//company_id로 company 하나 찾기
export const findCompanyInfo = (
  codeInfo: any,
  company_id: string
): CompanyInfo => {
  const compList = codeInfo?.company;

  if (!(compList && compList.length > 0)) return undefined;
  return compList.find((item: CompanyInfo) => item.company_id === company_id);
};


export const findOrgChild = ( codeInfo: any, orgNo:string, retList:OrgInfo[] = []):OrgInfo[]=>{
  if(!codeInfo || !codeInfo?.org)return [];

  const {org} = codeInfo;

    if(!orgNo)return  org;


    let orgList:OrgInfo[] = retList ? retList : [];


    const _child: OrgInfo[] = org?.filter(
      (item:OrgInfo) => String(item.parent_org_no) === orgNo
    );

    if (_child && _child?.length > 0) {
      _child?.map((i) => {
        if (!i) return;

        orgList.push(i);
        return findOrgChild(codeInfo, String(i.org_no), orgList);
      });
    }

    orgList = orgList.concat(org?.filter((item:OrgInfo)=> String(item.org_no) === orgNo))


    return orgList?.filter((item)=> item?.is_del === 0);

}
