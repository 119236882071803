import { ArrowIcon } from "core/components/common/icons";
import Image from "next/image";
import { useRouter } from "next/router";
import { useState } from "react";
import styled from "styled-components";
//로그인 전역상태
import { loginUserState } from "core/stores/userStore";
import { useRecoilState } from "recoil";
//서버에서 토큰발행을 안하므로 by nohsn 2023.06.01
import CP from "core/components/common";
import * as Images from "core/utils/Images";
import * as Cookies from "core/utils/cookies";
import functions from "core/utils/functions";

interface Props {
  uploadUrl: string;
  loginUrl: string;
  logoutUrl: string;

  onUpdate: () => void;
}
export default function UserProfile({
  uploadUrl,
  loginUrl,
  logoutUrl,
  onUpdate
}: Props) {
  const router = useRouter();
  const [errorImg, setErrorImg] = useState<boolean>(false);
  const CLIENT_BASE_URL = uploadUrl;
  const UPLOAD_URL = CLIENT_BASE_URL + "/uploads";
  // 로그인 정보
  const [loginUserInfo, setLoginUserInfo] = useRecoilState(loginUserState);
  const onHandleLogout = (mode: string) => {
    if (mode == "A") {
      window.alert("준비중입니다.");
      return;
    } else if (mode == "C") {
      setLoginUserInfo({
        state: false,
        message: "",
        staff_id: "",
        photo_file: "",
        staff_name: "",
        org_no: "",
        class_position: "",
        duty_position: "",
        admin_org_no: "",
        erp: "",
        is_master: false,
        office_phone: "",
        company: ""
      });
      window.location.href = logoutUrl;
    } else {
      if (window.confirm("로그아웃하시겠습니까?")) {
        let isOutsourcing: boolean = false;

        try {
          if (loginUserInfo) {
            isOutsourcing = loginUserInfo.is_outsourcing;
          }
        } catch (err) {}

        Cookies.removeCookie("Authentication");
        setLoginUserInfo({
          state: false,
          message: "",
          staff_id: "",
          photo_file: "",
          staff_name: "",
          org_no: "",
          class_position: "",
          duty_position: "",
          admin_org_no: "",
          erp: "",
          is_master: false,
          office_phone: "",
          company: ""
        });
        window.location.href = isOutsourcing
          ? `${logoutUrl.replace("/logout", "/outsourcing-logout")}`
          : logoutUrl;
      }
    }
  };

  const onErrorImage = (e: any) => {
    setErrorImg(true);
    const photo = e?.target.currentSrc?.replace(
      "https://new-dev-erp.swadpia.co.kr/uploads/",
      ""
    );
    // return ['https://www.swadpia.co.kr/uploads/staff_photo/', photo].join('');
    return [CLIENT_BASE_URL + "/uploads/staff_photo/", photo].join("");
  };

  if (!loginUserInfo) return null;

  return (
    <>
      <UserProfileWrapper>
        <PopLayer>
          {functions.isEmpty(loginUserInfo.staff_name) ? (
            <SubTitle onClick={() => onHandleLogout("C")}>로그인</SubTitle>
          ) : (
            <>
              <SubTitle onClick={onUpdate}>정보수정</SubTitle>
              <SubTitle onClick={() => onHandleLogout("B")}>로그아웃</SubTitle>
            </>
          )}
        </PopLayer>
        {loginUserInfo.photo_file && !errorImg ? (
          <Image
            src={
              loginUserInfo.photo_file
                ? UPLOAD_URL +
                  "/staff_photo/" +
                  loginUserInfo.photo_file.replace("/uploads/", "")
                : Images.defaultProfile
            }
            width={32}
            height={32}
            alt="profile"
            onError={onErrorImage}
            style={{ borderRadius: "50%", objectFit: "cover" }}
          />
        ) : (
          <CP.Icon.NoImageIcon
            viewBox="0 0 80 80"
            style={{
              width: 32,
              height: 32
            }}
          />
        )}
        <Colum>
          <Name>{loginUserInfo?.staff_name}</Name>
          <Id>{loginUserInfo?.staff_id}</Id>
        </Colum>
        <ArrowIcon
          width={24}
          height={24}
          style={{
            transform: "rotate(90deg)"
          }}
        />
      </UserProfileWrapper>
    </>
  );
}

const UserProfileWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  margin-left: 24px;

  &:hover {
    & > span {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
`;

const PopLayer = styled.span`
  display: none;
  position: absolute;
  top: 44px;
  right: 10xp;
  height: 100px;
  width: 80px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;

  &:hover {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const Colum = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const SubTitle = styled.h2`
  font-size: 14px;
  color: #555555;
  line-height: 25px;
  &:hover {
    cursor: pointer;
  }
`;

const Name = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: right;
  letter-spacing: -0.015em;
  color: #222222;
`;

const Id = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: right;
  letter-spacing: -0.015em;
  color: #777777;
`;
