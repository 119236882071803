// import { useCrumbs } from 'adpia/hooks/useCrumbs';
import CP from "core/components/common";
import BreadCrumb from "core/components/common/BreadCrumb";
import { useRouter } from "next/router";
import styled from "styled-components";
import PageTitle from "./PageTitle";
import { Menu, SIDEBAR_DATA } from "core/data/MENU";

interface IProps {
  title?: string;
  subTitle?: string;
  subTitleStyle?: any;
  bottom?: boolean;
  isBack?: boolean;
}

interface PrevProps {
  name: string;
  href?: string;
}
export const useCrumbs = () => {
  const { pathname, asPath } = useRouter();

  const fullPathName = asPath.replace(pathname + "/", pathname);
  const reduceCallback = (prev: PrevProps[], curr: Menu) => {
    let clink = curr.link;

    if (!fullPathName.includes("/adpia")) {
      clink = curr.link.replace("/adpia", "");
    }

    if (clink && fullPathName.startsWith(clink)) {
      prev.push({ name: curr.name, href: clink });
    }

    if (curr.children && curr.children.length > 0) {
      const children = curr.children.reduce<PrevProps[]>(reduceCallback, []);
      prev.push(...children);
    }

    if (!curr.children || curr.children.length === 0) {
      return prev;
    }

    return prev;
  };

  const crumbs = SIDEBAR_DATA.reduce<PrevProps[]>(reduceCallback, []);
  return [{ name: "홈", href: "/" }, ...crumbs].map(
    (crumb: PrevProps, index: number) => ({
      href:
        index === 0 ||
        index === crumbs.length ||
        crumb.href?.indexOf("corporation-card") !== -1
          ? crumb.href
          : undefined,
      name: crumb.name
    })
  );
};

export default function Title({
  title,
  subTitle,
  subTitleStyle = {},
  bottom = false,
  isBack = false
}: IProps) {
  const crumbs = useCrumbs();
  const router = useRouter();
  return (
    <Section bottom={bottom}>
      <StyleTitle>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center"
          }}
        >
          {subTitle ? (
            <div style={{ display: "flex", flexDirection: "row", gap: "8px" }}>
              <PageTitle />
              <h2 style={subTitleStyle}>{subTitle}</h2>
            </div>
          ) : (
            <PageTitle />
          )}
          {isBack && (
            <CP.Button
              variant="active"
              onClick={() => router.back()}
              style={{ marginLeft: 16 }}
              color="blue"
            >
              이전 화면
            </CP.Button>
          )}
        </div>
        <BreadCrumb crumbs={crumbs} />
      </StyleTitle>
    </Section>
  );
}

export const Section: any = styled.section<{
  bottom?: boolean;
}>`
  margin-top: 0;
  background-color: #fff;
  width: 100%;
  // width: calc(100% - 86px);
  height: 56px;
  border-bottom: ${(props) => props.bottom && `1px solid #ccc`};
  // position: fixed;
  top: 56px;
  left: 86px;
`;

export const StyleTitle: any = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 17px 24px;

  & h1 {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.black222};
    font-family: Pretendard;
    font-style: normal;
  }
  & h2 {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #999999;
    font-family: Pretendard;
    font-style: normal;
  }
`;
