import styled, { css } from 'styled-components';

interface IProps {
  margin?: number;
  color?: string;
  line?: string;
  style?: any;
  direction?: 'column' | 'row';
}

export default function Divider({
  margin,
  color,
  line,
  style,
  direction = 'column',
}: IProps) {
  return (
    <StyledDivider
      className="dividerDiv"
      margin={margin}
      color={color}
      line={line}
      style={style}
      direction={direction}
    />
  );
}

const StyledDivider = styled.div<IProps>`
  ${({ direction }) =>
    direction === 'column' &&
    css<IProps>`
      border-bottom: 1px ${({ color }) => (color ? color : '#ccc')};
      border-style: ${({ line }) => (line ? line : 'solid')};
      margin-left: ${props => (props.margin ? -props.margin : -24)}px;
      margin-right: ${props => (props.margin ? -props.margin : -24)}px;
    `}
  ${({ direction }) =>
    direction !== 'column' &&
    css<IProps>`
      border-right: 1px ${({ color }) => (color ? color : '#ccc')};
      border-style: ${({ line }) => (line ? line : 'solid')};
      margin-top: ${props => (props.margin ? -props.margin : -24)}px;
      margin-bottom: ${props => (props.margin ? -props.margin : -24)}px;
    `}
`;
