import * as S from "core/components/Styled";
import { StaffInfo } from "core/models/Common";
import { codeState } from "core/stores/adpia/hrStore";
import { sidebarState } from "core/stores/layoutStore";
import { findOrgInfo } from "core/utils/CodeInfoUtil";
import { useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import CP from ".";
import { InputIProps } from "./Input2";

interface InputProps extends InputIProps {
  searchType?: "id" | "name";
  containerStyle?: any;
  top?: number;
  searchDisabled?: boolean;
  onClickStaff?: (staff: StaffInfo) => void;

  company?: string,
  orgNoList?:string[]

}

export default function Input({ ...rest }: InputProps) {
  const [open, setOpen] = useState<boolean>(false);
  const [codeInfo, setCodeInfo] = useRecoilState(codeState);
  const [staffList, setStaffList] = useState<StaffInfo[]>([]);
  // 사이드바 오픈 여부
  const [showsSecondMenus, setShowsSecondMenus] = useRecoilState(sidebarState);
  const dropMenuRef = useRef<HTMLDivElement | null>(null);
  const {
    value,
    containerStyle,
    onChange,
    onClickStaff,
    searchType,
    top = 32,
    searchDisabled
  } = rest;

  useEffect(() => {
    if (!showsSecondMenus) return;

    setStaffList([]);
  }, [showsSecondMenus]);

  const findStaffList = (value: string, orgList:string[] = []) => {
    const list: StaffInfo[] = [];



    let _staffList = rest?.company ? codeInfo?.staffAll?.filter((item)=> item.company === rest?.company) : codeInfo?.staff ? codeInfo.staff : [];


    if (_staffList) {
      if(orgList && orgList?.length > 0){
        _staffList = _staffList.filter((item)=> orgList?.indexOf(String(item.org_no) )!== -1 );
      }


    _staffList?.map((item: StaffInfo) => {

        if (searchType) {
          // console.log({searchType})
          if (searchType === "id") {
            if (item?.staff_id.indexOf(value) !== -1) {
              list.push(item);
            }
          } else if (searchType === "name") {
            // console.log({value}, {item})
            if (String(item?.staff_name).indexOf(value) !== -1) {
              list.push(item);
            }
          }
        } else {
          if (
            String(item?.staff_name).indexOf(value) !== -1 ||
            item?.staff_id.indexOf(value) !== -1
          ) {
            list.push(item);
          }
        }
      });
    }

    return list;
  };
  useEffect(() => {
    if (!codeInfo?.staff) return;
    const { value, orgNoList } = rest;

    if (!value) {
      return setStaffList([]);
    }

    const list: StaffInfo[] = findStaffList(value, orgNoList ? orgNoList : []);

    if (list?.length === 1) {
      const item = list[0];
      if (searchType === "id" && item?.staff_id === rest.value)
        return setStaffList([]);
      if (item?.staff_name === rest.value) return setStaffList([]);
    }


    setStaffList(list);
  }, [rest?.value]);

  useEffect(() => {
    if (!staffList) return setOpen(false);
    setOpen(true);
  }, [staffList]);

  useEffect(() => {
    const handleOutsideClose = (e: { target: any }) => {
      // useRef current에 담긴 엘리먼트 바깥을 클릭 시 드롭메뉴 닫힘
      if (open && !dropMenuRef.current.contains(e.target)) setOpen(false);
    };
    document.addEventListener("click", handleOutsideClose);

    return () => document.removeEventListener("click", handleOutsideClose);
  }, [open]);

  return (
    <div
      ref={dropMenuRef}
      className="staffSearchInput"
      style={
        containerStyle
          ? containerStyle
          : { position: "relative", width: "100%", minWidth: 170 }
      }
    >
      <CP.Input
        {...rest}
        onFocus={() => {
          if (!open && staffList?.length > 0) setOpen(true);
        }}
      />

      {!searchDisabled && open && staffList?.length > 0 && (
        <StyleDiv top={top} onBlur={() => console.log("b")}>
          <CP.Flex justify="flex-end">
            <CP.Icon.CloseIcon
              width={16}
              height={16}
              viewBox="0 0 25 25"
              onClick={() => setStaffList([])}
            />
          </CP.Flex>

          <div>
            {staffList?.map((item) => {
              const orgName = findOrgInfo(
                codeInfo,
                item?.org_no
              )?.org_name?.replace("(주)", "");
              return (
                <button
                  style={{ background: "transparent", width: "100%" }}
                  onClick={() => {
                    onChange(
                      searchType === "id"
                        ? { target: { value: item.staff_id } }
                        : item?.staff_name
                        ? { target: { value: item.staff_name } }
                        : { target: { value: item.staff_id } }
                    );
                    
                    if (onClickStaff) {
                      onClickStaff(item)
                    };

                    setTimeout(() => {
                      setStaffList([]);
                    }, 50);
                  }}
                >
                  <CP.Flex padding={"4px"}>
                    <StyleText>{item?.staff_id}</StyleText>
                    <StyleText style={{ textAlign: "center" }}>
                      {item?.staff_name}
                    </StyleText>
                    <StyleText style={{ borderRight: "none" }}>
                      {orgName}
                    </StyleText>
                  </CP.Flex>
                </button>
              );
            })}
          </div>
        </StyleDiv>
      )}
    </div>
  );
}

const StyleText = styled(S.StyledText)`
  display: block;
  color: #333;
  width: 33%;
  text-overflow: ellipsis;
  padding: 0px 3px;
  white-space: nowrap;
  font-weight: 600;
  border-right: 1px solid #ccc;
  overflow: hidden;
  // text-align: center;
`;
const StyleDiv = styled.div<{ top?: number }>`
  top: ${({ top }) => top}px;
  margin-top: 4px;
  background: #fff;
  position: absolute;
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 100%;
  max-height: 200px;
  overflow: auto;
  padding: 6px;
  z-index: 5;
`;
