import Config from "@/env/Config";
import {
  FileExcelTwoTone,
  FilePdfTwoTone,
  FileUnknownTwoTone,
  FileZipTwoTone
} from "@ant-design/icons";
import * as S from "core/components/Styled";
import CP from "core/components/common";
import { ModalType } from "core/constants/enums";
import { fileToDataURL } from "core/utils/index";
import { ComponentProps, useState } from "react";
import styled from "styled-components";
import Grid from "../common/Grid";
import { UploadFileType } from "../common/Upload";
import { CloseIcon, ImageIcon } from "../common/icons";
import CustomFileViewer from "./CustomFileViewer";
interface ImageFileUploadProps extends ComponentProps<"input"> {
  files: UploadFileType[];
  onChange?: (e: any) => void;
  onDelete?: (idx: number, file: UploadFileType) => void;
  maxCount?: number;
  path: string;
  disabled?: boolean;

  title?: string;
  isPossibleUploadFile?: boolean
  isPossibleDeleteFile?: boolean
}
const UPLOAD_URL = Config.publicBaseUploadUrl;

export default function ImageFileUpload(props: ImageFileUploadProps) {
  const {
    files,
    title = "첨부파일 뷰어",
    path,
    onChange,
    onDelete,
    disabled = false,
    maxCount = 8,
    isPossibleUploadFile,
    isPossibleDeleteFile,
    ...rest
  } = props;

  const [fileViewModal, setFileViewModal] = useState({
    isOpen: false,
    startImageIndex: 0
  });

  const handleChange = async (e: any) => {
    if (disabled) return;
    if (e.target.files.length <= 0) return;

    let newFiles = [];
    for (let i = 0; i < e.target.files.length; i++) {
      const file = e.target.files[i];
      const blobImage = await fileToDataURL(file);
      newFiles.push({
        name: file?.name,
        data: blobImage?.source,
        file: file,
        type: file?.name
          ? file?.name.substring(
              file.name.lastIndexOf(".") + 1,
              file.name.length
            )
          : ""
      });
    }

    if (maxCount) {
      if (files.length + newFiles.length > maxCount)
        return CP.alert(`최대 ${maxCount}개까지만 업로드 가능합니다.`, {});
    }
    onChange(newFiles);
  };

  const getImageName = (fullName: string) => {
    const split = fullName.split("/");
    return split[split.length - 1];
  };

  const getFileType = (image: UploadFileType) => {
    try {
      if (image.type === "file") {
        const split = getImageName(image.name).split(".");
        return split[1];
        return image.type;
      }
    } catch (err) {}

    return image.type;
  };

  const renderItem = (image: UploadFileType) => {
    if (
      image.type.indexOf("png") !== -1 ||
      image.type.indexOf("jpg") !== -1 ||
      image.type.indexOf("jpeg") !== -1 ||
      image.type.indexOf("image") !== -1
    ) {
      let _src = image.data ? image.data : path + image.name;
      if (_src.includes("base64")) {
      } else if (_src.indexOf("http") === -1) {
        _src = UPLOAD_URL + _src.replace("/uploads", "");
      }

      if (_src.includes("/uploads/uploads")) {
        _src = _src.replace("/uploads/uploads", "/uploads");
      }
      return (
        <Image
          src={_src}
          alt="사진"
          style={{
            width: "100%",
            aspectRatio: "1 / 1",
            height: "100%",
            minWidth: "100%",
            minHeight: "100%",
            objectFit: "fill",
            border: "1px solid #e2e2e2",
            resize: "block"
          }}
        />
      );
    } else {
      let fileType = getFileType(image);
      return (
        <FileWrapper>
          <CP.Flex
            style={{
              flexDirection: "column",
              padding: 12,
              height: "100%",
              justifyContent: "center",
              alignItems: "center"
            }}
            gap={"8px"}
          >
            <>
              {fileType === "pdf" ? (
                <FilePdfTwoTone style={{ fontSize: 18 }} />
              ) : fileType === "zip" ? (
                <FileZipTwoTone style={{ fontSize: 18 }} />
              ) : fileType === "ppt" ? (
                <FilePdfTwoTone style={{ fontSize: 18 }} />
              ) : fileType === "xlsx" ? (
                <FileExcelTwoTone style={{ fontSize: 18 }} />
              ) : (
                <FileUnknownTwoTone style={{ fontSize: 18 }} />
              )}
            </>

            <S.StyledText
              style={{
                textAlign: "center",
                overflow: "hidden",
                wordBreak: "break-all"
              }}
            >
              {getImageName(image.name)}
            </S.StyledText>
          </CP.Flex>
        </FileWrapper>
      );
    }
  };

  return (
    <>
      <Grid columns={4} gap={12}>
        {maxCount === files.length 
          ? null // 1. maxcount가 꽉 찼을 때
          : (
            isPossibleUploadFile === false 
            ? ( // 2. upload가 안될 때
              <ImageWrapper>
                <StyleImageIcon width={24} height={24} />
                <S.StyledText size="14px" lineHeight="17px" color={"#999"}>
                </S.StyledText>
              </ImageWrapper>
            ) : ( // 3. 나머지 경우
              <Label style={{ cursor: !disabled ? "pointer" : "auto" }}>
                <ImageWrapper>
                  <StyleImageIcon width={24} height={24} />
                  <S.StyledText size="14px" lineHeight="17px" color={"#999"}>
                    업로드
                  </S.StyledText>
                </ImageWrapper>
                {!disabled && (
                  <input type="file" multiple onChange={handleChange} {...rest} />
                )}
              </Label>
            )
        )}
        {files.map((image, index) => {
          return (
            <div
              key={index}
              style={{ position: "relative", cursor: "pointer" }}
              onClick={() => {
                // if (!disabled) return;
                setFileViewModal({ isOpen: true, startImageIndex: index });
              }}
            >
              {(!disabled && isPossibleDeleteFile === true) && (
                <CloseButton
                  onClick={(e) => {
                    e.preventDefault();
                    onDelete(index, image);
                  }}
                >
                  <CloseIcon
                    width={16}
                    height={16}
                    viewPort="0 0 16 16"
                    viewBox="0 0 24 24"
                  />
                </CloseButton>
              )}
              {renderItem(image)}
            </div>
          );
        })}
        <CustomFileViewer
          files={files}
          path={path}
          isOpen={fileViewModal.isOpen}
          title={title}
          startImageIndex={fileViewModal.startImageIndex}
          edit={false}
          close
          setIsOpen={() =>
            setFileViewModal({ isOpen: false, startImageIndex: 0 })
          }
          download
          modalType={disabled ? ModalType.View : ModalType.Update}
        />
      </Grid>
    </>
  );
}

const Label = styled.label`
  width: 100%;
  height: 100%;

  & > input {
    position: absolute;
    width: 0;
    height: 0;
    padding: 0;
    overflow: hidden;
    border: 0;
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  aspect-ratio: 1 / 1;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed #999999;
  gap: 4px;
`;

const FileWrapper = styled.div`
  width: 100%;
  aspect-ratio: 1 / 1;
  height: 100%;
  cursor: pointer;
  border: 1px solid #e2e2e2;
  resize: block;
  border-radius: 8px;
  disaply: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 100%;
  min-height: 100%;
  justify-content: center;
  align-items: center;
`;

const StyleImageIcon = styled(ImageIcon)`
  & > path {
    fill: #999;
  }
`;

const Image = styled.img`
  width: 100%;
  aspect-ratio: 1 / 1;
  object-fit: cover;
  border-radius: 8px;
  // border: 1px solid #999999;
`;

const CloseButton = styled.button`
  position: absolute;
  top: -4px;
  right: -4px;
  z-index: 1;
  border-radius: 50%;
  background-color: #ffffff;
  border: 0.666667px solid #cccccc;
  display: flex;
  justify-content: center;
  align-items: center;
  & > svg > path {
    fill: rgba(0, 0, 0, 0.7);
  }
  &:hover {
    border-radius: 50px;
    background-color: ${({ theme }) => theme.colors.primary};
    & > svg > path {
      fill: #fff;
    }
  }
`;
