import { ListItem } from "core/components/common/Select";
import { ResCodeInfo } from "core/models/Common";
import { format } from "date-fns";
import moment, { Moment } from "moment";

//문자로 변환
export const castToString = (value: string | number | undefined) => {
  if (value === undefined) return "";
  return new String(value).toString();
};

//날짜->문자
export const castToDateString = (
  date: number | Date | undefined,
  dateFormat: string = "yyyy-MM-dd hh:mm:ss"
) => {
  if (date == null) return "";
  return format(new Date(date), dateFormat);
};

//숫자로 변환
export const castToNumber = (value: string | number | undefined) => {
  if (value === undefined) return 0;
  return Number(value);
};

//연락처 형식 000-0000-0000
export const castToMobile = (_number: number | string) => {
  const number = _number ? (_number + "").replace(/-/g, "") : ""; // .replaceAll("-", "") : "";

  if (!number || number.length < 9 || number.length > 11 || !Number(number))
    return number;

  if (number.indexOf("-") !== -1) return number;
  let numberList: string[] = [];
  let ret = "";

  if (number.startsWith("02")) {
    if (number.length > 10) return number;
    numberList.push(number.substring(0, 2));
    numberList.push(number.substring(2, number.length - 4));
    numberList.push(number.substring(number.length - 4, number.length));
  } else {
    if (number.length < 10) return number;
    numberList.push(number.substring(0, 3));
    numberList.push(number.substring(3, number.length - 4));
    numberList.push(number.substring(number.length - 4, number.length));
  }
  numberList?.map((item) => {
    if (ret !== "") ret += "-";
    ret += item;
  });
  return ret;
};

//숫자 형식 000,000,000
export const castToNumberComma = (_number: string | number) => {
  const number = (_number + "").replace(/,/g, ""); //.replaceAll(",", "");

  if (Number(number) || Number(number) === 0) {
    return Number(number).toLocaleString();
  } else {
    let value = (number + "").substring(0, (number + "").length - 1);
    const last = (number + "").substring(
      (number + "").length - 1,
      (number + "").length
    );

    if (!Number(value)) return;

    return Number(value).toLocaleString() + last;
  }
};

//시간 형식 00:00
export const castToTime = (value: number | string) => {
  let _value = (value + "").replace(/:/g, ""); //.replaceAll(":", "");

  if (_value.startsWith("0")) {
    _value = _value.substring(1, _value.length);
  }

  if (_value.length > 4) {
    _value = _value.substring(0, 4);
  }

  if (!Number(_value)) return "";

  if (_value.length === 3) {
    const mi = _value.substring(1, 3);
    const hh = _value.substring(0, 1);
    if (parseInt(mi) < 60) {
      return "0" + hh + ":" + mi;
    }
  } else if (_value.length === 4) {
    const mi = _value.substring(2, 4);
    const hh = _value.substring(0, 2);
    if (parseInt(mi) < 60) {
      return hh + ":" + mi;
    }
  }
  return _value;
};

export const castCardNo = (
  _value: number | string | string[],
  returnType: "string" | "array" = "string"
): string | string[] => {
  let val = _value;
  if (typeof val === "object") {
    let _val = "";
    val?.map((item) => {
      _val += item;
    });
    val = _val;
  }
  let value = String(val)?.replace(/-/g, ""); //  .replaceAll("-", "");
  if (value?.length !== 16) return String(_value);

  const splitStr = (str: string) => {
    return str.match(new RegExp(`.{1,4}`, "g"));
  };
  const valueArray: any[] = splitStr(value);

  if (returnType === "array") {
    return valueArray;
  }

  let ret: string = "";
  valueArray?.map((i, index) => {
    if (index > 0 && index < valueArray?.length) {
      ret += "-";
    }
    ret += i;
  });

  return ret;
};

//codeList로 selectbox 리스트만들기
export const selectListSetting = (
  codeList: ResCodeInfo[],
  code: string,
  subCode?: string | string[]
): ListItem[] => {
  if (!(codeList && codeList.length > 0)) return [];

  let list: ResCodeInfo[] = [];

  if (subCode) {
    if (typeof subCode === "string") {
      list = codeList.filter(
        (item: any) => item.h_code === code && item.ref_key + "" === subCode
      );
    } else {
      list = codeList.filter(
        (item: any) =>
          item.h_code === code && subCode.indexOf(item.ref_key + "") !== -1
      );
    }
  } else {
    list = codeList.filter((item: any) => item.h_code === code);
  }

  if (list?.length > 0) {
    // console.log({ list });
    list.sort((a: ResCodeInfo, b: ResCodeInfo) => {
      return a?.print_index - b?.print_index;
    });
  }

  let retList: ListItem[] = [];

  list?.map((item) => {
    retList.push({ name: item.name, value: item.code });
  });

  return retList;
};

export const WEEK = [
  { id: "0", name: "일", en: "Sun" },
  { id: "1", name: "월", en: "Mon" },
  { id: "2", name: "화", en: "Tue" },
  { id: "3", name: "수", en: "Wed" },
  { id: "4", name: "목", en: "Thu" },
  { id: "5", name: "금", en: "Fri" },
  { id: "6", name: "토", en: "Sat" }
];

interface dateProps {
  day: number | string;
  date: string;
  current: boolean;
}

//  평일인지 확인
export const getIsWeekday = (date: Moment): boolean => {
  let ret = true;
  if ([0, 6].indexOf(date.day()) !== -1) {
    ret = false;
  }
  return ret;
};
//달력 만들기
export const monthFormat = (state: { year: string; month: string }): any[] => {
  let month: any = moment(state.year + "." + state?.month).format("YYYY-MM");

  if (!month) return;

  let monthStart: any;
  let monthLength: any;
  let beforeMonth: any;
  if (month !== "Invalid date") {
    monthStart = moment(month + "-01", "YYYY-MM-DD").day(); //그달 1일의 요일
    // 0: 일, 1: 월, 2: 화. 3: 수, 4: 목, 5: 금, 6: 토
    monthLength = moment(month, "YYYY-MM").daysInMonth(); // 그달의 길이(마지막일자)

    beforeMonth = moment(month, "YYYY-MM").add(-1, "m").daysInMonth(); // 이전 달의 길이(마지막일자)
  } else {
    month = new Date(state.year + "/" + state?.month + "/01");
    monthStart = parseInt(
      WEEK.find((item) => item.en === (month + "").slice(0, 3))?.id
    );

    monthLength = new Date(
      state.year +
        "/" +
        (parseInt(state?.month) + 1 > 10
          ? parseInt(state?.month) + 1
          : "0" + (parseInt(state?.month) + 1)) +
        "/00"
    ).getDate();
    beforeMonth = new Date(state.year + "/" + state?.month + "/00").getDate();
  }

  if (!(month && monthStart !== undefined && monthLength)) {
    return [];
  }

  let list = [];
  let weekList: dateProps[] = [];

  [...Array(monthStart)].map((item, index) => {
    const _day = beforeMonth - (monthStart - index - 1) + "";
    const _date =
      moment(month, "YYYY-MM").add(-1, "m").format("YYYY-MM-") + _day;

    weekList.push({
      day: _day,
      date: _date,
      current: false
    });
  });

  [...Array(monthLength)].map((item, index) => {
    if (weekList.length === 7) {
      list.push(weekList);
      weekList = [];
    }
    const _day = index + 1 < 10 ? "-0" + (index + 1) : "-" + (index + 1);

    const _date = month + _day;
    let _current = true;

    weekList.push({
      day: index + 1 + "",
      date: _date,
      current: _current
    });
  });

  if (weekList.length > 0) {
    if (weekList.length < 7) {
      const num = WEEK.length - weekList.length;
      let _week: dateProps[] = [];

      [...Array(num)].map((n, index) => {
        const _day = "" + (index + 1);
        const _date =
          moment(month, "YYYY-MM").add(1, "M").format("YYYY-MM-0") + _day;

        _week.push({ day: _day, date: _date, current: false });
      });

      weekList = weekList.concat(_week);
    }
    list.push(weekList);
    weekList = [];
  }

  // if (list.length < 6) {
  //   let _week: dateProps[] = [];
  //   [...Array(7)].map((n, index) => {
  //     const _day = 1 + index;
  //     const _date =
  //       moment(month, "YYYY-MM").add(1, "M").format("YYYY-MM-0") + _day;

  //     _week.push({ day: _day, date: _date, current: false });
  //   });
  //   weekList = weekList.concat(_week);
  //   list.push(weekList);
  //   weekList = [];
  // }
  return list;
  // setMonthList(list);
};

export const findWeek = (date: string) => {
  //date : YYYY-MM-DD

  const week = moment(date, "YYYY-MM-DD").day();

  return WEEK[week]?.name ? WEEK[week]?.name : "";
};

//숫자로 변환
export const castToObject = (value: any) => {
  try {
    const json = JSON.parse(value);

    return typeof json === "object" ? json : value;
  } catch (e) {
    return value;
  }
};

//마스킹 처리
//start ~end 까지 부분 마스킹 가능
//없을 시 전체 마스킹
export const castToMasking = (
  value: string,
  _start?: number,
  _end?: number
) => {
  const start = _start ? _start : 0;
  const end = _end ? _end : value?.length;

  console.log({ start }, { end });

  let list: string[] = [];
  let ret: string = "";

  for (let x of value) {
    list.push(x);
  }

  list?.map((item, index) => {
    if (index >= start && index <= end) ret += "*";
    else ret += item;
  });

  return ret;
};

export const checkNumber = (_value: string | number, except: string = ",") => {
  let value: string = String(_value).replaceAll(except, "");

  let idx: number = 0;

  if (value?.indexOf(".") !== -1) {
    idx = value?.indexOf(".");
    value = value.replace(/./g, ""); // .replaceAll(".", "");
  }

  const pattern_num = /^[0-9]+$/;

  if (!pattern_num.test(value)) return "";

  if (idx > 0) {
    value = value.substring(0, idx) + "." + value.substring(idx, value.length);
  }

  return value;
};
