import * as S from "core/components/Styled";
import { CloseIcon } from "core/components/common/icons";
import styled from "styled-components";
// export type RadioItem = {
//   name: string;
//   value: string | number;
//   disabled?: boolean;
// };

interface IProps {
  children: string;
  style?: object;
  textStyle?: object;
  deleteYn?: boolean;
  onDelete?: (e: any) => void;
  onClick?: (value: any) => void;
  value?: any;
}

export default function Tag({
  children,
  style,
  textStyle,
  deleteYn = false,
  onDelete,
  onClick,
  value,
}: IProps) {
  if (!children) return null;

  const onClickText = () => {
    if (onClick) onClick(value);
  };
  return (
    <StyledTag style={style}>
      <StyledTagText style={textStyle} onClick={onClickText}>
        {children}
      </StyledTagText>
      {deleteYn && (
        <StyledCloseIcon
          viewBox="0 0 25 25"
          onClick={() => (onDelete ? onDelete(value ? value : children) : {})}
        />
      )}
    </StyledTag>
  );
}

const StyledCloseIcon = styled(CloseIcon)`
  margin-left: 8px;
  width: 16px;
  height: 16px;
  > path {
    width: 8.67px;
    height: 8.67px;
    fill: ${({ theme }) => theme.colors.primary};
  }
  cursor: pointer;
`;

const StyledTag = styled.div`
  padding: 8px 16px;
  border: ${({ theme }) => `1px solid ${theme.colors.primary}`};
  border-radius: 100px;
  width: fit-content;
  display: flex;
  align-items: center;
  &:hover {
    background-color: #f0eeff;
  }
`;

const StyledTagText = styled(S.StyledText)`
  color: ${({ theme }) => theme.colors.primary};
  // text-overflow: ellipsis;
  // overflow: hidden;
  // white-space: nowrap;
`;
