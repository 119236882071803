import { Input as InputComp, InputNumber, InputRef } from "antd";
import { SearchIcon as Icon } from "core/components/common/icons";
import * as S from "core/components/Styled";
import { ModalType } from "core/constants/enums";
import React, { ForwardedRef, forwardRef } from "react";
import styled from "styled-components";

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {}

const formatNumberWithThousandSeparatorAndTwoDecimalPlaces = (value: any) =>
  new Intl.NumberFormat("ko-KR", {
    // maximumSignificantDigits: 0,
  }).format(value);

export interface InputIProps {
  onChange?: (e: any) => void;
  onBlur?: (e: any) => void;
  value?: any;
  defaultValue?: string;
  type?: string; //'text' | 'search' | 'textarea';
  disabled?: boolean;
  placeholder?: string;
  rows?: number; //textarea
  maxLength?: number; //textarea
  style?: any;
  error?: boolean;
  name?: string;
  // ref?: any;
  className?: string;
  align?: "center" | "end" | "start";
  onKeyPress?: (e: any) => void;
  onFocus?: (e: any) => void;
  modalType?: ModalType;
  prefix?: any;
}

const { TextArea } = InputComp;

export default forwardRef(function Input(
  {
    onChange,
    value,
    defaultValue,
    type = "text",
    disabled,
    placeholder,
    style,
    rows,
    maxLength,
    onBlur,
    error,
    name,
    className,
    align,
    onKeyPress,
    onFocus,
    modalType = undefined,
    prefix,
    ...props
  }: InputIProps,
  ref: ForwardedRef<InputRef>
) {
  if (modalType) {
    if (modalType === ModalType.View) {
      return <S.StyledText>{value}</S.StyledText>;
    }
  }
  // console.log('input2');
  if (type === "textarea") {
    return (
      <StyledTextArea
        ref={ref}
        name={name}
        onChange={onChange}
        value={value}
        defaultValue={defaultValue}
        disabled={disabled}
        placeholder={placeholder}
        rows={rows}
        maxLength={maxLength}
        onBlur={onBlur}
        onFocus={onFocus}
        error={error}
        style={{ resize: "none", textAlign: align ? align : "", ...style }}
        className={className}
        onKeyPress={onKeyPress}
      />
    );
  }

  if (type === "number") {
    return (
      <StyledInputNumber
        name={name}
        className={value && "value_true"}
        style={{ textAlign: align ? align : "", ...style }}
        textAlign={style?.textAlign}
        defaultValue={defaultValue}
        onChange={(value) =>
          onChange({
            target: {
              name,
              value
            }
          })
        }
        // decimalSeparator = {','}
        formatter={formatNumberWithThousandSeparatorAndTwoDecimalPlaces}
        value={value}
        disabled={disabled}
        placeholder={placeholder}
        // onKeyDown={e => type === 'number' ? e.key === 'e' ? false : true : true}
        onBlur={onBlur}
        controls={false}
        error={error}
        onKeyPress={onKeyPress}
      />
    );
  }
  return (
    <StyledInput
      ref={ref}
      {...props}
      defaultValue={defaultValue}
      name={name}
      className={value && "value_true"}
      style={{ textAlign: align ? align : "", ...style }}
      onChange={onChange}
      value={value}
      disabled={disabled}
      placeholder={placeholder}
      prefix={prefix}
      suffix={type === "search" && <SearchIcon />}
      onBlur={onBlur}
      onFocus={onFocus}
      error={error}
      onKeyPress={onKeyPress}
      type={type && type !== "search" && type}
    />
  );
});

const SearchIcon = styled(Icon)`
  width: 24px;
  height: 24px;
  & > path {
    stroke: #999;
  }
`;
const StyledTextArea = styled(TextArea)<{ error?: boolean }>`
  // border: 1px solid #999999;
  border: 1px solid ${({ error }) => (error ? "#FF6969" : "#999999")};
`;

const StyledInputNumber = styled(InputNumber)<{
  error?: boolean;
  textAlign?: string;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 32px;
  border: 1px solid ${({ error }) => (error ? "#FF6969" : "#999999")};
  border-radius: 4px;
  padding: 8px;
  .ant-input-number-input {
    padding-left: 0px;
    text-align: ${({ textAlign }) => (textAlign ? textAlign : "end")};
  }
  & > .ant-input-suffix > svg > path {
    stroke: #999;
  }

  .ant-input-number-input-wrap {
    width: 100%;
  }
  &.value_true,
  &.ant-input-number-input-wrap:hover,
  &.ant-input-number-input-wrap-focused {
    & > .ant-input-suffix > svg > path {
      stroke: #222;
    }
  }
  &.ant-input-number-input-wrap:hover,
  &.ant-input-number-input-wrap-focused {
    border: 1px solid
      ${({ error, theme }) => (error ? "#FF6969" : theme.colors.primary)};
    box-shadow: none;
  }

  &.ant-input-number-input {
    color: ${({ theme }) => theme.colors.black222};
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
  }

  &:disabled {
    background: #eaeaea;
    border: 1px solid #999999;
    color: #999999;
  }

  &::placeholder {
    color: #999999;
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type="number"] {
    -moz-appearance: textfield;
  }
`;
const StyledInput = styled(InputComp)<{ error?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 32px;
  border: 1px solid ${({ error }) => (error ? "#FF6969" : "#999999")};
  border-radius: 4px;
  padding: 8px;

  & > .ant-input-suffix > svg > path {
    stroke: #999;
  }
  &.value_true,
  &.ant-input-affix-wrapper:hover,
  &.ant-input-affix-wrapper-focused {
    & > .ant-input-suffix > svg > path {
      stroke: #222;
    }
  }
  &.ant-input-affix-wrapper:hover,
  &.ant-input-affix-wrapper-focused {
    border: 1px solid
      ${({ error, theme }) => (error ? "#FF6969" : theme.colors.primary)};
    box-shadow: none;
  }

  &.ant-input {
    color: ${({ theme }) => theme.colors.black222};
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
  }

  &:disabled {
    background: #eaeaea;
    border: 1px solid #999999;
    color: #999999;
  }

  &::placeholder {
    color: #999999;
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type="number"] {
    -moz-appearance: textfield;
  }
`;
