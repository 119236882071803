import { Menu, SIDEBAR_DATA } from "core/data/MENU";
import { useRouter } from "next/router";
import { useMemo } from "react";
import styled from "styled-components";

export default function PageTitle() {
  const { pathname, asPath } = useRouter();
  const fullPathName = asPath.replace(pathname + "/", pathname);
  const title = useMemo(() => {
    let result = "";
    const findRecursive = (menu: Menu[]) => {
      menu.forEach((item) => {
        let clink = item.link;

        if (!fullPathName.includes("/adpia") || !pathname.includes("/adpia")) {
          clink = item.link.replace("/adpia", "");
        }
        if (clink === fullPathName || clink === pathname) {
          result = item.name;
          return;
        } else if (item.children) {
          findRecursive(item.children);
        }
      });
    };
    findRecursive(SIDEBAR_DATA);
    return result;
  }, [fullPathName]);
  return <Title>{title}</Title>;
}

export const Title: any = styled.h1`
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.black222};
`;
