import * as S from "core/components/Styled";
import { fileToDataURL } from "core/utils/index";
import { ComponentProps } from "react";
import styled from "styled-components";
import Grid from "./Grid";
import { CloseIcon, ImageIcon } from "./icons";
import CP from "core/components/common";

interface MultiFileInputProps extends ComponentProps<"input"> {
  images: string[];
  setImages?: (e: any) => void;
  onClickImageDeleteButton?: (index: number) => void;
  maxCount?: number;
}

export default function MultiFileInput(props: MultiFileInputProps) {
  const { images, setImages, onClickImageDeleteButton, maxCount, ...rest } =
    props;

  const onChangeMultiFileInput = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!setImages) return;

    const fileList = e.target.files;
    if (!fileList) return;
    const blobImages = await Promise.all(
      Array.from(fileList).map(fileToDataURL)
    );
    const blobImageSourceList = blobImages.map((image) => image.source);

    if (maxCount) {
      if (images.length + blobImageSourceList.length > maxCount) {
        CP.alert(`최대 ${maxCount}개까지만 업로드 가능합니다.`, {});
        return;
      }
    }

    setImages((prev: any) => [...prev, ...blobImageSourceList]);
  };

  const onClickImageDelete = (index: number) => {
    if (!(setImages && images)) return;
    setImages(images.filter((item, idx) => idx !== index));
  };

  return (
    <Grid columns={4} gap={12}>
      {maxCount === images.length ? null : (
        <Label>
          <ImageWrapper>
            <StyleImageIcon width={24} height={24} />
            <S.StyledText size="14px" lineHeight="17px" color={"#999"}>
              업로드
            </S.StyledText>
          </ImageWrapper>

          <input
            type="file"
            accept=".jpg, .jpeg, .png"
            multiple
            onChange={rest.onChange ? rest.onChange : onChangeMultiFileInput}
            {...rest}
          />
        </Label>
      )}

      {images.length > 0 && (
        <>
          {images.map((image, index) => (
            <div key={index} style={{ position: "relative" }}>
              <CloseButton
                onClick={(e) => {
                  e.preventDefault();
                  if (onClickImageDeleteButton) {
                    onClickImageDeleteButton(index);
                  } else {
                    onClickImageDelete(index);
                  }
                }}
              >
                <CloseIcon
                  width={16}
                  height={16}
                  viewPort="0 0 16 16"
                  viewBox="0 0 24 24"
                />
              </CloseButton>
              <Image
                src={image}
                alt="사진"
                style={{ width: "100%", aspectRatio: "1 / 1" }}
              />
            </div>
          ))}
        </>
      )}
    </Grid>
  );
}

const Label = styled.label`
  width: 100%;
  height: 100%;

  & > input {
    position: absolute;
    width: 0;
    height: 0;
    padding: 0;
    overflow: hidden;
    border: 0;
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  aspect-ratio: 1 / 1;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed #999999;
  gap: 4px;
`;

const StyleImageIcon = styled(ImageIcon)`
  & > path {
    fill: #999;
  }
`;

const Image = styled.img`
  width: 100%;
  aspect-ratio: 1 / 1;
  object-fit: cover;
  border-radius: 8px;
  // border: 1px solid #999999;
`;

const CloseButton = styled.button`
  position: absolute;
  top: -4px;
  right: -4px;
  z-index: 1;
  border-radius: 50%;
  background-color: #ffffff;
  border: 0.666667px solid #cccccc;
  display: flex;
  justify-content: center;
  align-items: center;
  & > svg > path {
    fill: rgba(0, 0, 0, 0.7);
  }
  &:hover {
    border-radius: 50px;
    background-color: ${({ theme }) => theme.colors.primary};
    & > svg > path {
      fill: #fff;
    }
  }
`;
