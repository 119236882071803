import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import DeleteTwoTone from "@mui/icons-material/DeleteTwoTone";
import { Spin } from "antd";
import CP from "core/components/common";
import Pagination from "core/components/common/Pagination";
import Paging from "core/components/common/Paging";
import { ListItem } from "core/components/common/Select";
import { PageListOption } from "core/models/Common";
import { theme } from "core/styles/theme";
import { castToMobile, castToNumberComma } from "core/utils/FormatUtil";
import { uuid } from "core/utils/index";
import _ from "lodash";
import useExcel from "../../hooks/useExcel";

import { ForwardedRef, forwardRef, useEffect, useState } from "react";
import {
  Column,
  HeaderGroup,
  Row,
  TableBodyPropGetter,
  TableBodyProps,
  TablePropGetter,
  TableProps,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable
} from "react-table";
import styled, { css } from "styled-components";
import CheckBox from "./CheckBox";

export type DirType = "ASC" | "DESC";

export interface CustomHeaderGroup extends HeaderGroup {
  visible?: boolean;
}
export interface DataTableProps {
  className?: string;
  paging?: boolean; // 페이징 여부
  totalCount?: boolean; // 총건수 표시 여부
  isTotalCountAll?: boolean; // 총건수 selectbox에 전체 표시 여부 ( 99999만개로 셋팅 )
  empty?: boolean; //데이터 없을 때 한줄 넣을지
  emptyMessage?: string; //데이터 없을 때 넣은 한줄 메시지
  data: readonly object[]; //보여줄 데이터 ROW
  columns: Column<any>[] | Column[]; // Header , accessor , type
  excelColumns?: Column<any>[] | Column[]; // Header , accessor , type
  //  type : 'number' | 'date' |
  onSelect?: (row: any) => void; // tr 클릭 시 해당 data 리턴
  onDoubleClick?: (row: any) => void; // tr 더블 클릭 시 해당 data 리턴
  columSizeList?: number[]; //컬럼 별 flex 사이즈 , 없을 경우 다 flex : 1
  style?: any;
  tableWrapperStyle?: any;
  title?: string | string[]; //header 상위 title
  isSearchBar?: boolean;
  isTools?: boolean; //header tools
  isFooter?: boolean; //footer
  searchText?: string;
  onChangeSearchText?: (value: string) => void;
  scrollRow?: number; // 최대 로우 이상 일 경우 스크롤이 필요할 때 최대 로우 수
  selectedItem?: any[]; //onSelect 로 선택한 data
  selectedItem2?: any; //onSelect 로 선택한 data
  isSelected?: boolean;
  checkedItem?: any[]; // onCheck 로 선택한 data
  onCheck?: (row: any) => void; //row 앞 check박스 누를 시 이벤트
  onCheckAll?: () => void; //전체 체크 시 발생되는 이벤트
  onCheckDeleteAll?: () => void; //전체 체크 해제 시 발생되는 이벤트
  checkYn?: boolean; // Row 앞 check박스 노출 여부
  left?: any;
  right?: any; // 상단 위 영역
  pageOption?: PageListOption;
  setPageOption?: (item: any) => void;
  rowCount?: boolean; // row 앞 No 로 번호 노출여부
  totalPages?: number; // 총 페이지수
  styleType?: "list" | "form";
  overWidth?: boolean;
  excel?: boolean; //엑셀 true , false
  setExcel?: (e: boolean) => void;
  onChangeExcel?: () => void; //엑셀 다운로드 후 호출되는 이벤트
  tableName?: string; // 테이블 명 ( Excel 명에서 사용)
  loading?: boolean; //Table 로딩
  header?: any; //custom Header
  customBody?: () => any; //custom body
  isSort?: boolean; //정렬여부
  sort?: string; //컬럼 키
  dir?: DirType; // [ASC, DESC]
  onChangeSort?: (sort: string, dir: DirType) => void;
  onRemove?: (item: any) => void;
  onUp?: (item: any) => void;
  onDown?: (item: any) => void;
}

interface PureTableProps extends DataTableProps {
  // className?: string;
  getTableProps: (
    propGetter?: TablePropGetter<object> | undefined
  ) => TableProps;
  getTableBodyProps: (
    propGetter?: TableBodyPropGetter<object> | undefined
  ) => TableBodyProps;
  headerGroups: CustomHeaderGroup[];
  page: Row<object>[];
  prepareRow: (row: Row<object>) => void;
  footerGroups: HeaderGroup<object>[];
}

export const PAGE_PER_COUNT: ListItem[] = [
  {
    name: "20",
    value: "20"
  },
  {
    name: "50",
    value: "50"
  }
];

export default forwardRef(function DataTable(
  res: DataTableProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  const {
    columns,
    excelColumns,
    title,
    data,
    paging = true,
    totalCount = true,
    isTotalCountAll = false,
    isSelected = false,
    columSizeList,
    pageOption,
    setPageOption,
    totalPages,
    excel,
    style,
    onChangeExcel,
    setExcel,
    tableName,
    loading,
    header,
    customBody
  } = res;

  const {
    getTableProps,
    getTableBodyProps,
    footerGroups,
    headerGroups,
    prepareRow,
    rows,
    page,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows
  } = useTable(
    {
      columns,
      data,
      autoResetPage: false
    },
    useSortBy,
    usePagination,
    useRowSelect
  );

  const { getExcel } = useExcel({
    tableName,
    data,
    columns: excelColumns ? excelColumns : columns
  });
  const [pagingYn, setPagingYn] = useState(false);

  //데이터 변경 시 리스트 스크롤 상단으로
  useEffect(() => {
    document.getElementById("tableWrapper")?.scrollTo(0, 0);
  }, [data]);

  useEffect(() => {
    if (
      data?.length > 0 &&
      paging &&
      totalPages &&
      pageOption &&
      setPageOption
    ) {
      setPagingYn(true);
    } else {
      setPagingYn(false);
    }
  }, [data, paging, totalPages, pageOption, setPageOption]);

  //처음 로드 시 페이징 기본값으로 셋팅
  useEffect(() => {
    if (pageOption && totalCount && setPageOption) {
      if (!pageOption?.perSize) {
        setPageOption({
          ...pageOption,
          take: PAGE_PER_COUNT[0].value,
          perSize:
            typeof PAGE_PER_COUNT[0].value === "string"
              ? parseInt(PAGE_PER_COUNT[0].value)
              : PAGE_PER_COUNT[0].value
        });
      }
    } else {
      //페이징이 필요없을 때
      setPageSize(999999999999);
    }
  }, []);

  //페이징 없을 때 자체 페이징
  useEffect(() => {
    if (pageOption?.pageType === "F") {
      gotoPage(pageOption.page - 1);
    }
  }, [pageOption?.page]);

  //페이징 수 변경 시 paging 리셋 & 페이지사이즈 셋팅
  useEffect(() => {
    if (!pageOption?.take) return;

    setPageSize(pageOption?.perSize);

    if (pageOption?.pageType === "F") {
      gotoPage(0);
    }
    if (setPageOption) {
      setPageOption({
        ...pageOption,
        skip: "0",
        take: pageOption.perSize + "",
        page: 1
      });
    }
  }, [pageOption?.perSize]);

  useEffect(() => {
    if (!excel) return;
    else if (excel === true) {
      getExcel();
      // useExcel({ tableName, columns, data });
      if (onChangeExcel) onChangeExcel();
      if (setExcel) {
        setTimeout(() => {
          setExcel(false);
        }, 200);
      }
    }
  }, [excel]);

  return (
    <DataTableContainer
      className="dataTableClass"
      style={{
        height: `calc(100% - ${pagingYn ? 80 : 0}px)`,
        width: "100%",
        ...style
      }}
      ref={ref}
    >
      <PureTable
        {...res}
        footerGroups={footerGroups}
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        headerGroups={headerGroups}
        page={page}
        isSelected={isSelected}
        prepareRow={prepareRow}
        loading={loading}
        header={header}
      />

      {pagingYn && (
        <Paging
          total={totalPages}
          pageOption={pageOption}
          setPageOption={setPageOption}
        />
      )}
    </DataTableContainer>
  );
});

export function PureTable({
  className,
  isTotalCountAll,
  title,
  footerGroups,
  isSearchBar,
  isTools = false,
  isFooter = false,
  searchText,
  onChangeSearchText,
  getTableProps,
  getTableBodyProps,
  headerGroups,
  page,
  prepareRow,
  columSizeList,
  overWidth,
  onSelect,
  onDoubleClick,
  totalCount,
  empty = true,
  emptyMessage,
  columns,
  style,
  tableWrapperStyle,
  scrollRow,
  selectedItem,
  selectedItem2,
  isSelected,
  checkedItem,
  checkYn,
  left,
  right,
  rowCount,
  data,
  onCheck,
  onCheckAll,
  onCheckDeleteAll,
  totalPages,
  pageOption,
  setPageOption,
  styleType,
  loading,
  header,
  isSort = false,
  sort,
  dir,
  onChangeSort,
  onRemove,
  onUp,
  onDown,
  customBody
}: PureTableProps) {
  useEffect(() => {
    if (!isSort) return;

    changeSort();
  }, [sort, dir]);
  const dataFormat = (data: any, style = {}) => {
    const type = data.props.cell.column?.type;

    if (!type) return <div style={style}>{data} </div>;

    let _value = data.props.cell.value;

    let ret;
    if (type === "number") {
      ret = castToNumberComma(_value);
    } else if (type === "mobile") {
      ret = castToMobile(_value);
    }

    if (!ret) return <div>{data} </div>;
    return <div>{ret}</div>;
  };

  const getHeaderHeight = () => {
    let _height: number = 87;
    if (!title && !isSearchBar && !isTools) _height = -1;
    else if (title && !isSearchBar && !isTools) _height = 39;
    else if (isTools) _height = 67 + 8;
    return _height;
  };

  const changeSort = () => {
    if (!isSort) return;
    headerGroups.map((headerGroup) => {
      headerGroup.headers.map((header) => {
        header.isSorted = false;
        const isKey: boolean = Object.keys(header).includes("accessor");
        if (!isKey) header.isSorted = false;
        else {
          if (sort === String(header ?? ["accessor"])) header.isSorted = true;
          else header.isSorted = false;
          // header.isSortedDesc = dir === 'ASC' ? false : true;
        }
      });
    });
  };

  return (
    <DataTableWrapper>
      {(totalCount || right) && (
        <Section>
          {totalCount && (
            <TotalCountWrapper>
              <TotalCount>
                전체
                <strong>
                  {totalPages !== undefined
                    ? totalPages
                    : data && data?.length
                      ? data.length
                      : 0}
                  건
                </strong>
              </TotalCount>
              <CP.Select
                style={{ width: 82, minWidth: 82 }}
                list={
                  isTotalCountAll
                    ? PAGE_PER_COUNT.concat([{ name: "전체", value: "99999" }])
                    : PAGE_PER_COUNT
                }
                selected={pageOption?.perSize + ""}
                setSelect={
                  (e: any) => setPageOption({ ...pageOption, perSize: e.value })
                  // setPageOption({ ...pageOption, take: e.value + '' })
                }
              />

              {left && left}
            </TotalCountWrapper>
          )}
          <Spacer />

          {right && right}
        </Section>
      )}

      <TableWrapper
        id={"tableWrapper"}
        select={onSelect ? true : false}
        className={className}
        columSizeList={columSizeList}
        overWidth={overWidth}
        scrollRow={scrollRow}
        style={{ ...style }}
        // style={isFooter ? {height : '100%', justifyContent:'space-between', display:'flex', flexDirection:'column'} : { ...style,  ...tableWrapperStyle}}
        checkYn={checkYn}
        rowCount={rowCount}
        type={styleType ? styleType : "list"}
        totalCountYn={totalCount || right ? true : false}
        columnLength={columns?.length}
        headerFixHeight={getHeaderHeight()}
      >
        {title && (
          <DataTableHeader
            style={
              isSearchBar
                ? { borderBottom: "1px solid #ccc" }
                : { borderBottom: "1px solid #ccc" }
            }
          >
            <DataTableHeaderTitle>{title}</DataTableHeaderTitle>
          </DataTableHeader>
        )}
        {isSearchBar && (
          <SearchBar style={{ borderBottom: "1px solid #ccc" }}>
            <CP.Input
              type="text"
              value={searchText}
              onChange={(e) => onChangeSearchText(e.target.value)}
              placeholder="입력하세요"
              style={{ height: 30 }}
            />
          </SearchBar>
        )}

        {isTools && (
          <SearchBar
            style={{ borderBottom: "1px solid #ccc", padding: 4, height: 36 }}
          >
            <CP.Flex style={{ alignItems: "center" }} gap="4px">
              <CP.Button
                onClick={onRemove}
                style={{
                  padding: 0,
                  paddingRight: 5,
                  paddingTop: 5,
                  paddingBottom: 5,
                  height: 28
                }}
                variant="text"
                color={"black"}
              >
                <DeleteTwoTone sx={{ color: theme.colors.primary }} />
                삭제
              </CP.Button>
              <div
                style={{ width: 1, height: 15, backgroundColor: "#b2b2b2" }}
              />
              <CP.Button
                onClick={onUp}
                style={{
                  padding: 0,
                  paddingRight: 5,
                  paddingTop: 5,
                  paddingBottom: 5,
                  height: 28
                }}
                variant="text"
                color="black"
              >
                <ArrowUpwardIcon sx={{ color: theme.colors.red }} />
                위로
              </CP.Button>
              <div
                style={{ width: 1, height: 15, backgroundColor: "#b2b2b2" }}
              />
              <CP.Button
                onClick={onDown}
                style={{
                  padding: 0,
                  paddingRight: 5,
                  paddingTop: 5,
                  paddingBottom: 5,
                  height: 28
                }}
                variant="text"
                color="black"
              >
                <ArrowDownwardIcon sx={{ color: theme.colors.blue }} />
                아래로
              </CP.Button>
            </CP.Flex>
          </SearchBar>
        )}
        <table
          {...getTableProps()}
          style={
            title
              ? {
                  borderTop:
                    title || isSearchBar || isTools
                      ? "0px solid #ccc"
                      : "1px solid #ccc"
                }
              : {}
          }
        >
          {header ? (
            header
          ) : (
            <thead style={{ zIndex: 2 }}>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={uuid()}>
                  {checkYn && (
                    <th
                      style={{
                        minWidth: 40,
                        width: 40,
                        whiteSpace: "nowrap",
                        height: "43.48px"
                        // padding: 16,
                      }}
                    >
                      <ThDiv check={true}>
                        <CheckBox
                          onClick={() => {
                            try {
                              if (data?.length === checkedItem?.length)
                                return onCheckDeleteAll();
                              onCheckAll();
                            } catch (err) {}
                          }}
                          checked={
                            data?.length === checkedItem?.length ? true : false
                          }
                        />
                      </ThDiv>
                    </th>
                  )}
                  {rowCount && (
                    <th className="no">
                      <ThDiv>No</ThDiv>
                    </th>
                  )}

                  {headerGroup.headers.map((column: CustomHeaderGroup) => {
                    if (column?.visible === false) return;

                    const label: any = column.render("Header");
                    if (label === "번호")
                      return (
                        <th
                          style={{
                            width: column.width,
                            maxWidth: column.width,
                            cursor: isSort ? "pointer" : "default"
                          }}
                          key={uuid()}
                          onClick={() => {
                            if (!isSort) return;
                            let _dir: DirType = "ASC";
                            if (sort === column.id) {
                              if (dir === "ASC") _dir = "DESC";
                            }
                            onChangeSort(column.id, _dir);
                            column.isSorted = true;
                          }}
                        >
                          <ThDiv>
                            {label}{" "}
                            {sort === column.id ? (
                              dir === "ASC" ? (
                                <CaretUpOutlined />
                              ) : (
                                <CaretDownOutlined />
                              )
                            ) : (
                              ""
                            )}
                          </ThDiv>
                        </th>
                      );
                    return (
                      <th
                        {...column.getHeaderProps()}
                        className={column?.id ? column.id : ""}
                        key={uuid()}
                        onClick={() => {
                          if (!isSort) return;
                          let _dir: DirType = "ASC";
                          if (sort === column.id) {
                            if (dir === "ASC") _dir = "DESC";
                          }
                          onChangeSort(column.id, _dir);
                          column.isSorted = true;
                        }}
                      >
                        <ThDiv
                          style={{ cursor: isSort ? "pointer" : "default" }}
                        >
                          {label}{" "}
                          {sort === column.id ? (
                            dir === "ASC" ? (
                              <CaretUpOutlined />
                            ) : (
                              <CaretDownOutlined />
                            )
                          ) : (
                            ""
                          )}
                        </ThDiv>
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
          )}

          {!loading && (!selectedItem2 || !_.isEmpty(selectedItem2)) && (
            <tbody {...getTableBodyProps()}>
              {}
              {page?.length > 0 ? (
                <>
                  {customBody
                    ? customBody()
                    : page.map((row, i) => {
                        prepareRow(row);
                        let bgColor = "transparent";

                        if (selectedItem) {
                          if (
                            selectedItem?.find(
                              // (item, index) => item === row?.values?.index,
                              (item, index) => i === item?.index
                            )
                          )
                            bgColor = "#F0EEFF";
                        } else if (selectedItem2) {
                          const { index, tdIndex, ...rows }: any = row.original;
                          if (_.isEqual(selectedItem2, row.original)) {
                            bgColor = "#F0EEFF";
                          } else if (_.isEqual(selectedItem2, rows)) {
                            bgColor = "#F0EEFF";
                          }
                        }

                        return (
                          <tr
                            {...row.getRowProps()}
                            key={uuid()}
                            style={{
                              backgroundColor: bgColor
                              // backgroundColor : selectedItem?
                            }}
                          >
                            {checkYn && (
                              <td style={{ minWidth: 40, width: 40 }}>
                                <CheckBox
                                  onClick={
                                    onCheck
                                      ? () => onCheck(row.original)
                                      : () => {
                                          // console.log({ row });
                                        }
                                  }
                                  value={row}
                                  checked={
                                    checkedItem?.length > 0 &&
                                    checkedItem?.find(
                                      (item) => item === row.original
                                    )
                                      ? true
                                      : false
                                  }
                                />
                              </td>
                            )}
                            {rowCount && pageOption && (
                              <td className="no">
                                {(typeof pageOption.skip === "string"
                                  ? parseInt(pageOption.skip)
                                  : pageOption.skip) +
                                  i +
                                  1}
                                {/* 넘버 들어오는곳  */}
                              </td>
                            )}
                            {row.cells.map((cell: any, index) => {
                              if (cell?.column?.visible === false) return;

                              return (
                                <td
                                  {...cell.getCellProps()}
                                  key={uuid()}
                                  className={cell?.column?.id} // props에 있는걸로 써야해서..
                                  // style={cell?.column?.style ? cell.column.style : {}}
                                  onDoubleClick={() => {
                                    if (!onDoubleClick) return;
                                    onDoubleClick(row.original);
                                  }}
                                  onClick={
                                    onSelect
                                      ? () => {
                                          if (isSelected)
                                            return onSelect(row.original);

                                          onSelect({
                                            ...row?.original,
                                            index: i,
                                            tdIndex: index
                                          });
                                        }
                                      : () => {
                                          // console.log('Select');
                                        }
                                  }
                                >
                                  {dataFormat(
                                    cell.render("Cell"),
                                    cell?.column?.style ? cell.column.style : {}
                                  )}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                </>
              ) : (
                <>
                  {empty && (
                    <tr key={uuid()} className="emptyTr">
                      <td
                        colSpan={
                          checkYn && rowCount
                            ? columns?.length + 2
                            : checkYn || rowCount
                              ? columns?.length + 1
                              : columns?.length
                        }
                        style={{
                          minHeight: "40px"
                        }}
                      >
                        {emptyMessage && <p>{emptyMessage}</p>}
                      </td>
                    </tr>
                  )}
                </>
              )}
            </tbody>
          )}

          {!loading && isFooter && (
            <tfoot>
              {footerGroups.map((footerGroup) => (
                <tr {...footerGroup.getFooterGroupProps()}>
                  {footerGroup.headers.map((column) => (
                    <td {...column.getFooterProps}>
                      <div>{column.render("Footer")}</div>
                    </td>
                  ))}
                </tr>
              ))}
            </tfoot>
          )}
        </table>
      </TableWrapper>

      {loading && (
        <div
          style={{
            position: "relative",
            top: 0,
            width: "100%",
            height: "100%"
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%"
            }}
          >
            <Spin tip="Loading..." style={{ marginBottom: 42 }} />
          </div>
        </div>
      )}
    </DataTableWrapper>
  );
}

const ThDiv = styled.div<{ check?: boolean }>`
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;

  background: #f9f9f9;
  padding: 12px 16px;
  height: 100%;
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;

  ${(props) =>
    props.check === true &&
    css`
      display: flex;
      //  height : 40px
      padding: 0px;
      justify-content: center;
      align-items: center;
    `}
`;

const DataTableContainer = styled.div`
  @media print {
    @page {
      size: A4;
      /* size : landscape */
    }
  }
`;

const DataTableWrapper = styled.div`
  width: 100%;
  overflow-x: hidden;
  height: 100%;
  overflow-y: hidden;
  padding-bottom: 10px;
`;

const DataTableHeader = styled.div`
  padding: 12px 16px;
  height: 40px;
  background-color: #f9f9f9;
  /* border-bottom: 1px solid #ccc; */
  position: sticky;
  top: -1px;
  z-index: 1;
`;

const DataTableHeaderTitle = styled.div`
  color: var(--black, #222);
  font-family: Pretendard;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 2px;
`;

const SearchBar = styled.div`
  height: 48px;
  width: 100%;
  padding: 8px;
  position: sticky;
  background-color: white;
  top: 39px;
`;

export const TableWrapper: any = styled.div<{
  select?: boolean;
  columSizeList?: number[];
  overWidth?: boolean;
  scrollRow?: number;
  checkYn?: boolean;
  rowCount?: boolean;
  type: "list" | "form";
  totalCountYn?: boolean;
  columnLength?: number;
  headerFixHeight: number;
}>`
  width: 100%;

  border-top: 1px solid #9f9f9f;
  overflow: auto;
  max-height: ${({ totalCountYn }) =>
    `calc(100% - ${totalCountYn ? 72 : 0}px )`};

  border-spacing: 0px;
  border-collapse: separate;
  // height : 100%;
  & > table {
    width: ${(props) => (props.overWidth ? "max-content" : "100%")};
    // height : 100%;
    overflow: auto;
    // table-layout: fixed;
    //  display : block;
    flex-direction: column;
  }

  & > table > thead > tr {
    width: 100%;
    // position: sticky;
    top: 0px;
    & .no,
    .idx {
      width: 50px !important;
      max-width: 50px;
    }
  }
  & > table > tbody > tr {
    width: 100%;
    & .no,
    .idx {
      & > div {
        padding: 0px;
      }
      padding: 16px;
      width: 50px !important;
      max-width: 50px;
    }
  }
  & > table > tfoot > tr {
    width: 100%;
  }

  & table > thead {
    // height: 100%;
    position: sticky;
    /* top: -1px; */
    //title height 40
    //searchbar height 48
    top: 86px;
    top: ${({ headerFixHeight }) => `${headerFixHeight}px`};
    // display: table; /* to take the same width as tr */
    width: calc(100%); /* - 17px because of the scrollbar width */
    // display : inline-table;
    z-index: 1;
  }

  & > table > thead > tr,
  & > table > tfoot > tr,
  & > table > tbody > tr {
    // display: table; /* display purpose; th's border */
    width: 100%;
  }

  & > table > thead > tr > th {
    ${(props) =>
      props.columSizeList?.map((size, index) => {
        let child = index + 1;

        if (props.checkYn) {
          child = child + 1;
        }

        if (props.rowCount) {
          child = child + 1;
        }

        return `
            &:nth-child(${child}) {
              // width: ${size}px;
              width : ${
                (100 / props.columSizeList.reduce((a, b) => a + b)) * size
              }%;
            }
          `;
      })};

    // position: sticky;
    top: 0px;
    background: #f9f9f9;
    font-weight: 700;
    font-size: 13px;
    line-height: 17px;

    color: ${({ theme }) => theme.colors.black222};
    // height: 40px;
    // border: 1px solid #cccccc;
    &:first-child {
      border-left: none;
    }
    &:last-child > div {
      border-right: none;
    }

    // border-top: 1px solid #9f9f9f;

    sub {
      font-size: 13px;
    }
  }

  /* & > table > tfoot, */
  & > table > tbody {
    // width: 100%;
    overflow-x: hidden;
    height: calc(100% - 45px);
    /* max-height: ${(props) =>
      props.scrollRow ? props.scrollRow * 40 + "px" : ""}; //+ 52 */

    // display: block; /* to enable vertical scrolling */

    // max-height: 200px; /* e.g. */
    overflow-y: auto;

    & > tr:hover {
      background-color: #f0eeff !important;
    }
  }

  /* & > table > tfoot > tr > td, */
  & > table > tbody > tr > td {
    & > div {
      padding: 0 16px;
    }
    cursor: ${({ select }) =>
      select && "pointer"}; //선택 이벤트 있을 시 커서 포인터

    /* padding: 16px 0; */
    // padding: 0 16px;
    white-space: nowrap;

    height: 40px;
    text-align: center;
    vertical-align: middle;
    font-size: 13px;
    border-bottom: 1px solid #eee;

    // text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    ${(props) =>
      props.columSizeList?.map((size, index) => {
        let child = index + 1;

        if (props.checkYn) {
          child = child + 1;
        }
        if (props.rowCount) {
          child = child + 1;
        }
        return `
            &:nth-child(${child}) {
              // width: ${size}px;
              width : ${
                (100 / props.columSizeList.reduce((a, b) => a + b)) * size
              }%;
            }
          `;
      })};
  }

  & > table > tfoot > tr > td {
    & > div {
      padding: 0 16px;
    }
    cursor: ${({ select }) =>
      select && "pointer"}; //선택 이벤트 있을 시 커서 포인터

    /* padding: 16px 0; */
    // padding: 0 16px;
    white-space: nowrap;

    height: 40px;
    text-align: center;
    vertical-align: middle;
    font-size: 13px;

    // text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    ${(props) =>
      props.columSizeList?.map((size, index) => {
        let child = index + 1;

        if (props.checkYn) {
          child = child + 1;
        }
        if (props.rowCount) {
          child = child + 1;
        }
        return `
            &:nth-child(${child}) {
              // width: ${size}px;
              width : ${
                (100 / props.columSizeList.reduce((a, b) => a + b)) * size
              }%;
            }
          `;
      })};
  }

  & > table > tbody,
  & > table > thead {
    & > tr {
      // display :flex;
      width: max-content;
      & > td,
      > th {
        overflow: hidden;
        // display : flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  & > table > tfoot {
    position: sticky;
    left: 0px;
    bottom: -1px;
    height: 40px;
    width: 100%;
    background-color: white;
    border-spacing: 0;
    border-collapse: separate;
    z-index: 1;
    & > tr {
      width: 100%;
    }

    & > tr > td > div {
      border-top: 1px solid red;
      border-left: 1px solid #eee;
      border-right: 1px solid #eee;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  tbody > tr > td {
    div:has(input) {
      padding: 0px 4px;

      input {
        border: 0px;
        border-bottom: 1px solid #ccc;
        border-radius: 0px;
      }
    }
  }
`;

export const PaginationWrapper: any = styled(Pagination)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 64px;
`;

const Section = styled.section`
  padding-top: 24px;
  padding-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;

const TotalCountWrapper = styled.div`
  display: flex;
  gap: 16px;
  // padding: 8px;
  align-items: center;
`;

const TotalCount = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  white-space: nowrap;
  & > strong {
    font-weight: 700;
  }
`;

// const PageSelect = styled(CP.Select)`
//   width: 82px;
//   min-width: 82px;
//   // height: 40px;
// `;

const Spacer = styled.div`
  flex: 1;
`;
