import * as S from "core/components/Styled";
import { monthFormat } from "core/utils/FormatUtil";
import moment from "moment";
import { useEffect, useState } from "react";
import styled from "styled-components";

interface IProps {
  date: string; //YYYY-MM
  list?: { date: string; content?: any; style?: any }[];
}
interface dateProps {
  day: number | string;
  date: string;
  current: boolean;
}

export const WEEK = [
  { id: "0", name: "일", en: "Sun" },
  { id: "1", name: "월", en: "Mon" },
  { id: "2", name: "화", en: "Tue" },
  { id: "3", name: "수", en: "Wed" },
  { id: "4", name: "목", en: "Thu" },
  { id: "5", name: "금", en: "Fri" },
  { id: "6", name: "토", en: "Sat" },
];

export default function MonthCalendar({ date, list }: IProps) {
  const [month, setMonth] = useState(moment(date, "YYYY-MM").format("MM"));
  const [year, setYear] = useState(moment(date, "YYYY-MM").format("YYYY"));

  const [monthList, setMonthList] = useState([]);

  useEffect(() => {
    setMonthList(
      monthFormat({
        year: moment(date, "YYYY-MM").format("YYYY"),
        month: moment(date, "YYYY-MM").format("MM"),
      })
    );

    setMonth(moment(date, "YYYY-MM").format("MM"));
    setYear(moment(date, "YYYY-MM").format("YYYY"));
  }, [date]);

  const settingStyle = (week: dateProps) => {
    if (!week?.current) return { background: "#F9F9F9" };

    const style = list?.find((item) => item?.date === week?.date)?.style;

    if (!style) return {};

    return style;
  };
  return (
    <>
      <StyleCalendar>
        <thead>
          <tr>
            {WEEK?.map((item, index) => (
              <th key={"week" + index}>
                <S.StyledText>{item.name}</S.StyledText>
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {monthList?.map((item: dateProps[], idx: number) => {
            return (
              <tr key={"day" + idx}>
                {item &&
                  item.length > 0 &&
                  item.map((week: dateProps, index: number) => {
                    if (!date) return null;

                    const day =
                      year +
                      "-" +
                      month +
                      "-" +
                      ((week.day + "").length === 1
                        ? "0" + week.day
                        : week.day);
                    if (!day) return null;

                    const _event = list?.filter((i: any) =>
                      Object.keys(i).find((it) => it === day)
                    );

                    const holiday = week.current === false ? true : false;
                    const today =
                      week.current === true &&
                      day === moment().format("YYYY-MM-DD")
                        ? true
                        : false;

                    return (
                      <td key={"date" + index} style={settingStyle(week)}>
                        <>
                          <div>
                            <DayCircle today={today}>
                              <DayText holiday={holiday} today={today}>
                                {week.day}
                              </DayText>
                            </DayCircle>
                          </div>
                          {list &&
                            list?.map((item: any) => {
                              if (item.date !== week?.date) return;

                              let content = undefined;

                              if (item?.date === week?.date) {
                                content = item?.content;
                              }

                              if (!content) return <></>;

                              return (
                                <div key={"month_item" + month}>{content}</div>
                              );
                              // }
                            })}
                        </>
                      </td>
                    );
                  })}
              </tr>
            );
          })}
        </tbody>
      </StyleCalendar>
    </>
  );
}

const StyleCalendar = styled.table`
  //   margin-top: 16px;
  table-layout: fixed;

  & > thead > tr {
    height: 40px;

    & > th {
      padding: 8px;

      border-width: 0px 1px 1px 0px;
      border-style: solid;
      border-color: #cccccc;
      border-top: 1px solid #9f9f9f;
      height: 100%;

      & > h1 {
        font-size: 15px;
        line-height: 24px;
        font-weight: 700;
      }
    }
  }

  & > tbody {
    & > tr {
      & > td {
        height: 100%;
        padding: 8px;
        border-width: 0px 1px 1px 0px;
        border-style: solid;
        border-color: #cccccc;
        text-align: right;
        // height: 89.26px;
        // width: 89.26px;
        // max-height: 89.26px;
        // max-width: 89.26px;


        // & > div {
        //   width: 100%;

        & > div {
          display: flex;
          justify-content: flex-end;
          gap: 2px;

          &:first-child {
            // height: 100%;
          }
        }
        // }
      }
    }
  }

  & > thead,
  & > tbody {
    table-layout: fixed;
    width: 100%;
    & > tr {
      display: flex;
      width: 100%;

     td:first-child , th:first-child   {
      & h1 {
        color: #ff6969;
      }


      };

     td:last-child  , th:last-child {
        border-right: 0px;
        &  h1 {
          color: #0084ff;
        }
      };

      & > th,
      & > td {
        display: flex;
        flex: 1;


      }

      & > th{
        justify-content : center;
      }

      & > td {
        height: 89.26px;
        max-height: 89.26px;

        flex-direction: column;
        justify-content: space-between;
      }
    }
  }
`;

const ColorTag = styled.div<{ color: string; size?: string }>`
  background-color: ${({ color }) => color};
  width: ${({ size }) => size || "8px"};
  height: ${({ size }) => size || "8px"};
  border-radius: 100px;
`;

const DayCircle = styled.div<{ today: boolean }>`
  width: 24px;
  height: 24px;
  background-color: ${({ today, theme }) => today && theme.colors.primary};
  border-radius: 50px;
`;
const DayText = styled(S.StyledText)<{ holiday: boolean; today: boolean }>`
  color: ${({ holiday, today }) =>
    holiday ? "#CCCCCC" : today ? "#FFF" : "#222"};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
