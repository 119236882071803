import {
  BellIcon,
  GuideIcon,
  NoticeIcon
} from "core/components/common/icons/index";
import { COMPANY_CODE } from "core/constants/enums";
import Image from "next/image";
import styled from "styled-components";
import UserProfile from "./UserProfile";

interface HeaderProps {
  company?: COMPANY_CODE;
  marginLeft?: number;
  loginUrl: string;
  logoutUrl: string;
  uploadUrl: string;
  onUpdate: () => void;
}

export default function Header({
  company = COMPANY_CODE.Adpia,
  marginLeft = 0,
  loginUrl,
  logoutUrl,
  uploadUrl,
  onUpdate
}: HeaderProps) {
  const getCompanyIcon = () => {
    if (company === COMPANY_CODE.AdpiaMall)
      return "/images/brand/adpiamall-verical-on.svg";
    else if (company === COMPANY_CODE.FoodMall)
      return "/images/brand/foodmall-vertical-on.svg";

    return "/images/brand/adpia-verical-on.svg";
  };
  return (
    <HeaderComponent marginLeft={marginLeft}>
      <Image
        src={getCompanyIcon()}
        width={125}
        height={24}
        alt="headmenu logo"
        style={{ marginRight: "auto" }}
      />
      <GuideIcon />
      <NoticeIcon />
      <BellIcon />
      <UserProfile
        loginUrl={loginUrl}
        logoutUrl={logoutUrl}
        uploadUrl={uploadUrl}
        onUpdate={onUpdate}
      />
    </HeaderComponent>
  );
}

const HeaderComponent = styled.header<{ marginLeft: number }>`
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 16px 24px;
  margin-left: ${({ marginLeft }) => marginLeft}px;
  height: ${({ theme }) => theme.headerHeight}px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-bottom: 1px solid #9f9f9f;
  position: fixed;
  width: ${({ marginLeft }) => `calc(100% - ${marginLeft}px)`};

  // 1. react-spring-bottom-sheet 적용으로 조절했습니다.
  // 2. sidebar 보다 높게 바꿈 2=>4
  z-index: 4; 
  top: 0;
  gap: 8px;
`;
