import moment from "moment";
import {
  Column,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable
} from "react-table";
import generateExcel from "zipcelx";

export interface ExcelProps {
  tableName?: string;
  data?: readonly object[]; //보여줄 데이터 ROW
  columns: Column<any>[] | Column[]; // Header , accessor , type
}
const getHeader = (column: any) => {
  if (column.totalVisibleHeaderCount === 1) {
    return [
      {
        ...column,
        value: column.Header,
        type: "string"
      }
    ];
  } else {
    const span = [...Array(column.totalVisibleHeaderCount - 1)].map((x) => ({
      value: "",
      type: "string"
    }));
    return [
      {
        value: column.Header,
        type: "string"
      },
      ...span
    ];
  }
};

export default function useExcel({ tableName, data, columns }: ExcelProps) {
  const { headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
      autoResetPage: false
    },
    useSortBy,
    usePagination,
    useRowSelect
  );

  // console.log({ rows });
  const getExcel = () => {
    const config: any = {
      filename: tableName
        ? `${tableName}-${moment().format("YYYYMMDD")}`
        : `excel-${moment().format("YYYYMMDD")}`,
      sheet: {
        data: []
      }
    };
    const dataSet = config.sheet.data;
    headerGroups.forEach((headerGroup) => {
      const headerRow: any[] = [];
      if (headerGroup.headers) {
        headerGroup.headers.forEach((column) => {
          if (column.Header === "내용" && tableName === "사내공지") {
          } else {
            headerRow.push(...getHeader(column));
          }
        });
      }
      dataSet.push(headerRow);
    });
    console.log({ rows }, { data });
    if (rows.length > 0) {
      rows.forEach((row) => {
        prepareRow(row);
        const dataRow: any = [];
        Object.values(row.original).forEach((value, idx) => {
          if (!row?.cells[idx]) return;
          if (!row.cells[idx]?.column) return;

          const Cell: any = row.cells[idx].column.Cell;
          try {
            value =
              typeof Cell(row.cells[idx]) === "string"
                ? Cell(row.cells[idx])
                : Cell(row.cells[idx]).props.children;
          } catch (err) {}

          if (
            row.cells[idx].column.Header === "내용" &&
            tableName === "사내공지"
          ) {
          } else {
            dataRow.push({
              value: value ? (value.length > 1000 ? "" : value) : "",
              type: typeof value === "number" ? "number" : "string"
            });
          }
        });

        dataSet.push(dataRow);
      });
    } else {
      dataSet.push([
        {
          value: "No data",
          type: "string"
        }
      ]);
    }

    generateExcel(config);
  };

  return { getExcel };
}
