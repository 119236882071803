import { Dialog } from "@headlessui/react";
import * as S from "core/components/Styled";
import CP from "core/components/common";
import { ModalType } from "core/constants/enums";
import styled from "styled-components";

export interface ModalProps {
  isOpen: boolean;
  setIsOpen?: (e: boolean) => void;
  children?: React.ReactNode;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  contentHeight?: string;
  contentWidth?: string;
  contentStyle?: any;
  title?: string;
  close?: boolean;
  className?: string;
  style?: any;
  form?: boolean;
  modalType?: ModalType;
  onClickEdit?: (e: any) => void;
  edit?: boolean;
  download?: boolean;
  downloadHref?: string;
}

export default function Modal({
  children,
  isOpen,
  setIsOpen = () => {},
  footer,
  header,
  title,
  close,
  contentHeight,
  contentWidth,
  contentStyle,
  style,
  form,
  className,
  modalType = ModalType.None,
  edit = true,
  download = false,
  downloadHref = "",
  onClickEdit
}: ModalProps) {
  return (
    <DialogWrapper
      // onClose={() => console.log("close")}
      // onBlur={() => console.log("onBlur")}
      onClose={() => {
        setIsOpen(false);
      }}
      open={isOpen}
      className={className}
    >
      {form === true ? (
        <form>
          <DialogPanel style={style}>
            {(title || close || header) && (
              <Header>
                <CP.Flex>
                  {title && <TitleText>{title} </TitleText>}
                  {header && header}
                </CP.Flex>
                {modalType === ModalType.View ? (
                  <CP.Flex gap="8px">
                    {edit && <CP.Icon.EditIcon onClick={onClickEdit} />}
                    {download && (
                      <a
                        rel="noopener noreferrer"
                        target="_blank"
                        href={downloadHref}
                      >
                        <CP.Icon.DownloadIcon />
                      </a>
                    )}
                    {close && (
                      <CP.Icon.CloseIcon
                        onClick={() => {
                          setIsOpen(false);
                        }}
                      />
                    )}
                  </CP.Flex>
                ) : (
                  <CP.Flex gap="8px">
                    {/* {download && <a rel="noopener noreferrer" target="_blank" href={downloadHref}><CP.Icon.DownloadIcon onClick={onClickDownload} /></a>} */}
                    {close && (
                      <CP.Icon.CloseIcon
                        onClick={() => {
                          setIsOpen(false);
                        }}
                      />
                    )}
                  </CP.Flex>
                )}
              </Header>
            )}

            <Content
              contentHeight={contentHeight}
              contentWidth={contentWidth}
              style={contentStyle}
            >
              {children}
            </Content>
            {footer && <Footer>{footer}</Footer>}
          </DialogPanel>
        </form>
      ) : (
        <DialogPanel style={style}>
          {(title || close || header) && (
            <Header>
              <CP.Flex>
                {title && <TitleText>{title} </TitleText>}
                {header && header}
              </CP.Flex>
              {modalType === ModalType.View ? (
                <CP.Flex gap="8px">
                  {edit && <CP.Icon.EditIcon onClick={onClickEdit} />}
                  {download && (
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href={downloadHref}
                    >
                      <CP.Icon.DownloadIcon />
                    </a>
                  )}
                  {close && (
                    <CP.Icon.CloseIcon
                      onClick={() => {
                        setIsOpen(false);
                      }}
                    />
                  )}
                </CP.Flex>
              ) : (
                <CP.Flex gap="8px">
                  {/* {download && <a rel="noopener noreferrer" target="_blank" href={downloadHref}><CP.Icon.DownloadIcon onClick={onClickDownload} /></a>} */}
                  {close && (
                    <CP.Icon.CloseIcon
                      onClick={() => {
                        setIsOpen(false);
                      }}
                    />
                  )}
                </CP.Flex>
              )}
            </Header>
          )}

          <Content
            contentHeight={contentHeight}
            contentWidth={contentWidth}
            style={contentStyle}
          >
            {children}
          </Content>
          {footer && <Footer>{footer}</Footer>}
        </DialogPanel>
      )}
    </DialogWrapper>
  );
}

const DialogWrapper = styled(Dialog)`
  position: fixed;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DialogPanel = styled(Dialog.Panel)`
  position: relative;
  min-width: 320px;
  max-width: 900px;
  min-height: 240px;
  max-height: 840px;
  background-color: #fff;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
`;

const Header = styled.div`
  padding: 17px 24px;
  border-bottom: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-shrink: 0;
`;

const TitleText = styled(S.StyledText)`
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
`;

const Content = styled.div<{
  contentHeight?: string;
  contentWidth?: string;
}>`
  max-height: 100%;
  flex-grow: 1;
  overflow: auto;
  padding: 16px;
  height: ${({ contentHeight }) => contentHeight || "auto"};
  width: ${({ contentWidth }) => contentWidth || "auto"};
`;

const Footer = styled.div`
  padding: 17px 24px;
  border-top: 1px solid #ccc;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  flex-shrink: 0;
  gap: 8px;
`;
