import { ButtonGroup as MButtonGroup, Button } from "@mui/material";

interface ButtonGroupProps {
  list: any[];
  activeValue: string;
  onClick: (value: string) => void;
}
const ButtonGroup = ({ list, activeValue, onClick }: ButtonGroupProps) => {
  return (
    <MButtonGroup
      variant="outlined"
      color="primary"
      aria-label="Basic button group"
    >
      {list.map((item) => {
        return (
          <Button
            variant={activeValue === item.value ? "contained" : "outlined"}
            onClick={() => onClick(item.value)}
            sx={{
              backgroundColor:
                activeValue === item.value ? "#6c62d1" : "transparent",
              height: 32,
              borderColor: "#6c62d1",
              color: activeValue === item.value ? "#fff" : "#6c62d1"
            }}
          >
            {item.label}
          </Button>
        );
      })}
      {/* <Button>One</Button>
      <Button>Two</Button>
      <Button>Three</Button> */}
    </MButtonGroup>
  );
};

export default ButtonGroup;
