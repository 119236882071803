import { atom } from "recoil";
// 사이드바 오픈 여부
export const sidebarState = atom({
  key: `sidebarState_${new Date().getTime()}`,
  default: false
  // default: false,
});

//로딩바
export const loadingState = atom({
  key: `loading_${new Date().getTime()}`,
  default: false
  // default: false,
});
//로딩바
export const tootipState = atom({
  key: `tootip_${new Date().getTime()}`,
  default: false
  // default: false,
});
//Global Scroll Postion
export const globalScrollPostion = atom({
  key: `globalScrollPostion`,
  default: 0
});
