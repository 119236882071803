import {
  FilePdfTwoTone,
  FileUnknownTwoTone,
  FileZipTwoTone
} from "@ant-design/icons";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import { IconButton } from "@mui/material";
import * as S from "core/components/Styled";
import CP from "core/components/common";
import Image from "next/image";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Button from "../common/Button";
import Flex from "../common/Flex";
import Modal, { ModalProps } from "../common/Modal";
import { UploadFileType } from "../common/Upload";
import { ArrowIcon } from "../common/icons";
interface CustomFileViewerProps extends ModalProps {
  files: UploadFileType[];
  path: string;
  startImageIndex?: number;
  isPaging?: boolean;
}

//pdf 미리보기 시 툴바 제거 (딱 뷰어만 되도록)
const NoPDFToolbar = "#toolbar=0&navpanes=0&scrollbar=0";

export default function CustomFileViewer(props: CustomFileViewerProps) {
  const {
    files,
    path,
    startImageIndex = 0,
    isPaging = true,
    ...modalProps
  } = props;
  const [fileList, setFileList] = useState<UploadFileType[]>([]);
  const [imageZoom, setImageZoom] = useState(1);
  const [currentImageIndex, setCurrentImageIndex] =
    useState<number>(startImageIndex);

  useEffect(() => {
    setFileList([...files]);
  }, [files]);

  useEffect(() => {
    if (!props.isOpen) return;

    if (startImageIndex !== currentImageIndex)
      setCurrentImageIndex(startImageIndex);
  }, [props.isOpen]);

  const [href, setHref] = useState(undefined);

  const refLink = useRef<HTMLAnchorElement>();

  const enablePrev = currentImageIndex !== 0;
  const enableNext = currentImageIndex !== fileList.length - 1;

  const onClickPrev = () => {
    if (enablePrev) setCurrentImageIndex(currentImageIndex - 1);
  };

  const onClickNext = () => {
    if (enableNext) setCurrentImageIndex(currentImageIndex + 1);
  };

  useEffect(() => {
    return () => {
      setCurrentImageIndex(0);
    };
  }, []);

  useEffect(() => {
    if (!fileList) return setHref(undefined);
    else if (fileList.length <= 0) return setHref(undefined);
    else if (!fileList[currentImageIndex]) return setHref(undefined);

    if (isImage()) {
      setHref(
        fileList[currentImageIndex].data
          ? fileList[currentImageIndex].data
          : path + fileList[currentImageIndex].name
      );
    } else {
      setHref(path + fileList[currentImageIndex].name);
    }
  }, [currentImageIndex, fileList]);

  const isImage = () => {
    const file = fileList[currentImageIndex];
    let _type = file?.type.toLowerCase();

    if (!_type) {
      let url = file?.data ? file.data : file?.name;

      if (url) _type = url.substring(url.lastIndexOf(".") + 1, url?.length);
      else return false;
    } else if (_type === "image") return true;

    return _type === "jpg" || _type === "png" || _type === "jpeg"
      ? true
      : false;
  };

  if (files.length !== fileList.length) return null;

  const getImageName = () => {
    const split = fileList[currentImageIndex].name.split("/");
    return split[split.length - 1];
  };

  const wheelHandler = (e: any) => {
    setImageZoom((ratio) => (ratio >= 0.2 ? ratio + 0.001 * e.deltaY : 0.2));
  };

  const getFileUrl = (): string => {
    let fileUrl = fileList[currentImageIndex].data
      ? fileList[currentImageIndex].data.replace("http:", "https:") +
        NoPDFToolbar
      : fileList[currentImageIndex].name.replace("http:", "https:") +
        NoPDFToolbar;

    fileUrl = fileUrl.replace("/uploads/uploads", "/uploads");
    if (fileUrl.indexOf("https://") === -1) return path + fileUrl;
    return fileUrl;
  };

  const newWindowOpen = (url: string) => {
    window.open(
      url,
      "title",
      `width=${window.screen.width}, height=${window.screen.height}, fullscreen=yes`
    );
  };

  const IconZoomButton = (url: string) => {
    return (
      <div
        style={{
          position: "absolute",
          top: 8,
          right: 8,
          backgroundColor: "transparent",
          width: 35,
          height: 35,
          justifyContent: "center",
          alignItems: "center",
          display: "flex"
        }}
      >
        <IconButton onClick={() => newWindowOpen(url)}>
          <ZoomOutMapIcon fontSize="large" />
        </IconButton>
      </div>
    );
  };

  const getExcelUrl = (url: string): string => {
    return "https://view.officeapps.live.com/op/embed.aspx?src=" + url;
  };

  const getPath = () => {
    let url = fileList[currentImageIndex].data;
    if (!url) url = path + fileList[currentImageIndex].name;

    url = url.replace("/uploads/uploads", "/uploads");
    return url;
  };

  if (files.length <= 0) return null;
  return (
    <Modal
      {...modalProps}
      downloadHref={href}
      contentStyle={{ borderRadius: " 0px  0px 16px 16px", padding: "0px" }}
    >
      {isImage() ? (
        <ImageWrapper
          zoom={imageZoom}
          onDoubleClick={() => {
            newWindowOpen(getPath());
          }}
        >
          {IconZoomButton(getPath())}
          <CustomImage
            zoom={imageZoom}
            src={getPath()}
            width={672}
            height={480}
            alt="이미지"
          />
        </ImageWrapper>
      ) : (
        <div
          style={{
            width: 672,
            height: 480,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          {fileList && fileList[currentImageIndex].data ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%"
              }}
            >
              <CP.Flex
                direction="column"
                justify="center"
                align="center"
                style={{ cursor: "pointer", width: "100%", height: "100%" }}
              >
                <a
                  ref={refLink}
                  rel="noopener noreferrer "
                  target="_blank"
                  style={{
                    textAlign: "center",
                    overflow: "hidden",
                    wordBreak: "break-all",
                    padding: 16,
                    width: "100%",
                    height: "100%"
                  }}
                >
                  {fileList[currentImageIndex].type === "pdf" ? (
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        position: "relative"
                      }}
                    >
                      {IconZoomButton(
                        fileList[currentImageIndex].data
                          ? fileList[currentImageIndex].data.replace(
                              "http:",
                              "https:"
                            ) + NoPDFToolbar
                          : fileList[currentImageIndex].name.replace(
                              "http:",
                              "https:"
                            ) + NoPDFToolbar
                      )}
                      <iframe
                        src={
                          fileList[currentImageIndex].data
                            ? fileList[currentImageIndex].data.replace(
                                "http:",
                                "https:"
                              ) + NoPDFToolbar
                            : fileList[currentImageIndex].name.replace(
                                "http:",
                                "https:"
                              ) + NoPDFToolbar
                        }
                        width="100%"
                        height="100%"
                      ></iframe>
                    </div>
                  ) : fileList[currentImageIndex].type === "zip" ? (
                    <FileZipTwoTone style={{ fontSize: 36 }} />
                  ) : fileList[currentImageIndex].type === "ppt" ? (
                    <FilePdfTwoTone style={{ fontSize: 36 }} />
                  ) : fileList[currentImageIndex].type === "xlsx" ? (
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        position: "relative"
                      }}
                    >
                      {IconZoomButton(
                        getExcelUrl(
                          fileList[currentImageIndex].data
                            ? fileList[currentImageIndex].data.replace(
                                "http:",
                                "https:"
                              ) + NoPDFToolbar
                            : fileList[currentImageIndex].name.replace(
                                "http:",
                                "https:"
                              ) + NoPDFToolbar
                        )
                      )}
                      <iframe
                        src={getExcelUrl(
                          fileList[currentImageIndex].data
                            ? fileList[currentImageIndex].data.replace(
                                "http:",
                                "https:"
                              ) + NoPDFToolbar
                            : fileList[currentImageIndex].name.replace(
                                "http:",
                                "https:"
                              ) + NoPDFToolbar
                        )}
                        width="100%"
                        height="100%"
                      ></iframe>
                    </div>
                  ) : (
                    <FileUnknownTwoTone style={{ fontSize: 36 }} />
                  )}
                </a>
              </CP.Flex>

              <a
                ref={refLink}
                rel="noopener noreferrer"
                href={path + fileList[currentImageIndex].name}
                target="_blank"
                style={{
                  textAlign: "center",
                  overflow: "hidden",
                  wordBreak: "break-all",
                  padding: 16
                }}
              >
                {getImageName()}
              </a>
            </div>
          ) : (
            <CP.Flex
              style={{
                flexDirection: "column",
                padding: 16,
                width: "100%",
                height: "100%",
                justifyContent: "center",
                alignItems: "center"
              }}
              gap={"32px"}
            >
              <>
                {fileList[currentImageIndex].type === "pdf" ? (
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      position: "relative"
                    }}
                  >
                    {IconZoomButton(getFileUrl())}
                    <iframe
                      src={getFileUrl()}
                      width="100%"
                      height="100%"
                    ></iframe>
                  </div>
                ) : fileList[currentImageIndex].type === "zip" ? (
                  <FileZipTwoTone style={{ fontSize: 32 }} />
                ) : fileList[currentImageIndex].type === "ppt" ? (
                  <FilePdfTwoTone style={{ fontSize: 32 }} />
                ) : fileList[currentImageIndex].type === "xlsx" ? (
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      position: "relative"
                    }}
                  >
                    {IconZoomButton(getExcelUrl(getFileUrl()))}
                    <iframe
                      src={getExcelUrl(getFileUrl())}
                      width="100%"
                      height="100%"
                    ></iframe>
                  </div>
                ) : (
                  <FileUnknownTwoTone style={{ fontSize: 32 }} />
                )}
              </>

              <S.StyledText
                style={{
                  textAlign: "center",
                  overflow: "hidden",
                  wordBreak: "break-all"
                }}
              >
                {getImageName()}
              </S.StyledText>
            </CP.Flex>
          )}
        </div>
      )}

      {isPaging && (
        <Flex
          align="center"
          justify="center"
          gap="24px"
          margin="24px 0 0 0"
          style={{ paddingBottom: "12px" }}
        >
          <PrevButton onClick={onClickPrev} disabled={!enablePrev}>
            <ArrowIcon />
          </PrevButton>
          <Index>
            {currentImageIndex + 1}/{fileList.length}
          </Index>
          <NextButton onClick={onClickNext} disabled={!enableNext}>
            <ArrowIcon />
          </NextButton>
        </Flex>
      )}
    </Modal>
  );
}

const PrevButton = styled(Button)`
  width: 24px;
  height: 24px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  &:disabled {
    cursor: not-allowed;
  }

  & > svg {
    transform: rotate(180deg);
  }

  & > svg > path {
    fill: none;
  }
`;
const NextButton = styled(Button)`
  width: 24px;
  height: 24px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  & > svg > path {
    fill: none;
  }
  &:disabled {
    cursor: not-allowed;
  }
`;
const Index = styled.div`
  font-size: 13px;
`;

const CustomImage = styled(Image)<{ zoom: number }>`
  min-width: 672px;
  min-height: 480px;
  object-fit: contain;
`;

const ImageWrapper = styled.div<{ zoom: number }>`
  position: relative;
  top: 0;
  left: 0;
  // width : ${({ zoom }) => 200 / zoom}%;
  width: 100%;
  height: ${({ zoom }) => 200 / zoom}%;
  transform: scale(${({ zoom }) => zoom});
  transform-origin: left top;
  padding: 16px;
`;
