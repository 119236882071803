import Header from "core/components/layout/Header";
import Image from "next/image";
import Link from "next/link";
import styled from "styled-components";
//로그인 전역상태
import Config from "@/env/Config";
import * as apiObject from "@/utils/api";
import CP from "core/components/common";
import { loginUserState, userInbfoTypes } from "core/stores/userStore";
import * as Cookies from "core/utils/cookies";
import functions from "core/utils/functions";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";

export const MENUS = [
  {
    src: "/images/brand/adpia-horizontal-off.svg",
    hoverImage: "/images/brand/adpia-horizontal-on.svg",
    alt: "성원애드피아",
    href: Config.publicAdpiaBaseUrl,
    code: "1",
    isToken: true
  },
  {
    src: "/images/brand/adpiamall-horizontal-off.svg",
    hoverImage: "/images/brand/adpiamall-horizontal-on.svg",
    alt: "애드피아몰",
    href: Config.publicAdpiaMallBaseUrl + "/auth",
    code: "2",
    isToken: true
  },
  {
    src: "/images/brand/foodmall-horizontal-off.svg",
    hoverImage: "/images/brand/foodmall-horizontal-on.svg",
    alt: "성원푸드몰",
    href: Config.publicStayrakBaseUrl + "/auth", //+ "/auth",
    code: "3",
    isToken: true
  }
] as const;

const Home = () => {
  const router = useRouter();
  // 로그인 정보
  const [loginUserInfo, setLoginUserInfo] = useRecoilState(loginUserState);
  const [updatePwModal, setUpdatePwModal] = useState(false);

  const [updatePw, setUpdatePw] = useState({
    new_password: "",
    re_new_password: "",
    staff_pw: ""
  });

  useEffect(() => {
    if (!updatePwModal)
      setUpdatePw({ new_password: "", re_new_password: "", staff_pw: "" });
  }, [updatePwModal]);

  useEffect(() => {
    if (functions.isEmpty(loginUserInfo?.staff_id)) {
      router.replace("/login");
    }
    const token = Cookies.getCookie("Authentication");
    if (!token) router.replace("/login");
  }, []);

  const onUpdatePwSubmit = async () => {
    if (updatePw.new_password !== updatePw.re_new_password)
      return CP.alert("암호를 다시 확인해 주세요", {});

    const reg_ex = /[`#&\'"/=<>\{\}\[\]\\\/ ]/gim;
    if (RegExp(reg_ex).test(updatePw.new_password))
      return CP.alert("암호를 다시 확인해 주세요.", {});

    const { re_new_password, ...params } = updatePw;
    // const res = await EmployeeService.updateChangePassword({
    //   ...params,
    //   staff_id: ""
    // });
    const res = await apiObject.post(
      "/api/member/member.php?action=change_password",
      {
        staff_id: "",
        staff_pw: updatePw.staff_pw,
        new_password: updatePw?.re_new_password
      }
    );

    if (res?.state === "true") {
      CP.alert("암호를 변경하였습니다.", {});
      setUpdatePwModal(false);
      // window.location.href = '/login';
      return;
    }

    CP.alert("오류가 발생하였습니다.", {});
  };

  return (
    <>
      <Header
        uploadUrl={Config.publicRealBaseUrl}
        loginUrl={Config.publicAuthBaseUrl + "/login"}
        logoutUrl={Config.publicAuthBaseUrl + "/logout"}
        onUpdate={() => setUpdatePwModal(true)}
      />
      <Main>
        <Image src="/images/bi.png" alt="logo" width={254} height={52} />
        <Title>
          <span>{loginUserInfo?.staff_name || "관리자"}님, </span>
          성원그룹웨어에 오신걸 환영합니다.
        </Title>
        <SubTitle2>오늘도 화이팅하세요!</SubTitle2>
        <Menus>
          {MENUS.map((menu) => (
            <MenuItem
              key={menu.alt}
              menu={menu}
              loginUserInfo={loginUserInfo}
            />
          ))}
        </Menus>
      </Main>

      <CP.Modal
        style={{ width: 400 }}
        isOpen={updatePwModal}
        setIsOpen={() => setUpdatePwModal(false)}
        title={"비밀번호 변경"}
        close={true}
        footer={
          <CP.Flex gap={"8px"}>
            <CP.Button
              width={103}
              height={40}
              variant="outline"
              onClick={() => setUpdatePwModal(false)}
            >
              취소
            </CP.Button>
            <CP.Button width={103} height={40} onClick={onUpdatePwSubmit}>
              변경
            </CP.Button>
          </CP.Flex>
        }
      >
        <CP.Flex direction="column" gap={"16px"}>
          <CP.Flex gap={"8px"} width="100%">
            <SubTitle>기존 비밀번호</SubTitle>
            <CP.Input
              type="password"
              value={updatePw.staff_pw}
              onChange={(e) =>
                setUpdatePw({ ...updatePw, staff_pw: e?.target.value })
              }
            ></CP.Input>
          </CP.Flex>

          <CP.Flex gap={"8px"} width="100%">
            <SubTitle>비밀번호</SubTitle>
            <CP.Input
              type="password"
              value={updatePw.new_password}
              onChange={(e) =>
                setUpdatePw({ ...updatePw, new_password: e?.target.value })
              }
            ></CP.Input>
          </CP.Flex>
          <CP.Flex gap={"8px"}>
            <SubTitle>비밀번호 변경</SubTitle>
            <CP.Input
              type="password"
              value={updatePw.re_new_password}
              onChange={(e) =>
                setUpdatePw({ ...updatePw, re_new_password: e?.target.value })
              }
            ></CP.Input>
          </CP.Flex>
        </CP.Flex>
      </CP.Modal>
    </>
  );
};

function MenuItem({
  menu,
  loginUserInfo
}: {
  menu: (typeof MENUS)[number];
  loginUserInfo: userInbfoTypes;
}) {
  if (loginUserInfo?.erp?.includes(menu.code)) {
    const token = Cookies.getCookie("Authentication");

    let href = menu.href + "?token=" + token;
    // if (menu.alt === "애드피아몰" || menu.alt === "성원푸드몰") {
    //   href = href + "&rurl=/system/menu/auth";
    // }

    return (
      <Menu key={menu.alt}>
        <Link href={href} target={menu.code != "1" ? "_blank" : "_self"}>
          <Image src={menu.src} alt={menu.alt} width={240} height={111} />
        </Link>
      </Menu>
    );
  } else {
    return (
      <Menu key={menu.alt}>
        <Image
          src={menu.src}
          alt={menu.alt}
          width={240}
          height={111}
          style={{ opacity: 0.2 }}
        />
      </Menu>
    );
  }
}

const Main = styled.main`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${({ theme }) => theme.headerHeight + 96}px;
  background-color: #f9f9f9;
`;

const Title = styled.h1`
  letter-spacing: -1.8px;
  color: #3d3d3d;
  font-size: 30px;
  & span {
    color: #5046b9;
  }
  margin-top: 48px;
`;
const SubTitle2 = styled.h2`
  letter-spacing: -1.8px;
  color: #3d3d3d;
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 128px;
`;

const Menus = styled.ul`
  display: flex;
  gap: 46px;
  flex-direction: row;
  @media (min-width: 10px) and (max-width: 1024px) {
    flex-direction: column;
  }
`;
const Menu = styled.li`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px;
  gap: 8px;
  width: 304px;
  height: 175px;
  background: #f9f9f9;
  border: 2px solid #c2cee2;
  border-radius: 8px;
  transition: all 0.3s ease-in-out;

  &:hover {
    width: 304px;
    height: 175px;
    background: #ffffff;
    box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    border: none;
    transition: all 0.3s ease-in-out;
  }
`;

const SubTitle = styled.h2`
  font-size: 14px;
  font-weight: 700;
  width: 30%;
  color: #555555;
  line-height: 25px;
  &:hover {
    cursor: pointer;
  }
`;

export default Home;
