import {
  CompanyInfo,
  OrgInfo,
  ResCodeInfo,
  StaffInfo
} from "core/models/Common";
import { ResStaffTree } from "core/models/hr/employee/Employee";
import { ResMenuGroupAuth } from "core/models/system/menu/MenuAuth";
import { ResMenu } from "core/models/system/menu/MenuList";
import { localStorageEffect } from "core/utils/localStorage";
import { atom } from "recoil";
//2. sessionStorage에 저장하고 싶은 경우
//Next.js를 쓴다면 sessionStorage는 아래와 같이 따로 설정 필요
/* const sessionStorage =
      typeof window !== 'undefined' ? window.sessionStorage : undefined
const { persistAtom } = recoilPersist({
  key: '내맘대로 정하는 키 이름',
  storage: sessionStorage,
}); */

export interface CodeProps {
  // departments :any[];
  code: ResCodeInfo[];
  company: CompanyInfo[];
  staff: StaffInfo[];
  org: OrgInfo[];
  codeAll: ResCodeInfo[];
  orgAll: OrgInfo[];
  staffAll: StaffInfo[];
}

export const codeState = atom<CodeProps>({
  key: `codeInfo`,
  default: {
    code: [],
    company: [],
    org: [],
    staff: [],
    codeAll: [],
    orgAll: [],
    staffAll: []
  },
  effects: [localStorageEffect("codeState")]
});

export interface MenuProps {
  menus: ResMenu[];
  menuListAll: ResMenu[];
  role: ResMenuGroupAuth;
}

export const menuState = atom<MenuProps>({
  key: `menu`,
  default: {
    menus: [],
    menuListAll: [],
    role: undefined
  },
  effects: [localStorageEffect("menuState")]
});

export const departmentState = atom<{ department: ResStaffTree[] }>({
  key: `departmentInfo`,
  default: {
    department: []
  },
  effects: [localStorageEffect("departmentInfo")]
});
