import { useCalendar } from "@h6s/calendar";
import classnames from "classnames/bind";
import * as S from "core/components/Styled";
import { format } from "date-fns";
import { ko } from "date-fns/locale";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import CP from ".";
import styles from "./DatePicker.module.scss";
import { ArrowIcon } from "./icons";

const cx = classnames.bind(styles);

interface DateSelectProps {
  selectedDate?: string;
  showMonthOnly?: boolean;
  style?: any;
  customStyle?: any;
  placeholder?: string;
  disabled?: boolean;
  error?: boolean;
  type?: "day" | "month" | "year";
  handleDate: (date: string) => void;
  content?: any;
  yearIsChange?: boolean;
  maxDate?: string;
  minDate?: string;
  selectedList?: string[];
}

export default function DatePicker({
  disabled,
  type = "day",
  selectedDate,
  handleDate,
  maxDate,
  minDate,
  selectedList
}: DateSelectProps) {
  const { headers, body, cursorDate, navigation } = useCalendar();

  const [viewType, setViewType] = useState<"year" | "month" | "day">(type);
  const [selcYear, setSelcYear] = useState<string>();
  const [selcMonth, setSelcMonth] = useState<string>();
  const [limit, setLimit] = useState({
    minY: "",
    minM: "",
    minD: "",
    maxY: "",
    maxM: "",
    maxD: ""
  });

  const [open, setOpen] = useState(false);

  // const [dateOpen, setDateOpen] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    let _minY = "";
    let _minM = "";
    let _maxY = "";
    let _maxM = "";
    let _minD = "";
    let _maxD = "";

    if (minDate) {
      let min = moment(minDate, "YYYY-MM-DD");

      if (
        min.format("YYYY-MM-DD") === "Invalid date" &&
        minDate.indexOf("31") !== -1
      ) {
        const _mon = minDate.replace("-31", "");
        const _last = moment(_mon, "YYYY-MM").daysInMonth();
        min = moment(`${_mon}-${_last}`);
      }

      if (!min) return;

      _minY = min.format("YYYY");
      _minM = min.format("MM");
      _minD = min.format("DD");
    }

    if (maxDate) {
      let max = moment(maxDate, "YYYY-MM-DD");
      if (
        max.format("YYYY-MM-DD") === "Invalid date" &&
        maxDate.indexOf("31") !== -1
      ) {
        const _mon = maxDate.replace("-31", "");
        const _last = moment(_mon, "YYYY-MM").daysInMonth();
        max = moment(`${_mon}-${_last}`);
      }

      if (!max) return;

      _maxY = max.format("YYYY");
      _maxM = max.format("MM");
      _maxD = max.format("DD");
    }
    setLimit({
      minY: _minY,
      minM: _minM,
      maxY: _maxY,
      maxM: _maxM,
      minD: _minD,
      maxD: _maxD
    });
  }, [minDate, maxDate]);
  useEffect(() => {
    let checkFormat = "YYYY-MM-DD";

    if (type === "month") checkFormat = "YYYY-MM";
    else if (type === "year") checkFormat = "YYYY";

    if (
      selectedDate &&
      moment(selectedDate, checkFormat).format(checkFormat) === "Invalid date"
    ) {
      navigation.setToday();
      setSelcYear(moment().format("YYYY"));
      setSelcMonth(moment().format("MM"));
      return;
    }
    navigation.setDate(
      new Date(moment(selectedDate, checkFormat).format("YYYY-MM-DD"))
    );
    setSelcYear(
      selectedDate ? formatYear(selectedDate) : moment().format("YYYY")
    );
    setSelcMonth(
      selectedDate ? formatMonth(selectedDate) : moment().format("MM")
    );
  }, [selectedDate]);

  const formatYear = (item: string) =>
    moment(item, type === "day" ? "YYYY-MM-DD" : "YYYY-MM").format("YYYY");
  const formatMonth = (item: string) =>
    moment(item, type === "day" ? "YYYY-MM-DD" : "YYYY-MM").format("MM");

  const handlePrevYear = () => {
    if (viewType === "year")
      setSelcYear(moment(selcYear, "YYYY").add(-10, "y").format("YYYY"));
    else {
      setSelcYear(moment(selcYear, "YYYY").add(-1, "y").format("YYYY"));
      if (selectedDate) {
        navigation.setDate(
          new Date(
            moment(selectedDate, "YYYY-MM-DD").add("y", -1).format("YYYY-MM-DD")
          )
        );
      } else {
        navigation.setDate(
          new Date(Number(selcYear) - 1 + "-" + selcMonth + "-01")
        );
      }
    }
  };
  const handleNextYear = () => {
    if (viewType === "year") {
      setSelcYear(moment(selcYear, "YYYY").add(10, "y").format("YYYY"));
    } else {
      if (selectedDate) {
        navigation.setDate(
          new Date(
            moment(selectedDate, "YYYY-MM-DD").add("y", 1).format("YYYY-MM-DD")
          )
        );
      } else {
        navigation.setDate(
          new Date(Number(selcYear) + 1 + "-" + selcMonth + "-01")
        );
      }
      setSelcYear(moment(selcYear, "YYYY").add(1, "y").format("YYYY"));
    }
  };
  const handlePrevMonth = () => {
    setSelcMonth(
      moment(selcYear + selcMonth, "YYYYMM")
        .add(-1, "M")
        .format("MM")
    );
    setSelcYear(
      moment(selcYear + selcMonth, "YYYYMM")
        .add(-1, "M")
        .format("YYYY")
    );
    navigation.toPrev();
  };
  const handleNextMonth = () => {
    setSelcMonth(
      moment(selcYear + selcMonth, "YYYYMM")
        .add(1, "M")
        .format("MM")
    );

    setSelcYear(
      moment(selcYear + selcMonth, "YYYYMM")
        .add(1, "M")
        .format("YYYY")
    );
    navigation.toNext();
  };

  const onChangeMonth = (month: number) => {
    const mon = month < 10 ? "0" + month : month + "";

    if (type === "month") {
      handleDate(selcYear + "-" + mon);
      setOpen(false);
    } else {
      navigation.setDate(new Date(selcYear + "-" + mon + "-01"));
      setSelcMonth(mon);
      setViewType("day");
    }
  };

  const headerItem = () => {
    const y = selcYear?.substring(0, selcYear.length - 1);

    if (viewType === "day") {
      return (
        <TopWrapper style={{ display: "flex", flex: "1" }}>
          <TopItem style={{ display: "flex" }}>
            <PrevYearIcon
              isDisabled={disabled}
              isDisplay={limit.minY === selcYear ? "none" : "block"}
              width={24}
              height={24}
              onClick={handlePrevYear}
            />
            <YearAndMonth>
              <button
                onClick={() =>
                  limit.minY === limit.maxY ? {} : setViewType("year")
                }
              >
                <S.StyledText weight="700"> {`${selcYear}년`}</S.StyledText>
              </button>
            </YearAndMonth>
            <NextYearIcon
              isDisabled={disabled}
              isDisplay={limit.maxY === selcYear ? "none" : "block"}
              width={24}
              height={24}
              onClick={handleNextYear}
            />
          </TopItem>
          <TopItem style={{ display: "flex" }}>
            <PrevMonthIcon
              isDisabled={disabled}
              isDisplay={
                limit.minY + limit.minM === selcYear + selcMonth
                  ? "none"
                  : "block"
              }
              onClick={handlePrevMonth}
              width={24}
              height={24}
            />
            <YearAndMonth>
              <button
                onClick={() =>
                  limit.minM === limit.maxM ? {} : setViewType("month")
                }
              >
                <S.StyledText weight="700"> {`${selcMonth}월`}</S.StyledText>
              </button>
            </YearAndMonth>
            <NextMonthIcon
              isDisabled={disabled}
              isDisplay={
                limit.maxY + limit.maxM === selcYear + selcMonth
                  ? "none"
                  : "block"
              }
              onClick={handleNextMonth}
              width={24}
              height={24}
            />
          </TopItem>
        </TopWrapper>
      );
    } else if (viewType === "month") {
      return (
        <TopWrapper>
          <TopItem>
            <PrevYearIcon
              isDisabled={disabled}
              isDisplay={limit.minY === selcYear ? "none" : "block"}
              width={24}
              height={24}
              onClick={handlePrevYear}
            />
            <button onClick={() => setViewType("year")}>
              <YearAndMonth>
                {" "}
                <S.StyledText weight="700">
                  {" "}
                  {`${selcYear}년`}
                </S.StyledText>{" "}
              </YearAndMonth>
            </button>
            <NextYearIcon
              isDisabled={disabled}
              isDisplay={limit.maxY === selcYear ? "none" : "block"}
              width={24}
              height={24}
              onClick={handleNextYear}
            />
          </TopItem>
        </TopWrapper>
      );
    } else {
      return (
        <TopWrapper>
          <TopItem>
            <PrevYearIcon width={24} height={24} onClick={handlePrevYear} />
            <button onClick={() => setViewType("month")}>
              <YearAndMonth>
                <S.StyledText weight="700"> {`${`${y}0~${y}9`}`}</S.StyledText>
              </YearAndMonth>
            </button>
            <NextYearIcon width={24} height={24} onClick={handleNextYear} />
          </TopItem>
        </TopWrapper>
      );
    }
  };
  const dayItem = () => {
    return (
      <>
        <StyleWeekRow>
          {headers.weekDays.map(({ key, value }) => {
            return (
              <DayText key={key}>{format(value, "E", { locale: ko })}</DayText>
            );
          })}
        </StyleWeekRow>

        {body.value.map((week) => {
          const { key, value: days } = week;

          return (
            <StyleWeekRow key={key}>
              {days.map((day) => {
                const {
                  key,
                  date,
                  isCurrentDate,
                  isCurrentMonth,
                  isWeekend,
                  value
                } = day;

                const disabledMin = minDate
                  ? moment(limit.minY + limit.minM + limit.minD, "YYYYMD")
                  : undefined;
                const disabledMax = maxDate
                  ? moment(limit.maxY + limit.maxM + limit.maxD, "YYYYMD")
                  : undefined;
                const nowDate = moment(selcYear + selcMonth + date, "YYYYMMD");
                const disabledDate = !(
                  disabledMin <= nowDate && disabledMax >= nowDate
                )
                  ? true
                  : false;

                // const isSelected = !selectedDate
                //   ? false
                //   : moment(selectedDate, 'YYYY-MM-DD').format('yyyy.MM.dd') ===
                //     format(value.getTime(), 'yyyy.MM.dd');
                const isSelected = !selectedList
                  ? false
                  : selectedList?.filter(
                        (item) => item === format(value.getTime(), "yyyy-MM-dd")
                      )?.length > 0
                    ? true
                    : false;

                return (
                  <DayText
                    key={key}
                    as="span"
                    className={cx(styles.day, {
                      disabledDate,
                      isCurrentMonth,
                      isSelected,
                      isCurrentDate,
                      isWeekend
                    })}
                    onClick={(e) => {
                      if (disabledDate) return;
                      if (!isCurrentMonth) return;

                      const val = `${selcYear}-${selcMonth}-${
                        date + "".length < 10 ? "0" + date : "" + date
                      }`;
                      handleDate(val);
                      setOpen(false);
                    }}
                  >
                    {date}
                  </DayText>
                );
              })}
            </StyleWeekRow>
          );
        })}
      </>
    );
  };

  const monthItem = () => {
    return (
      <CP.Flex direction="column" gap={"10px"}>
        {[...Array(4)].map((item, idx) => (
          <CP.Flex gap={"6px"}>
            {[...Array(3)].map((item, index) => {
              const month = index + 1 + idx * 3;

              // const disabledMonth = Number(limit.minM) <= month && Number(limit.maxM) >= month ? false : true;
              const disabledMin = minDate
                ? moment(limit.minY + limit.minM, "YYYYM")
                : "";
              const disabledMax = maxDate
                ? moment(limit.maxY + limit.maxM, "YYYYM")
                : "";
              const nowMon = moment(selcYear + month, "YYYYMM");
              const disabledMonth =
                disabledMin <= nowMon && disabledMax >= nowMon ? false : true;

              return (
                <StyleWeekRow
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "center"
                  }}
                >
                  <button
                    onClick={() => {
                      if (disabledMonth) return;

                      onChangeMonth(month);
                    }}
                  >
                    <S.StyledText
                      weight={"600"}
                      style={{ opacity: disabledMonth ? "0.2" : "1" }}
                    >
                      {month + "월"}
                    </S.StyledText>
                  </button>
                </StyleWeekRow>
              );
            })}
          </CP.Flex>
        ))}
      </CP.Flex>
    );
  };
  const yearItem = () => {
    return (
      <>
        <CP.Flex direction="column" gap={"10px"}>
          {[...Array(4)].map((item, idx) => (
            <CP.Flex gap={"6px"}>
              {[...Array(3)].map((item, index) => {
                const preYear = Number(
                  selcYear.substring(0, selcYear.length - 1) + 0
                );
                const _year = preYear - 1 + (index + idx * 3) + "";

                return (
                  <StyleWeekRow
                    style={{
                      display: "flex",
                      flex: 1,
                      justifyContent: "center"
                    }}
                  >
                    <button
                      onClick={() => {
                        if (type === "year") {
                          handleDate(_year + "-" + selcMonth);
                          setOpen(false);
                          return;
                        }
                        if (idx + index === 0) {
                          setSelcYear(
                            moment(selcYear, "YYYY")
                              .add(-10, "y")
                              .format("YYYY")
                          );
                        } else if (idx === 3 && index === 2) {
                          setSelcYear(
                            moment(selcYear, "YYYY").add(10, "y").format("YYYY")
                          );
                        } else {
                          setSelcYear(_year);
                          setViewType("month");
                        }
                      }}
                    >
                      <S.StyledText
                        weight={"600"}
                        color={
                          index + idx === 0 || (index === 2 && idx === 3)
                            ? "#ccc"
                            : "#222"
                        }
                      >
                        {_year}
                      </S.StyledText>
                    </button>
                  </StyleWeekRow>
                );
              })}
            </CP.Flex>
          ))}
        </CP.Flex>
      </>
    );
  };

  return (
    <DatePickerWrapper ref={ref} disabled={disabled}>
      {headerItem()}

      <CP.Flex direction="column" justify="center" gap={"4px"}>
        {viewType === "day"
          ? dayItem()
          : viewType === "month"
            ? monthItem()
            : yearItem()}
      </CP.Flex>
    </DatePickerWrapper>
  );
}

const StyleWeekRow = styled.div`
  padding: 0px 8px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: space-between;
`;

const DayText = styled.div`
  padding: 8px 9.4px;
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 120%;
  color: ${({ theme }) => theme.colors.black222};
  &:nth-child(1) {
    color: #ff6969;
  }
  &:nth-child(7) {
    color: #0085ff;
  }
  &:hover {
    background-color: #f0eeff;
  }
`;

const TopWrapper = styled.div`
  padding: 8px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #f9f9f9;
`;

const TopItem = styled.div`
  display: flex;
  gap: 16px;
  justify-content: center;
  align-items: center;
`;

const Arrow = styled(ArrowIcon)<{ isDisplay?: string; isDisabled?: boolean }>`
  ${({ isDisplay, isDisabled }) =>
    (isDisplay === "none" || isDisabled) &&
    css`
      opacity: 0;
      pointer-events: none;
    `}
  cursor: pointer;
`;

const PrevYearIcon = styled(Arrow)`
  transform: rotate(180deg);
  &:hover > path {
    stroke: #6c62d1;
  }
`;
const NextYearIcon = styled(Arrow)`
  &:hover > path {
    stroke: #6c62d1;
  }
`;
const PrevMonthIcon = styled(Arrow)`
  transform: rotate(180deg);
  &:hover > path {
    stroke: #6c62d1;
  }
`;
const NextMonthIcon = styled(Arrow)`
  &:hover > path {
    stroke: #6c62d1;
  }
`;

const YearAndMonth = styled(S.StyledText)`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: ${({ theme }) => theme.colors.black222};
`;

const DatePickerWrapper = styled.div<{ disabled: boolean }>`
  max-width: 290px;
  min-width: 290px;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 8px 0px;

  ${({ disabled }) =>
    disabled &&
    css`
      // opacity : 0.5;
      & button {
        background: transparent;
      }
      & div,
      button,
      svg,
      span,
      a {
        cursor: default;
        pointer-events: none;
        &:hover {
          background: transparent;
        }
      }
    `}
`;

const MonthPickerWrapper = styled(DatePickerWrapper)`
  & button {
    background: transparent;
  }
`;
