import * as S from "core/components/Styled";
import { Dropdown } from "antd";
import classnames from "classnames/bind";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import CP from ".";
import styles from "./DatePicker.module.scss";
import { ArrowIcon, CalendarIcon, CloseIcon } from "./icons";
const cx = classnames.bind(styles);

interface DateSelectProps {
  onChange?: (date: string) => void;
  value?: string;
  style?: any;
  placeholder?: string;
  disabled?: boolean;
  error?: boolean;
}

export default function DatePicker({
  error,
  placeholder = "날짜선택",
  disabled,
  value = moment().format("YYYY-MM"),
  onChange,
  style
}: DateSelectProps) {
  const [yearOpen, setYearOpen] = useState(false);
  const [selcYear, setSelcYear] = useState<string>();

  const [open, setOpen] = useState(false);
  // const [dateOpen, setDateOpen] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    setSelcYear(formatYear(value));
  }, [value]);

  const formatYear = (item: string) => moment(item, "YYYY-MM").format("YYYY");

  useEffect(() => {
    const onCheckClickOutside = (e: MouseEvent) => {
      //!searchRef.current.contains(e.target as Node) 은 SearchResult태그에 이벤트가 발생하지 않았을때를 의미
      if (
        open === true &&
        ref.current &&
        !ref.current.contains(e.target as Node)
      ) {
        setOpen(false); //외부 클릭시 실행할 로직
      }
    };
    document.addEventListener("mousedown", onCheckClickOutside); //컴포넌트 렌더링 후 이벤트 등록
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", onCheckClickOutside); //컴포넌트가 제거될때 실행
    };
  }, [open, ref.current]);

  const handlePrevYear = () => {
    if (yearOpen)
      setSelcYear(moment(selcYear, "YYYY").add(-10, "y").format("YYYY"));
    else setSelcYear(moment(selcYear, "YYYY").add(-1, "y").format("YYYY"));
  };
  const handleNextYear = () => {
    if (yearOpen)
      setSelcYear(moment(selcYear, "YYYY").add(10, "y").format("YYYY"));
    else setSelcYear(moment(selcYear, "YYYY").add(1, "y").format("YYYY"));
  };
  const onChangeMonth = (month: number) => {
    const mon = month < 10 ? "0" + month : month + "";

    onChange(selcYear + "-" + mon);
    setOpen(false);
  };

  const settingYears = () => {
    const y = selcYear.substring(0, selcYear.length - 1);

    return `${y}0~${y}9`;
  };
  const monthPickerItem = () => {
    // setDateOpen(false);
    return (
      <MonthPickerWrapper ref={ref}>
        <TopWrapper>
          <TopItem>
            <PrevYearIcon width={24} height={24} onClick={handlePrevYear} />
            <button onClick={() => setYearOpen(!yearOpen)}>
              <YearAndMonth>
                {yearOpen ? settingYears() : selcYear + "년"}
              </YearAndMonth>
            </button>
            <NextYearIcon width={24} height={24} onClick={handleNextYear} />
          </TopItem>
        </TopWrapper>

        {!yearOpen ? (
          <CP.Flex direction="column" gap={"10px"}>
            {[...Array(4)].map((item, idx) => (
              <CP.Flex gap={"6px"}>
                {[...Array(3)].map((item, index) => {
                  const month = index + 1 + idx * 3;
                  return (
                    <StyleWeekRow
                      style={{
                        display: "flex",
                        flex: 1,
                        justifyContent: "center"
                      }}
                    >
                      <button onClick={() => onChangeMonth(month)}>
                        <S.StyledText weight={"600"}>
                          {month + "월"}
                        </S.StyledText>
                      </button>
                    </StyleWeekRow>
                  );
                })}
              </CP.Flex>
            ))}
          </CP.Flex>
        ) : (
          <>
            <CP.Flex direction="column" gap={"10px"}>
              {[...Array(4)].map((item, idx) => (
                <CP.Flex gap={"6px"}>
                  {[...Array(3)].map((item, index) => {
                    const preYear = Number(
                      selcYear.substring(0, selcYear.length - 1) + 0
                    );
                    const _year = preYear - 1 + (index + idx * 3) + "";

                    return (
                      <StyleWeekRow
                        style={{
                          display: "flex",
                          flex: 1,
                          justifyContent: "center"
                        }}
                      >
                        <button
                          onClick={() => {
                            if (idx + index === 0) {
                              setSelcYear(
                                moment(selcYear, "YYYY")
                                  .add(-10, "y")
                                  .format("YYYY")
                              );
                            } else if (idx === 3 && index === 2) {
                              setSelcYear(
                                moment(selcYear, "YYYY")
                                  .add(10, "y")
                                  .format("YYYY")
                              );
                            } else {
                              setSelcYear(_year);
                              setYearOpen(false);
                            }
                          }}
                        >
                          <S.StyledText
                            weight={"600"}
                            color={
                              index + idx === 0 || (index === 2 && idx === 3)
                                ? "#ccc"
                                : "#222"
                            }
                          >
                            {_year}
                          </S.StyledText>
                        </button>
                      </StyleWeekRow>
                    );
                  })}
                </CP.Flex>
              ))}
            </CP.Flex>
          </>
        )}
      </MonthPickerWrapper>
    );
  };

  return (
    <Dropdown dropdownRender={monthPickerItem} trigger={["click"]} open={open}>
      <div
        tabIndex={0}
        onClick={(e) => {
          if (disabled) return;

          setOpen(!open);
        }}
        className={cx(styles.dateButton, { open })}
        style={{
          border: error ? "1px solid #FF6969" : "1px solid #999",
          background: disabled ? "#eaeaea" : "#fff",
          ...style
        }}
      >
        <CalendarIcon stroke="#999" width={24} height={24} />
        <InputValue>
          {value ? moment(value, "YYYY-MM").format("YYYY.MM") : placeholder}
        </InputValue>
      </div>
    </Dropdown>
  );
}

const StyleWeekRow = styled.div`
  padding: 0px 8px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: space-between;
`;

const DayText = styled.div`
  padding: 8px 9.4px;
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 120%;
  color: ${({ theme }) => theme.colors.black222};
  &:nth-child(1) {
    color: #ff6969;
  }
  &:nth-child(7) {
    color: #0085ff;
  }
  &:hover {
    background-color: #f0eeff;
  }
`;
const Typography = styled.span``;

const InputValue = styled.span`
  flex: 1;
  color: #999;
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  white-space: nowrap;
`;

const CloseButton: any = styled(CloseIcon)`
  align-self: flex-end;
  margin: 8px;
  cursor: pointer;
`;

const TopWrapper = styled.div`
  padding: 8px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #f9f9f9;
`;

const TopItem = styled.div`
  display: flex;
  gap: 16px;
  justify-content: center;
  align-items: center;
`;

const Arrow = styled(ArrowIcon)`
  cursor: pointer;
`;

const PrevYearIcon = styled(Arrow)`
  transform: rotate(180deg);
  &:hover > path {
    stroke: #6c62d1;
  }
`;
const NextYearIcon = styled(Arrow)`
  &:hover > path {
    stroke: #6c62d1;
  }
`;
const PrevMonthIcon = styled(Arrow)`
  transform: rotate(180deg);
  &:hover > path {
    stroke: #6c62d1;
  }
`;
const NextMonthIcon = styled(Arrow)`
  &:hover > path {
    stroke: #6c62d1;
  }
`;

const YearAndMonth = styled(S.StyledText)`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: ${({ theme }) => theme.colors.black222};
`;

const DatePickerWrapper = styled.div`
  max-width: 290px;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 16px;
  padding: 8px 0px;
`;

const MonthPickerWrapper = styled(DatePickerWrapper)`
  & button {
    background: transparent;
  }

  & .yearRow {
    & :first-child > & div:first-child > button > h1 {
      color: #ccc;
    }
  }
`;
