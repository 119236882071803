import * as S from "core/components/Styled";
import { Input as InputComp, InputRef } from "antd";
import CP from "core/components/common";
import { ForwardedRef, forwardRef } from "react";
import styled from "styled-components";
const { Search } = InputComp;

export interface IProps {
  onChange?: (e: any) => void;
  onBlur?: (e: any) => void;
  value?: any;
  defaultValue?: string;
  type?: string; //'text' | 'search' | 'textarea';
  disabled?: boolean;
  placeholder?: string;
  rows?: number; //textarea
  maxLength?: number; //textarea
  style?: any;
  error?: boolean;
  name?: string;
  // ref?: any;
  className?: string;
  align?: "center" | "end" | "start";
  onKeyPress?: (e: any) => void;
  buttonText?: string;
  onClickButton: (e: any) => void;
}

export default forwardRef(function Input(
  {
    onChange,
    value,
    defaultValue,
    type = "text",
    disabled,
    placeholder,
    style,
    rows,
    maxLength,
    onBlur,
    error,
    name,
    className,
    align,
    onKeyPress,
    buttonText = "조회",
    onClickButton,
    ...props
  }: IProps,
  ref: ForwardedRef<InputRef>
) {
  return (
    <div style={{ flexDirection: "row", display: "flex" }}>
      <StyledInput
        ref={ref}
        {...props}
        name={name}
        className={value && "value_true"}
        style={{ textAlign: align ? align : "", ...style }}
        onChange={onChange}
        value={value}
        disabled={disabled}
        placeholder={placeholder}
        onBlur={onBlur}
        error={error}
        onKeyPress={onKeyPress}
      />
      <CP.Button
        onClick={onClickButton}
        style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
      >
        <S.StyledText color={"#fff"} lineHeight={"15px"}>
          {" "}
          {buttonText}
        </S.StyledText>
      </CP.Button>
    </div>
  );
});

const StyledInput = styled(InputComp)<{ error?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 32px;
  border: 1px solid ${({ error }) => (error ? "#FF6969" : "#999999")};
  border-radius: 4px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  padding: 8px;

  & > .ant-input-suffix > svg > path {
    stroke: #999;
  }
  &.value_true,
  &.ant-input-affix-wrapper:hover,
  &.ant-input-affix-wrapper-focused {
    & > .ant-input-suffix > svg > path {
      stroke: #222;
    }
  }
  &.ant-input-affix-wrapper:hover,
  &.ant-input-affix-wrapper-focused {
    border: 1px solid
      ${({ error, theme }) => (error ? "#FF6969" : theme.colors.primary)};
    box-shadow: none;
  }

  &.ant-input {
    color: ${({ theme }) => theme.colors.black222};
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
  }

  &:disabled {
    background: #eaeaea;
    border: 1px solid #999999;
    color: #999999;
  }

  &::placeholder {
    color: #999999;
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type="number"] {
    -moz-appearance: textfield;
  }
`;
