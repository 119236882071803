import styled from "styled-components";

export interface GridProps {
  columns?: number;
  rows?: number;
  columnGap?: number;
  rowGap?: number;
  gap?: number;
  padding?: string;
  width?: string;
  height?: string;
}

const Grid: any = styled.div<GridProps>`
  display: grid;
  grid-template-columns: repeat(${({ columns }) => columns || 1}, 1fr);
  grid-template-rows: repeat(${({ rows }) => rows || 1}, 1fr);
  grid-column-gap: ${({ columnGap }) => columnGap || 0}px;
  grid-row-gap: ${({ rowGap }) => rowGap || 0}px;
  gap: ${({ gap }) => gap || 0}px;
  padding: ${({ padding }) => padding || "0"};
  width: ${({ width }) => width || "auto"};
  height: ${({ height }) => height || "auto"};
`;

export default Grid;
