import { CircularProgress } from "@mui/material";
import React from "react";
import styled, { css } from "styled-components";

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: "active" | "outline" | "ghost" | "text"; //'disabled' |
  type?: "submit" | "button";
  //color 기본값 primary (기본색)
  color?: "primary" | "green" | "black" | "blue" | "red" | "purple" | "gray";
  //variant=active 일경우 'primary' | 'green'  | 'blue' | 'red' | 'purple' | 'gray'
  //variant=outline 일경우 'primary' | 'black'  |  'red' |  'gray'
  disabled?: boolean;
  size?: "small" | "medium" | "large" | "icon" | "list";
  fontSize?: "small" | "medium" | "large";
  width?: number;
  height?: number;
  loading?: boolean;
}

export default function Button({
  variant = "active",
  type = "button",
  loading = false,
  children,
  ...rest
}: ButtonProps) {
  switch (variant) {
    case "active":
      return (
        <ActiveButton type={type} {...rest}>
          {loading && <CircularProgress size={20} color="inherit" />}
          {children}
        </ActiveButton>
      );
    case "outline":
      return (
        <OutlineButton type={type} {...rest} color={rest.color ?? "black"}>
          {loading && <CircularProgress size={20} color="inherit" />}
          {children}
        </OutlineButton>
      );
    case "ghost":
      return (
        <GhostButton type={type} {...rest}>
          {loading && <CircularProgress size={20} color="inherit" />}
          {children}
        </GhostButton>
      );
    case "text":
      return (
        <TextButton type={type} {...rest}>
          {loading && <CircularProgress size={20} color="inherit" />}
          {children}
        </TextButton>
      );
    default:
      return (
        <ActiveButton type={type} {...rest}>
          {loading && <CircularProgress size={20} color="inherit" />}
          {children}
        </ActiveButton>
      );
  }
}

const BaseButton = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 4px;
  min-width: fit-content;
  gap: 4px;
  padding: ${({ size }) => {
    switch (size) {
      case "small":
        return "6.5px 8px";
      case "medium":
        return "8px 16px";
      case "large":
        return "12px 24px";
      case "icon":
        return "8px 16px 8px 8px";
      default:
        return "11px 16px";
    }
  }};

  padding: ${({ size }) => {
    switch (size) {
      case "icon":
        return "0px 16px 0px 8px";
      case "list":
        return "8px";
      default:
        return "11px 16px";
    }
  }};
  height: ${({ size }) => {
    switch (size) {
      case "small":
        return "40px";
      case "list":
        return "32px";
      default:
        return "32px";
    }
  }};
  font-size: ${({ fontSize }) => {
    switch (fontSize) {
      case "small":
        return "12px";

      default:
        return "13px";
    }
  }};
  color: ${({ theme }) => theme.colors.white};
  font-weight: 400;
  cursor: pointer;
  // width: ${({ width }) => width && `${width}px`};
  width: ${({ width, size }) =>
    width ? `${width}px` : size === "list" && "108px"};
  height: ${({ height }) => height && `${height}px`};

  &:focus {
    outline: 0;
  }

  ${(props) =>
    props.disabled &&
    css`
      background-color: #999 !important;
      color: #ccc !important;
      border: 1px solid #999 !important;
    `}
`;

const ActiveButton = styled(BaseButton)`
  background-color: ${({ color, theme }) => {
    switch (color) {
      case "green":
        return "#14AD1A";

      case "blue":
        return "#38ACFF";
      case "red":
        return "#FF6969";
      case "purple":
        return "#B312FF";
      case "gray":
        return "#999999";
      default:
        return theme.colors.primary;
    }
  }};

  border: ${({ color }) => color === "gray" && "#555"};

  &:hover {
    background-color: ${({ color, theme }) => {
      switch (color) {
        case "green":
          return "#108A15";
        case "blue":
          return "#2D89CC";
        case "red":
          return "#CC5454";
        case "purple":
          return "#8F0ECC";
        case "gray":
          return "#555555";
        default:
          return theme.colors.primaryHover;
      }
    }};
  }
  & > svg {
    & > path {
      fill: #fff;
    }
  }
`;

const OutlineButton = styled(BaseButton)`
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid
    ${({ color, theme }) => {
      switch (color) {
        case "black":
          return "#999";
        case "red":
          return "#FF6969";
        case "gray":
          return "#CCCCCC";
        default:
          return theme.colors.primary;
      }
    }};

  color: ${({ color, theme }) => {
    switch (color) {
      case "black":
        return "#222";
      case "red":
        return "#FF6969";
      case "gray":
        return "#999";
      default:
        return theme.colors.primary;
    }
  }};

  & > svg > path {
    fill: ${({ color, theme }) => {
      switch (color) {
        case "black":
          return "#222";
        case "red":
          return "#FF6969";
        case "gray":
          return "#999";
        default:
          return theme.colors.primary;
      }
    }};
  }

  &:hover {
    background-color: ${({ color, theme }) => {
      switch (color) {
        case "red":
          return "#FFE1E1";
        case "black":
          return "#CCCCCC";
        default:
          return "#F0EEFF";
      }
    }};

    color: ${({ color }) => color === "gray" && "#555"};
  }
`;

const GhostButton = styled(BaseButton)`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #999999;
  border: 1px solid #555555;
  border-radius: 4px;
  color: #ffffff;
`;

const TextButton = styled(BaseButton)`
  color: ${({ color, theme }) => {
    switch (color) {
      case "black":
        return "#222";
      case "red":
        return "#FF6969";
      case "gray":
        return "#999";
      case "primary":
        return theme.colors.primary;
      default:
        return theme.colors.primary;
    }
  }};

  background-color: transparent;

  &:hover {
    background-color: ${({ color, theme }) => {
      switch (color) {
        case "green":
          return "#108A15";
        case "blue":
          return "#2D89CC";
        case "red":
          return "#CC5454";
        case "purple":
          return "#8F0ECC";
        case "gray":
          return "#555555";
        case "primary":
          return "#F0EEFF";
        case "black":
          return "rgba(0,0,0, 0.1)";
        default:
          return "transparent";
      }
    }};
  }

  &:active {
    background-color: ${({ color, theme }) => {
      switch (color) {
        case "green":
          return "#108A15";
        case "blue":
          return "#2D89CC";
        case "red":
          return "#CC5454";
        case "purple":
          return "#8F0ECC";
        case "gray":
          return "#555555";
        case "primary":
          return "#F0EEFF";
        case "black":
          return "rgba(0,0,0, 0.2)";
        default:
          return "transparent";
      }
    }};
  }
`;
