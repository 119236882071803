import { useRouter } from "next/router";
import React from "react";
import styled, { css } from "styled-components";
import { ArrowIcon } from "./icons";

interface BreadCrumbProps {
  crumbs: { href?: string; name: string }[];
}

export default function BreadCrumb({ crumbs }: BreadCrumbProps) {
  const router = useRouter();
  return (
    <BreadCrumbWrapper>
      {crumbs.map((crumb, index) => (
        <React.Fragment key={crumb.name + index}>
          {/* {index === crumbs.length - 1 ? ( */}
          <StyleA
            href={crumb.href}
            onClick={() => (crumb.href ? router.push(crumb.href) : {})}
          >
            <span>{crumb.name}</span>
          </StyleA>
          {/* ) : (
            <span>{crumb.name}</span>
          )} */}

          {index !== crumbs.length - 1 && <ArrowIcon width={24} height={24} />}
        </React.Fragment>
      ))}
    </BreadCrumbWrapper>
  );
}

const BreadCrumbWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  & span {
    font-family: Pretendard;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #555555;
  }
`;

const StyleA = styled.a<{ href?: string }>`
  ${({ href }) =>
    href &&
    css`
      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    `}
`;
