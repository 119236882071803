import { Input as InputComp } from "antd";
import { SearchIcon as Icon } from "core/components/common/icons";
import Image from "next/image";
import React from "react";
import styled from "styled-components";
import * as Images from "core/utils/Images";
interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {}

interface IProps {
  onChange?: (e: any) => void;
  onBlur?: (e: any) => void;
  value?: any;
  type?: string; //'text' | 'search' | 'textarea';
  disabled?: boolean;
  placeholder?: string;
  rows?: number; //textarea
  maxLength?: number; //textarea
  style?: any;
  error?: boolean;
}

const { TextArea } = InputComp;

export default function SmallInput({
  onChange,
  value,
  type = "text",
  disabled,
  placeholder,
  style,
  rows,
  maxLength,
  onBlur,
  error,
}: IProps) {
  if (type === "textarea") {
    return (
      <StyledTextArea
        onChange={onChange}
        value={value}
        disabled={disabled}
        placeholder={placeholder}
        rows={rows}
        maxLength={maxLength}
        onBlur={onBlur}
        error={error}
        style={{ ...style, resize: "none" }}
      />
    );
  }
  return (
    <StyledInput
      className={value && "value_true"}
      style={style}
      onChange={onChange}
      value={value}
      disabled={disabled}
      placeholder={placeholder}
      suffix={
        type === "search" && (
          <Image src={Images.searchIcon} alt="search" width={15} height={15} />
        )
      }
      onBlur={onBlur}
      error={error}
    />
  );
}

const SearchIcon = styled(Icon)`
  width: 15px;
  height: 15px;
  & > path {
    stroke: #999;
  }
`;
const StyledTextArea = styled(TextArea)<{ error?: boolean }>`
  // border: 1px solid #999999;
  border: 1px solid ${({ error }) => (error ? "#FF6969" : "#999999")};
`;
const StyledInput = styled(InputComp)<{ error?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 30px;
  border: 1px solid ${({ error }) => (error ? "#FF6969" : "#999999")};
  border-radius: 4px;
  padding: 8px;

  & > .ant-input-suffix > svg > path {
    stroke: #999;
  }
  &.value_true,
  &.ant-input-affix-wrapper:hover,
  &.ant-input-affix-wrapper-focused {
    & > .ant-input-suffix > svg > path {
      stroke: #222;
    }
  }
  &.ant-input-affix-wrapper:hover,
  &.ant-input-affix-wrapper-focused {
    border: 1px solid
      ${({ error, theme }) => (error ? "#FF6969" : theme.colors.primary)};
    box-shadow: none;
  }

  &.ant-input {
    color: ${({ theme }) => theme.colors.black222};
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }

  &:disabled {
    background: #eaeaea;
    border: 1px solid #999999;
    color: #999999;
  }

  &::placeholder {
    color: #999999;
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type="number"] {
    -moz-appearance: textfield;
  }
`;
