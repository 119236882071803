import * as S from "core/components/Styled";
import { theme } from "core/styles/theme";
import moment from "moment";
import { useEffect, useState } from "react";
import styled from "styled-components";

interface IProps {
  date: string; //YYYY-MM
  setDate: (e: any) => void;
  list?: { date: string; content: any; style?: any }[];
  className?: string;
}

export default function YearCalendar({
  date,
  setDate,
  list,
  className
}: IProps) {
  const [month, setMonth] = useState(moment(date, "YYYY-MM").format("MM"));
  const [year, setYear] = useState(moment(date, "YYYY-MM").format("YYYY"));

  useEffect(() => {
    setMonth(moment(date, "YYYY-MM").format("MM"));
    setYear(moment(date, "YYYY-MM").format("YYYY"));
  }, [date]);

  return (
    <>
      <StyleTable className={className}>
        {[...Array(3)].map((i, index) => (
          <>
            <tr key={"month_title" + index}>
              {[...Array(4)].map((item, idx) => {
                const mm: number = idx + 1 + index * 4;

                const _month: string =
                  (mm + "").length === 1 ? "0" + mm : mm + "";

                return (
                  <th
                    className={`year_${year}-${_month}`}
                    style={{
                      backgroundColor: month === _month && theme.colors.primary
                    }}
                    onClick={() => {
                      setDate(`${year}-${_month}`);
                    }}
                    key={" title" + index}
                  >
                    <S.StyledText style={{ color: month === _month && "#fff" }}>
                      {" "}
                      {mm + "월"}
                    </S.StyledText>
                  </th>
                );
              })}
            </tr>
            <tr key={"month_content" + index}>
              {[...Array(4)].map((it, idx) => {
                let month: string = idx + 1 + index * 4 + "";
                if (month.length === 1) month = "0" + month;

                const _date = year + "-" + month;
                const _month: string =
                  month.length === 1 ? "0" + month : month + "";

                return (
                  <td
                    key={" content" + index}
                    onClick={() => {
                      setDate(`${year}-${_month}`);
                    }}
                  >
                    {_date &&
                      list &&
                      list?.map((item: any) => {
                        if (item.date !== _date) return;

                        let content = undefined;

                        if (item?.date === _date) {
                          content = item?.content;
                        }

                        if (!content) return <></>;

                        return <div key={"month_item" + month}>{content}</div>;
                        // }
                      })}
                  </td>
                );
              })}
            </tr>
          </>
        ))}
      </StyleTable>
    </>
  );
}

const StyleTable = styled(S.StyleColumnForm)`
  border-radius: 0px;
  table-layout: fixed;

  & > tr:nth-child(2n + 1) {
    height: 40px;
  }
  & > tr > th {
    & > h1 {
      font-size: 15px;
      line-height: 24px;
      font-weight: 700;
    }
    max-height: 40px;
    height: 40px;
    text-align: center;

    padding: 8px;

    &:last-child,
    :first-child {
      border-radius: 0px;
    }
  }

  & > tr > td {
    height: 152px;
    min-height: 152px;

    > div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-bottom: 6.98px;

      :last-child {
        padding-bottom: 0px;
      }
      & > div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }
  }
  & > tr:last-child > th {
    border-bottom: 0px;
  }
`;
const StyleText = styled(S.StyledText)`
  font-size: 14px;
  line-height: 120%;
  color: #999;
`;
