import { Modal } from "antd";

interface OptionProps {
  title?: string;
  type?: "alert" | "confirm";
  onOk?: (e: any) => void;
  onCancel?: (e: any) => void;
  okText?: string;
  cancelText?: string;
}
export function alertComp(
  content: any,
  { title, onOk, okText = "확인" }: OptionProps = {}
) {
  return alert(content);
}

export function alert2(
  content: any,
  { title, onOk, okText = "확인" }: OptionProps = {}
) {
  return Modal.info({
    className: "customAlert",
    title,
    onOk: onOk,
    okText: okText,
    content,
    maskClosable: true,
    closable: false,
    icon: <></>,
    autoFocusButton: "ok",
    zIndex: 999999
  });
}

export function confirmComp(
  content: any,
  {
    title = "알림",
    onOk,
    onCancel,
    okText = "확인",
    cancelText = "취소"
  }: OptionProps
) {
  return confirm(content);

  return Modal.confirm({
    className: "customAlert ",
    title,
    content,
    icon: <></>,
    maskClosable: true,
    closable: false,
    onOk: onOk,
    okText: okText,
    autoFocusButton: "ok",
    cancelText: cancelText,
    onCancel: onCancel,
    zIndex: 999999
  });
}
