import MonthButton from "core/components/common/MonthButton";
import moment from "moment";
import styled from "styled-components";
import CP from ".";

interface IProps {
  type?: "day" | "month" | "oneMonth" | "year";
  /**
   * day : 날짜 a between b
   * month : 월 a between b
   * oneMonth : 월 [이전달][다음달]
   * year : 년도 selectbox
   */
  date?: string;
  date2?: string;
  /**
   * date 타입에 맞게 주기
   *   * day : YYYY-MM-DD
   * month : YYYY-MM
   * oneMonth : YYYY-MM
   * year :YYYY
   */
  onChange?: (e: any) => void;
  onChange2?: (e: any) => void;
  min?: string;
  max?: string;
  disabled?: boolean;
  disabled2?: boolean;
  error?: boolean;
  error2?: boolean;
  holiList?: { [key: string]: string };
  getHolidayList?: (e: string) => void;
}

export default function PeriodPicker({
  type = "day",
  date = "",
  date2 = "",
  onChange = () => {},
  onChange2 = () => {},
  min = "",
  max = "",
  disabled = false,
  disabled2 = false,
  error = false,
  error2 = false,
  getHolidayList,
  holiList
}: IProps) {
  const onDateChange = (e: any, key?: string) => {
    // console.log({e}, {key});
    if (!onChange) return console.log({ e });

    // const { date_start, date_end } = state;
    const date_start = date;
    const date_end = date2;

    const value = e;

    if (!value) return;

    const format =
      type === "year" ? "YYYY" : type === "day" ? "YYYY-MM-DD" : "YYYY-MM";

    if (min && min > value)
      return CP.alert(`[${min}] 이후 날짜로 지정할 수 있습니다.`, {});

    if (max && max < value)
      return CP.alert(`[${max}] 이전 날짜로 지정할 수 있습니다.`, {});

    if (!(date_start && date_end) && !key) return onChange(e);

    if (key === "date_end" && date_start) {
      if (date_start > value)
        return CP.alert("종료일보다 시작일보다 전일 수 없습니다.", {});
    } else if (key === "date_start" && date_end) {
      if (value > date_end) {
        return CP.alert("시작일이 종료일보다 이후일 수 없습니다.", {});
        // onChange(value);
        // setTimeout(()=>{
        //   onChange2('')

        // },2000)

        // return;
      }
    }

    if (key === "date_start") {
      return onChange(moment(value, "YYYY-MM-DD HH:mm:ss").format(format));
    }

    onChange2(moment(value, "YYYY-MM-DD HH:mm:ss").format(format));
  };

  if (type === "year") {
    return (
      <CP.Select
        list={[
          ...Array(Number(moment().add("y", 2).format("YYYY")) - 2022)
        ].map((item, index) => {
          const d: any =
            parseInt(moment().add("y", 2).format("YYYY")) - index + "";

          return { name: d, value: d };
        })}
        selected={date}
        setSelect={(e) => onChange(e?.value)}
        disabled={disabled}
      />
    );
  }

  if (type === "oneMonth") {
    return (
      <>
        <CP.DatePicker
          selectedDate={date}
          handleDate={onDateChange}
          type={"month"}
          disabled={disabled}
          error={error}
        />
        <MonthButton
          onClick={() =>
            onDateChange(moment(date, "YYYY-MM").add(-1, "M").format("YYYY-MM"))
          }
          label="이전 달"
          disabled={
            disabled
              ? disabled
              : min && date === moment(min, "YYYY-MM-DD").format("YYYY-MM")
              ? true
              : false
          }
        />
        <MonthButton
          disabled={
            disabled
              ? disabled
              : max && date === moment(max, "YYYY-MM-DD").format("YYYY-MM")
              ? true
              : false
          }
          onClick={() =>
            onDateChange(moment(date, "YYYY-MM").add(1, "M").format("YYYY-MM"))
          }
          label="다음 달"
        />
      </>
    );
  }

  return (
    <>
      <CP.DatePicker
        type={type}
        handleDate={(e: any) => onDateChange(e, "date_start")}
        selectedDate={date ? date : ""}
        disabled={disabled}
        error={error}
        holiList={holiList}
        getHolidayList={(year: string) => getHolidayList(year)}
      />
      <span>~</span>
      <CP.DatePicker
        type={type}
        handleDate={(e: any) => onDateChange(e, "date_end")}
        selectedDate={date2 ? date2 : ""}
        disabled={disabled2}
        error={error2}
        holiList={holiList}
        getHolidayList={getHolidayList}
      />
    </>
  );
}

const DatePickerWrapper = styled.div`
  max-width: 290px;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 16px;
  padding: 8px 0px;

  & button {
    background: transparent;
  }
`;
