import { Radio as RadioComp } from 'antd';
import styled from 'styled-components';

export type RadioItem = {
  name: string;
  value: string | number;
  disabled?: boolean;
};

interface RadioProps {
  list?: RadioItem[];
  selected: RadioItem | string | number;
  setSelect: (value: RadioItem) => void;
  className?: string;
  disabled?: boolean;
}

export default function SmallRadio({
  className,
  list,
  selected,
  setSelect,
  disabled,
}: RadioProps) {
  return (
    <RadioComp.Group
      className={className}
      onChange={e => {
        setSelect(e.target.value);
      }}
      value={selected}
      disabled={disabled}
    >
      {list?.map((item, index) => (
        <StyledRadio key={index} value={item.value} disabled={item.disabled}>
          {item.name}
        </StyledRadio>
      ))}
    </RadioComp.Group>
  );
}

const StyledRadio = styled(RadioComp)`
  &.ant-radio-wrapper {
    // margin-inline-end: 20px;
    // padding-left: 14px;
    & span:last-child {
      font-family: Pretendard;
      font-weight: 400;
      font-size: 14px;
      color: ${({ theme }) => theme.colors.black222};
      padding: 0px;
      padding-left: 8px;
    }

    & .ant-radio-checked .ant-radio-inner {
      border-color: ${({ theme }) => theme.colors.primary};
      background-color: ${({ theme }) => theme.colors.primary};

      &::after {
        background-color: #fff;
      }
    }
  }
`;
