import CP from "core/components/common";
import { ButtonProps } from "core/components/common/Button";

interface MonthButtonProps extends ButtonProps {
  label: string;
}

export default function MonthButton({ ...rest }: MonthButtonProps) {
  return (
    <CP.Button
      variant="outline"
      color="primary"
      {...rest}
      style={{ width: 64, padding: "0 12px", fontSize: 14 }}
    >
      {rest.label}
    </CP.Button>
  );
}
