import { Radio as RadioComp } from 'antd';
import styled, { css } from 'styled-components';

export type RadioItem = {
  name: string;
  value: string | number;
  disabled?: boolean;
};

interface RadioProps {
  list?: RadioItem[];
  selected: RadioItem | string | number;
  setSelect: (e: any) => void;
  className?: string;
  disabled?: boolean;
  style?: any;
  radioStyle?: any;
  optionType?: 'default' | 'button';
  error?:boolean;
}

export default function Radio({
  className,
  list,
  selected,
  setSelect,
  disabled,
  style,
  radioStyle,
  optionType = 'default',
  error
}: RadioProps) {
  return (
    <RadioComp.Group
      className={className}
      onChange={e => {
        setSelect(e.target.value);
      }}
      value={selected}
      disabled={disabled}
      style={style}
      optionType={optionType}  
      // {...rest}
    >
      {list?.map((item: RadioItem, index: number) => (
        <StyledRadio
          key={index}
          value={item.value}
          disabled={item.disabled}
          optionType={optionType}
          style={radioStyle}
          error={error}
          // {...rest}
        >
          {item.name}
        </StyledRadio>
      ))}
    </RadioComp.Group>
  );
}

const StyledRadio = styled(RadioComp)<{ optionType: 'default' | 'button' , error?:boolean}>`
  ${({ optionType, error }) =>
    optionType === 'default' &&
    css`
      &.ant-radio-wrapper {
        // margin-inline-end: 20px;
        // padding-left: 16px;

        & span:last-child {
          font-family: Pretendard;
          font-weight: 400;
          font-size: 13px;
          color: ${({ theme }) => theme.colors.black222};
          padding: 0px;
          padding-left: 8px; 
           border-color :${ error ?'rgb(255, 105, 105)': '#d9d9d9'}
        }

        & .ant-radio-checked .ant-radio-inner {
          border-color: ${({ theme }) => theme.colors.primary};
          background-color: ${({ theme }) => theme.colors.primary};

          &::after {
            background-color: #fff;
          }
        }
      }
    `}
  ${({ optionType , error}) =>
    optionType === 'button' &&
    css`
      background: transparent;
      border : 1px solid ${error ? 'rgb(255, 105, 105)' : '#d9d9d9' }; 
      border-inline-start : 1px solid ${error ? 'rgb(255, 105, 105)' : '#d9d9d9' } !important; 
      color: #222 !important;
      margin-bottom: 2px;
      margin-right: 2px;
      border-start-start-radius: 3px !important;
      border-start-end-radius: 3px !important;
      border-end-start-radius: 3px !important;
      border-end-end-radius: 3px !important;
      &::before {
        background: transparent !important;
        border: 0px !important;
      }

      & .ant-radio-button-wrapper{
     
      }

      &:hover:not(
          .ant-radio-button-wrapper-checked,
          .ant-radio-button-wrapper-disabled
        ) {
        background: ${({ theme }) => theme.colors?.primary};
        color: #fff !important;
        font-weight: 700;
      }
      &.ant-radio-button-wrapper-disabled {
        background: #eaeaea;
        border-color: #eaeaea;
      }

      &.ant-radio-button-wrapper-checked {
        // border: 1px solid ${({ theme }) => theme.colors?.primary};
        border-color: ${({ theme }) => theme.colors?.primary};
        background: ${({ theme }) => theme.colors?.primary};
        color: #fff !important;
        font-weight: 700;

        &:hover {
          border-color: ${({ theme }) => theme.colors?.primary};
        }
      }
    `}
`;
