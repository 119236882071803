import * as S from "core/components/Styled";
import { Checkbox as CheckboxComp } from "antd";
import styled from "styled-components";
import { theme } from "core/styles/theme";

interface CheckBoxProps {
  checked?: boolean;
  onClick?: (value: any) => void;
  ref?: any;
  label?: string;
  value?: any;
  disabled?: boolean;
}

export type CheckBoxItem = {
  label?: string;
  value: string | number;
  disabled?: boolean;
};

interface CheckBoxListProps {
  list?: CheckBoxItem[] | string[] | string;
  checked?: CheckBoxItem | string;
  onClick?: (value: CheckBoxItem) => void;
  ref?: any;
}

export default function SmallCheckBox({
  checked = undefined,
  onClick,
  ref,
  disabled,
  label,
  value,
}: CheckBoxProps) {
  return (
    <>
      <StyledCheckbox
        disabled={disabled}
        value={value && value}
        onClick={onClick}
        checked={checked && checked}
      >
        {label && (
          <S.StyledText type="subTitle" color={theme.colors.black222} size="13">
            {label}
          </S.StyledText>
        )}
      </StyledCheckbox>
    </>
  );
}

export function CheckBoxList({
  list,
  checked,
  onClick,
  ref,
}: CheckBoxListProps) {
  return (
    <>
      {typeof list !== "string" && list?.length > 0 ? (
        <CheckboxComp.Group ref={ref}>
          {list?.map((item: CheckBoxItem | string, index) => {
            if (typeof item === "string") return null;
            return (
              <StyledCheckbox
                key={index}
                disabled={item?.disabled}
                value={item?.value}
                onClick={(e) => onClick(item)}
                checked={
                  typeof checked === "string"
                    ? checked === item?.value
                      ? true
                      : false
                    : checked.value === item.value
                    ? true
                    : false
                }
              >
                {item?.label && (
                  <S.StyledText type="subTitle" color={theme.colors.black222}>
                    {item?.label}
                  </S.StyledText>
                )}
              </StyledCheckbox>
            );
          })}
        </CheckboxComp.Group>
      ) : (
        <>
          <StyledCheckbox></StyledCheckbox>
        </>
      )}
    </>
  );
}

const StyledCheckbox = styled(CheckboxComp)`
  & .ant-checkbox-checked .ant-checkbox-inner {
    border-color: ${({ theme }) => theme.colors.primary} !important;
    background-color: ${({ theme }) => theme.colors.primary} !important;
  }
`;
