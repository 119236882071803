import * as S from "core/components/Styled";
// import { Button, Divider, Segmented, Tooltip as TooltipComp } from 'antd';
import Tooltip from "@mui/material/Tooltip";
import { tootipState } from "core/stores/layoutStore";
import { useRecoilState } from "recoil";
// import { Tooltip } from 'react-tooltip';

export interface IProps {
  content: any | string;
  placement?: "top" | "left" | "right" | "bottom";
  children?: any;
  style?: any;
}

const TooltipComponent = ({
  content,
  placement = "bottom",
  children,
  style,
}: IProps) => {
  const [tooltip, setTooltip] = useRecoilState(tootipState);

  return (
    <Tooltip
      followCursor={true}
      style={style}
      placement={placement}
      // open={tooltip}
      // disableHoverListener={true}
      onOpen={() => {
        if (tooltip === true) return;

        setTooltip(true);
      }}
      onClose={() => setTooltip(false)}
      title={
        typeof content === "string" ? (
          <S.StyledText color={"#222"}>{content}</S.StyledText>
        ) : (
          content
        )
      }
      arrow={false}
      //  overlayStyle={{marginBottom : '-50px'}}
      //  overlayInnerStyle={{padding :16, borderRadius : '0px', color :'#fff', }}
    >
      {children}
    </Tooltip>
  );
};

export default TooltipComponent;
