import { PageListOption } from "core/models/Common";
import { uuid } from "core/utils/index";
import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { Arrow2Icon, ArrowIcon } from "./icons";

interface PaginationProps {
  total: number;
  // page: number;
  // pageSize: number;
  // onPageChange: (page: number) => void;
  className?: string;
  pageOption?: PageListOption;
  setPageOption?: (item: any) => void;
  pagingSize?: number;
}

export default function Pagination({
  // onPageChange,
  // page,
  // pageSize,
  total,
  className,
  pageOption,
  setPageOption,
  pagingSize = 5,
}: PaginationProps) {
  const totalPages = Math.ceil(total / pageOption?.perSize);


  const [pgDisabled, setPgDisabled] = useState({
    prev: true,
    next: false,
    min: 0,
  });

  const pagingDisableSetting = useCallback(() => {
    const { page, perSize } = pageOption;
    let _prev = pgDisabled.prev;
    let _next = pgDisabled.next;
    let _min = pgDisabled.min;

    if (totalPages <= 1) {
      _prev = true;
      _next = true;
    } else {
      if (page === 1) {
        _prev = true;
      } else if (page > 1) {
        _prev = false;
      }
      if (page === totalPages) {
        _next = true;
      } else if (page < totalPages) {
        _next = false;
      }
    }

    const paSt = page + "";
    if (paSt.length > 1) {
      const num = paSt.substring(0, paSt.length - 1);

      _min = parseInt(num + "0");

      if(page > 0 && page % 10 === 0)_min = _min -10;

    } else {
      _min = 0;
    }
    setPgDisabled({ prev: _prev, next: _next, min: _min });
  }, [
    pageOption,
    pgDisabled.min,
    pgDisabled.next,
    pgDisabled.prev,
    totalPages,
  ]);

  useEffect(() => {
    // console.log({ skip }, { totalPages });
    if (pageOption.page === undefined) return;
    pagingDisableSetting();
  }, [pagingDisableSetting, pageOption, totalPages]);


  if (!totalPages) return;

  const pagingSetting = (pageNumber: number) => {
    const { perSize } = pageOption;

    setPageOption({
      ...pageOption,
      page: pageNumber,
      skip: (pageNumber - 1) * perSize + "",
      take: perSize + "", //'20', // pageNumber * perSize + '',
    });
  };

  return (
    <div
      className={className}
      style={{ paddingTop: "32px", paddingBottom: "16px" }}
    >
      <OrderList>
        <FirstButton
          disabled={pgDisabled.prev}
          onClick={() => pagingSetting(1)}
        >
          <Arrow2Icon width={16} height={16} />
        </FirstButton>
        <PrevButton
          disabled={pgDisabled.prev}
          onClick={() => pagingSetting(pageOption?.page - 1)}
        >
          <ArrowIcon width={16} height={16} />
        </PrevButton>

        {[...Array(totalPages - pgDisabled.min)].map((num, index) => {
          if (index > 9) return;
          const pgNum = pgDisabled.min + index + 1;

          return (
            <PageButton
              active={pageOption.page === pgNum}
              key={uuid()}
              onClick={() => pagingSetting(pgNum)}
            >
              {pgNum}
            </PageButton>
          );
        })}

        <NextButton
          disabled={pgDisabled.next}
          onClick={() => pagingSetting(pageOption.page + 1)}
        >
          <ArrowIcon width={16} height={16} />
        </NextButton>
        <LastButton
          disabled={pgDisabled.next}
          onClick={() => pagingSetting(totalPages)}
        >
          <Arrow2Icon width={16} height={16} />
        </LastButton>
      </OrderList>
    </div>
  );
}

const OrderList = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
`;

const PageButton = styled.button<{ active?: boolean; disabled?: boolean }>`
  background-color: ${({ active, theme }) =>
    active ? theme.colors.primary : theme.colors.white};

  color: ${({ active, theme }) =>
    active ? theme.colors.white : theme.colors.gray150};
  min-width: 32px;
  min-height: 32px;
  border: ${({ active, theme }) =>
    active ? "none" : `1px solid ${theme.colors.greyCCC}`};

  border-radius: 4px;
  cursor: pointer;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;

  &:hover {
    color: ${({ active, theme }) => !active && theme.colors.primary};
    border: none;
  }

  &:focus {
    outline: none;
  }
  &:disabled {
    cursor: not-allowed;
    // background: #f5f5f5;
    border: none;
    color: ${({ active, theme }) => theme.colors.greyCCC};
  }
`;

const PrevButton = styled(PageButton)<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(180deg);
  &:hover {
    color: ${({ theme, disabled }) => !disabled && theme.colors.gray150};
  }
`;
const NextButton = styled(PrevButton)`
  transform: rotate(0deg);
`;
const FirstButton = styled(PrevButton)`
  transform: rotate(180deg);
`;
const LastButton = styled(PrevButton)`
  transform: rotate(0deg);
`;
