import * as S from "core/components/Styled";
import { OrgInfo } from "core/models/Common";
import { isArray } from "lodash";
import { useEffect, useState } from "react";
import styled from "styled-components";
import CP from ".";
import { COMPANY_NO } from "../../constants/enums";

interface TreeProps {
  data: OrgInfo[];
  folderIcon?: any;
  icon?: any;
  orgInfo: OrgInfo | OrgInfo[];
  onClick: (orgInfo: OrgInfo) => void;
  searchText?: string;
  highlight?: boolean;
  repIndex?: string;
  style?:any;
  isRepOpen?:boolean;
  disabled?:boolean;
}

interface DataProps extends OrgInfo {
  children: DataProps[];
}

export default function Tree({
  data,
  orgInfo,
  folderIcon,
  icon,
  onClick,
  highlight = false,
  searchText,
  repIndex = COMPANY_NO.Adpia,
  style,
  isRepOpen = true,
  disabled = false
}: TreeProps) {
  const [all, setAll] = useState(true);
  const [openFolder, setOpenFolder] = useState<string[]>(["0" ]);

  const onToggleMenu = (id: string) => {
    if (id === "") {
      setAll(!all);
    }
    if (openFolder.indexOf(id) !== -1) {
      setOpenFolder(openFolder.filter((item) => item !== id));
    } else {
      setOpenFolder(openFolder.concat([id]));
    }
  };

  const changeOpenFolder = (id: string) => {
    if (openFolder.find((item) => item === id)) return;
    setOpenFolder(openFolder.concat([id]));
  };

  const highlightedText = (text: any, query: any, item: DataProps) => {
    if (query !== "" && text.includes(query)) {
      const parts = text.split(new RegExp(`(${query})`, "gi"));

      return (
        <>
          {parts.map((part: any, index: number) => {
            const isMark = part.toLowerCase() === query.toLowerCase();
            if (isMark) {
              changeOpenFolder(String(item.org_no));
              changeOpenFolder(String(item.parent_org_no));
              // onToggleMenu(String(item.org_no))
              return <mark key={index}>{part}</mark>;
            } else return part;
          })}
        </>
      );
    }

    return text;
  };

  const rowSetting = (data: DataProps[], index: number = 0) => {
    if (data?.length === 0) return;
    const sortedData = data.sort((a, b) => a.print_index - b.print_index);
    return (
      <>
        {sortedData?.map((item, idx) => {
          const children = item.children;
          let selected = false;

          const orgName =item?.org_name ? item.org_name : '';

          if (orgInfo === null || (isArray(orgInfo) && orgInfo?.length === 0))
            selected = false;
          else if (!isArray(orgInfo) && item.org_no === orgInfo?.org_no)
            selected = true;
          else if (
            isArray(orgInfo) &&
            orgInfo?.filter((org) => org?.org_no === item.org_no)?.length > 0
          )
            selected = true;
          else selected = false;

          const isActive = openFolder.indexOf(item.parent_org_no + "") !== -1;

          return (
            <ButtonGroup
              key={"button_group_" + item.org_no}
              className={`abcc group_${item.parent_org_no} ${
                isActive ? " active " : " disabled"
              }`}
            >
              <ButtonWrapper
                className={"ab"}
                key={"button_wrapper_" + item.org_no}
              >
                <CP.Flex gap={"4px"}>
                  {[...new Array(Number(item.depth) - 1)].map((v, i) => (
                    <div
                      key={"div_empty_" + item.org_no + i}
                      style={{ width: 24, height: 24 }}
                    />
                  ))}
                  <StyleMainButton>
                    {children?.length > 0 ? (
                      <>
                        <StyleButton
                          onClick={() => {
                            if(disabled)return;
                            else if (isRepOpen && item?.org_no === Number(repIndex)) return;

                            onToggleMenu(String(item.org_no));
                          }}
                          style={{ paddingBottom: 0 }}
                        >
                          {Number(item.depth) === 1 ? (
                            <CP.Icon.FolderHomeIcon />
                          ) : openFolder.indexOf(String(item.org_no)) !== -1 ? (
                            <CP.Icon.FolderOpenIcon />
                          ) : (
                            <CP.Icon.FolderCloseIcon />
                          )}

                          {/* <TreeBgIcon>
                            <CP.Icon.TreeCaretArrowIcon
                            style={{
                                transform: openFolder.indexOf(String(item.org_no)) !== -1 ? `rotate(0deg)` : `rotate(-90deg)`,
                              }}
                             />
                          </TreeBgIcon> */}
                        </StyleButton>

                        <StyleButton
                          onClick={() =>  onClick(item)}
                          style={{ paddingBottom: 0 }}
                        >
                          {/* <CP.Icon.FolderIcon /> */}
                          <div
                            style={{
                              backgroundColor: selected
                                ? "#6C62D1"
                                : "transparent",
                              paddingLeft: 4,
                              paddingRight: 4,
                              height: "24px",
                              justifyContent: "center",
                              alignItems: "center",
                              display: "flex",
                              borderRadius: 2
                            }}
                          >
                            <S.StyledText
                              lineHeight="normal"
                              color={selected ? "#FFFFFF" : "#555"}
                              className={String(item.org_no)}
                            >
                              {highlight
                                ? highlightedText(
                                   orgName,
                                    searchText,
                                    item
                                  )
                                : orgName}
                            </S.StyledText>
                          </div>
                        </StyleButton>
                      </>
                    ) : (
                      <>
                        <StyleButton
                          className={String(item.org_no)}
                          onClick={() =>  onClick(item)}
                          style={{ paddingBottom: 0 }}
                        >
                          <CP.Icon.TreePersonIcon />

                          <div
                            style={{
                              backgroundColor: selected
                                ? "#6C62D1"
                                : "transparent",
                              paddingLeft: 4,
                              paddingRight: 4,
                              height: "24px",
                              justifyContent: "center",
                              alignItems: "center",
                              display: "flex",
                              borderRadius: 2
                            }}
                          >
                            <S.StyledText
                              lineHeight="normal"
                              color={selected ? "#FFFFFF" : "#555"}
                              className={String(item.org_no)}
                            >
                              {highlight
                                ? highlightedText(
                                  orgName,
                                    searchText,
                                    item
                                  )
                                : orgName}
                            </S.StyledText>
                          </div>
                        </StyleButton>
                      </>
                    )}
                  </StyleMainButton>
                </CP.Flex>
              </ButtonWrapper>
              {children && rowSetting(children)}
            </ButtonGroup>
          );
        })}
      </>
    );
  };

  const settingChild = (data: OrgInfo[], index: number = 0) => {
    const setting = (data: OrgInfo[], org_no = "0") => {
      const _data = data?.filter(
        (item: OrgInfo) => item.parent_org_no + "" === org_no
      );
      const ret: any = _data?.map((item: OrgInfo) => ({
        ...item,
        children: setting(data, item.org_no + "")
      }));
      return ret;
    };

    const tree = setting(data).filter(
      (item: DataProps) => item.org_no + "" === repIndex
    );

    return rowSetting(tree);
  };

  useEffect(() => {
    if(!isRepOpen)return  setOpenFolder(["0" ]);
    else setOpenFolder(["0", repIndex]);
  }, [searchText, isRepOpen]);


  return <Wrapper style={style}>{settingChild(data)}</Wrapper>;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4px 8px 8px 8px;
  gap: 4px;
  & > button {
    display: flex;
    align-items: center;
    /* gap: 4px; */
    & > h1 {
      text-align: left;
    }
  }
  max-height: 100%;
  width: 100%;
  overflow-y: auto;
`;

const TreeBgIcon = styled.div`
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" ><path fill-rule="evenodd" d="M18.2929 9.70711C18.9229 9.07714 20 9.52331 20 10.4142V19C20 19.5523 19.5523 20 19 20H10.4142C9.52331 20 9.07714 18.9229 9.70711 18.2929L18.2929 9.70711Z"/></svg>');
  background-repeat: no-repeat;
  background-position: center left;
  background-size: contain;
  width: 24px;
  height: 24px;
`;

const ButtonGroup = styled.div`
  padding-top: 4px;
  &.disabled {
    display: none;
  }

  &.active {
    display: block;
  }
`;

const ButtonWrapper = styled.div`
  /* &:hover {
    background-color: #f0eeff;
  } */
`;

const StyleMainButton = styled.button`
  background-color: transparent;
  display: flex;
  align-items: center;
  gap: 4px;
  /* padding-bottom: 4px; */
  /* padding-right: 8px; */
  /* padding-left:8px; */
  &:hover {
    background-color: #f0eeff;
  }
`;

const StyleButton = styled.button`
  background-color: transparent;
  display: flex;
  align-items: center;
  gap: 4px;
  padding-bottom: 4px;
`;
