import * as S from "core/components/Styled";
import { useState } from "react";
import styled from "styled-components";
import CP from ".";

interface TreeProps {
  defaultIdx?: string;
  defaultParentIdx?: string;
  data: TreeDataProps[];
  select?: string | string[];
  onClick: (idx: string) => void;
  isChecked?: boolean;
  onChecked?: (idx: string) => void;
  checkItem?: string[];
}

export interface TreeDataProps {
  idx: string;
  name: string;
  parentIdx: string;
  print_index?: number;
  depth?: string;
  children?: TreeDataProps[];
  code?: string;
}

export default function Tree({
  data,
  select,
  onClick,
  defaultIdx,
  defaultParentIdx,
  isChecked,
  checkItem,
  onChecked,
}: // highlight = false,
// searchText,
TreeProps) {
  const [openFolder, setOpenFolder] = useState<string[]>(["0", defaultIdx]);

  const onToggleMenu = (id: string) => {
    if (openFolder.indexOf(id) !== -1) {
      setOpenFolder(openFolder.filter((item) => item !== id));
    } else {
      setOpenFolder(openFolder.concat([id]));
    }
  };

  const itemSetting = (item: TreeDataProps) => {
    const children = item.children;

    let icon;

    if (children?.length > 0) {
      if (
        Number(item.depth) === 1 ||
        openFolder.indexOf(String(item.idx)) !== -1
      ) {
        icon = (
          <>
            <CP.Icon.TreeCaretIcon />
            <CP.Icon.TreeFolderIcon />
          </>
        );
      } else {
        icon = (
          <>
            <CP.Icon.TreeCaretCloseIcon />
            <CP.Icon.TreeFolderCloseIcon />
          </>
        );
      }
    } else {
      icon = (
        <>
          <div style={{ width: 24 }} />
          <CP.Icon.TreeFolderItemIcon />
        </>
      );
    }

    return (
      <CP.Flex gap={"4px"} align="center">
        {icon}
        {isChecked && (
          <CP.CheckBox
            checked={
              checkItem?.filter((check) => check === item.idx)?.length > 0
                ? true
                : false
            }
          />
        )}
        <S.StyledText>{item?.name}</S.StyledText>
      </CP.Flex>
    );
  };
  const rowSetting = (data: TreeDataProps[], index: number = 0) => {
    if (data?.length === 0) return;
    const sortedData = data.sort((a, b) => a.print_index - b.print_index);
    return (
      <>
        {sortedData?.map((item, idx) => {
          const children = item.children;
          let isActive =
            openFolder.indexOf(item.idx + "") !== -1 ||
            openFolder.indexOf(item.parentIdx + "") !== -1;

          let selected = false;

          return (
            <ButtonGroup
              key={"button_group_" + item.idx}
              className={`tree group_${item.parentIdx} ${
                isActive ? " active " : " disabled"
              }`}
            >
              <ButtonWrapper
                className={"ab"}
                key={"button_wrapper_" + item.idx}
              >
                <CP.Flex gap={"4px"}>
                  {[...new Array(Number(item.depth) - 1)].map((v, i) => (
                    <div
                      key={"div_empty_" + item.idx + i}
                      style={{ width: 24, height: 24 }}
                    />
                  ))}
                  <StyleMainButton>
                    {children?.length > 0 ? (
                      <>
                        <StyleButton
                          onClick={() => {
                            onToggleMenu(String(item.idx));
                          }}
                          style={{ paddingBottom: 0 }}
                        >
                          {openFolder.indexOf(String(item.idx)) !== -1 ? (
                            <CP.Icon.TreeFolderIcon />
                          ) : (
                            <CP.Icon.TreeFolderCloseIcon />
                          )}
                        </StyleButton>

                        <StyleButton
                          onClick={() => {
                            if (onChecked) onChecked(item?.idx);
                          }}
                          style={{ paddingBottom: 0 }}
                        >
                          {isChecked && (
                            <CP.CheckBox
                              checked={
                                checkItem?.filter((check) => check === item.idx)
                                  ?.length > 0
                                  ? true
                                  : false
                              }
                            />
                          )}

                          <div
                            style={{
                              backgroundColor: selected
                                ? "#6C62D1"
                                : "transparent",
                              paddingLeft: 4,
                              paddingRight: 4,
                              height: "24px",
                              justifyContent: "center",
                              alignItems: "center",
                              display: "flex",
                              borderRadius: 2,
                            }}
                          >
                            <S.StyledText
                              lineHeight="normal"
                              color={selected ? "#FFFFFF" : "#555"}
                              className={String(item.idx)}
                            >
                              {item.name}
                            </S.StyledText>
                          </div>
                        </StyleButton>
                      </>
                    ) : (
                      <>
                        <StyleButton
                          className={String(item.idx)}
                          onClick={() => {
                            if (item?.children?.length === 0 && onChecked)
                              onChecked(item?.idx);
                          }}
                          style={{ paddingBottom: 0 }}
                        >
                          <CP.Icon.TreeFolderItemIcon />

                          {isChecked && (
                            <CP.CheckBox
                              checked={
                                checkItem?.filter((check) => check === item.idx)
                                  ?.length > 0
                                  ? true
                                  : false
                              }
                            />
                          )}

                          <div
                            style={{
                              backgroundColor: selected
                                ? "#6C62D1"
                                : "transparent",
                              paddingLeft: 4,
                              paddingRight: 4,
                              height: "24px",
                              justifyContent: "center",
                              alignItems: "center",
                              display: "flex",
                              borderRadius: 2,
                            }}
                          >
                            <S.StyledText
                              lineHeight="normal"
                              color={selected ? "#FFFFFF" : "#555"}
                              className={String(item.idx)}
                            >
                              {item.name}
                            </S.StyledText>
                          </div>
                        </StyleButton>
                      </>
                    )}
                  </StyleMainButton>
                </CP.Flex>
              </ButtonWrapper>
              {children && rowSetting(children)}
            </ButtonGroup>
          );
        })}
      </>
    );
  };

  const setting = (
    data: TreeDataProps[],
    idx = defaultParentIdx ? defaultParentIdx : "99"
  ) => {
    const _data = !data
      ? []
      : data?.filter((item: TreeDataProps) => item.parentIdx === idx);

    const ret: any = _data?.map((item: TreeDataProps) => ({
      ...item,
      children: setting(data, item.idx),
    }));

    return ret;
  };

  const settingChild = (data: TreeDataProps[], index: number = 0) => {
    const tree = setting(data).filter(
      (item: TreeDataProps) => item.idx === defaultIdx
    );

    return rowSetting(tree);
  };

  return <Wrapper>{settingChild(data)}</Wrapper>;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4px 8px 8px 8px;
  gap: 4px;
  & > button {
    display: flex;
    align-items: center;
    /* gap: 4px; */
    & > h1 {
      text-align: left;
    }
  }
  max-height: 100%;
  width: 100%;
  /* overflow-y: auto; */
`;
const ButtonGroup = styled.div`
  padding-top: 4px;
  &.disabled {
    display: none;
  }

  &.active {
    display: block;
  }
`;

const ButtonWrapper = styled.div``;

const StyleMainButton = styled.button`
  background-color: transparent;
  display: flex;
  align-items: center;
  gap: 4px;
  /* padding-bottom: 4px; */
  /* padding-right: 8px; */
  /* padding-left:8px; */
  &:hover {
    background-color: #f0eeff;
  }
`;

const StyleButton = styled.button`
  background-color: transparent;
  display: flex;
  align-items: center;
  gap: 4px;
  padding-bottom: 4px;
`;
