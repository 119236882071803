import { useCalendar } from "@h6s/calendar";
import { Dropdown } from "antd";
import classnames from "classnames/bind";
import * as S from "core/components/Styled";
import { format } from "date-fns";
import { ko } from "date-fns/locale";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import CP from ".";
import styles from "./DatePicker.module.scss";
import { ArrowIcon, CalendarIcon, CloseIcon } from "./icons";

const cx = classnames.bind(styles);

export interface DateSelectProps {
  selectedDate?: string;
  showMonthOnly?: boolean; //
  style?: any; //
  customStyle?: any;
  placeholder?: string;
  disabled?: boolean;
  error?: boolean;
  type?: "day" | "month" | "year";
  handleDate: (date: string) => void;
  content?: any;
  isHoliday?: boolean; //공휴일 배제 여부
  holiList?: { [key: string]: string };
  getHolidayList?: (e: string) => void;
}

export default function DatePicker({
  showMonthOnly,
  style,
  customStyle,
  error,
  placeholder = "날짜선택",
  disabled,
  type = "day",
  selectedDate,
  handleDate,
  content,
  isHoliday = true,
  getHolidayList,
  holiList
}: DateSelectProps) {
  const { headers, body, cursorDate, navigation } = useCalendar();

  const [viewType, setViewType] = useState<"year" | "month" | "day">(type);
  const [selcYear, setSelcYear] = useState<string>();
  const [selcMonth, setSelcMonth] = useState<string>();

  const [open, setOpen] = useState(false);

  // const [dateOpen, setDateOpen] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    if (!open) return;
    // handleDate("");
    setSelcYear(moment().format("YYYY"));
    setSelcMonth(moment().format("MM"));
    navigation.setDate(new Date());
  }, [open]);

  useEffect(() => {
    if (!getHolidayList) return;
    if (!selcYear) return;
    if (type !== "day") return;
    getHolidayList(selcYear);
  }, [selcYear, type]);

  useEffect(() => {
    let checkFormat = "YYYY-MM-DD";

    if (type === "month") checkFormat = "YYYY-MM";
    else if (type === "year") checkFormat = "YYYY";

    // if (
    //   selectedDate &&
    //   moment(selectedDate, checkFormat).format(checkFormat) === "Invalid date"
    // ) {
    //   handleDate("");
    //   setSelcYear(moment().format("YYYY"));
    //   setSelcMonth(moment().format("MM"));
    //   return;
    // }
    // setSelcYear(
    //   selectedDate ? formatYear(selectedDate) : moment().format("YYYY")
    // );
    // setSelcMonth(
    //   selectedDate ? formatMonth(selectedDate) : moment().format("MM")
    // );
  }, [selectedDate]);

  useEffect(() => {
    const onCheckClickOutside = (e: MouseEvent) => {
      //!searchRef.current.contains(e.target as Node) 은 SearchResult태그에 이벤트가 발생하지 않았을때를 의미
      if (
        open === true &&
        ref.current &&
        !ref.current.contains(e.target as Node)
      ) {
        setOpen(false); //외부 클릭시 실행할 로직
      }
    };
    document.addEventListener("mousedown", onCheckClickOutside); //컴포넌트 렌더링 후 이벤트 등록
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", onCheckClickOutside); //컴포넌트가 제거될때 실행
    };
  }, [open, ref.current]);

  // const getHoliday = async () => {
  //   const res: any = await CommonService.getHoliday({
  //     y: selcYear,
  //     ym: ``
  //   });

  //   if (res && res?.row) {
  //     let _holiList: { [key: string]: string } = {};

  //     res.row?.map((_month: string[][]) => {
  //       _month?.map((_date: string[]) => {
  //         if (_date?.length === 2) {
  //           _holiList[_date[0]] = _date[1];
  //         }
  //       });
  //     });

  //     setHoliList(_holiList);
  //   }
  // };

  const handlePrevYear = () => {
    if (viewType === "year")
      setSelcYear(moment(selcYear, "YYYY").add(-10, "y").format("YYYY"));
    else {
      setSelcYear(moment(selcYear, "YYYY").add(-1, "y").format("YYYY"));
      // if (selectedDate) {
      //   navigation.setDate(
      //     new Date(
      //       moment(selectedDate, "YYYY-MM-DD").add("y", -1).format("YYYY-MM-DD")
      //     )
      //   );
      // } else {
      navigation.setDate(
        new Date(Number(selcYear) - 1 + "-" + selcMonth + "-01")
      );
      // }
    }
  };

  const handleNextYear = () => {
    if (viewType === "year") {
      setSelcYear(moment(selcYear, "YYYY").add(10, "y").format("YYYY"));
    } else {
      // if (selectedDate) {
      //   navigation.setDate(
      //     new Date(
      //       moment(selectedDate, "YYYY-MM-DD").add("y", 1).format("YYYY-MM-DD")
      //     )
      //   );
      // } else {
      navigation.setDate(
        new Date(Number(selcYear) + 1 + "-" + selcMonth + "-01")
      );
      // }
      setSelcYear(moment(selcYear, "YYYY").add(1, "y").format("YYYY"));
    }
  };
  const handlePrevMonth = () => {
    setSelcMonth(
      moment(selcYear + selcMonth, "YYYYMM")
        .add(-1, "M")
        .format("MM")
    );
    setSelcYear(
      moment(selcYear + selcMonth, "YYYYMM")
        .add(-1, "M")
        .format("YYYY")
    );
    navigation.toPrev();
  };

  const handleNextMonth = () => {
    setSelcMonth(
      moment(selcYear + selcMonth, "YYYYMM")
        .add(1, "M")
        .format("MM")
    );

    setSelcYear(
      moment(selcYear + selcMonth, "YYYYMM")
        .add(1, "M")
        .format("YYYY")
    );
    navigation.toNext();
  };

  const onChangeMonth = (month: number) => {
    const mon = month < 10 ? "0" + month : month + "";

    if (type === "month") {
      handleDate(selcYear + "-" + mon);
      setOpen(false);
    } else {
      navigation.setDate(new Date(selcYear + "-" + mon + "-01"));
      setSelcMonth(mon);
      setViewType("day");
    }
  };

  const headerItem = () => {
    if (!selcYear) return;

    const y = selcYear.substring(0, selcYear.length - 1);
    if (viewType === "day") {
      return (
        <TopWrapper>
          <TopItem>
            <PrevYearIcon width={24} height={24} onClick={handlePrevYear} />
            <YearAndMonth>
              <button onClick={() => setViewType("year")}>
                <S.StyledText weight="700"> {`${selcYear}년`}</S.StyledText>
              </button>
            </YearAndMonth>
            <NextYearIcon width={24} height={24} onClick={handleNextYear} />
          </TopItem>
          <TopItem>
            <PrevMonthIcon onClick={handlePrevMonth} width={24} height={24} />
            <YearAndMonth>
              <button onClick={() => setViewType("month")}>
                <S.StyledText weight="700"> {`${selcMonth}월`}</S.StyledText>
              </button>
            </YearAndMonth>
            <NextMonthIcon onClick={handleNextMonth} width={24} height={24} />
          </TopItem>
        </TopWrapper>
      );
    } else if (viewType === "month") {
      return (
        <TopWrapper>
          <TopItem>
            <PrevYearIcon width={24} height={24} onClick={handlePrevYear} />
            <button onClick={() => setViewType("year")}>
              <YearAndMonth>
                {" "}
                <S.StyledText weight="700">
                  {" "}
                  {`${selcYear}년`}
                </S.StyledText>{" "}
              </YearAndMonth>
            </button>
            <NextYearIcon width={24} height={24} onClick={handleNextYear} />
          </TopItem>
        </TopWrapper>
      );
    } else {
      return (
        <TopWrapper>
          <TopItem>
            <PrevYearIcon width={24} height={24} onClick={handlePrevYear} />
            <button onClick={() => setViewType("month")}>
              <YearAndMonth>
                <S.StyledText weight="700"> {`${`${y}0~${y}9`}`}</S.StyledText>
              </YearAndMonth>
            </button>
            <NextYearIcon width={24} height={24} onClick={handleNextYear} />
          </TopItem>
        </TopWrapper>
      );
    }
  };
  const dayItem = () => {
    return (
      <>
        <StyleWeekRow>
          {headers.weekDays.map(({ key, value }) => {
            return (
              <DayText key={key}>{format(value, "E", { locale: ko })}</DayText>
            );
          })}
        </StyleWeekRow>

        {body.value.map((week) => {
          const { key, value: days } = week;

          return (
            <StyleWeekRow key={key}>
              {days.map((day) => {
                const {
                  key,
                  date,
                  isCurrentDate,
                  isCurrentMonth,
                  isWeekend,
                  value
                } = day;

                let holiday: string = "";
                if (holiList) {
                  const val = format(value.getTime(), "yyyy-MM-dd");

                  holiday = holiList[val];
                }

                const isSelected = !selectedDate
                  ? false
                  : moment(selectedDate, "YYYY-MM-DD").format("yyyy.MM.DD") ===
                    format(value.getTime(), "yyyy.MM.dd");

                return (
                  <DayText
                    key={key}
                    as="span"
                    className={cx(styles.day, {
                      isCurrentMonth,
                      isSelected,
                      isCurrentDate,

                      isWeekend,
                      holiday
                    })}
                    onClick={(e) => {
                      if (!isCurrentMonth) {
                        if (date < 20) {
                          handleNextMonth();
                        } else {
                          handlePrevMonth();
                        }
                        return;
                      }
                      if (isHoliday && holiday) {
                        return;
                      }
                      const val = `${selcYear}-${selcMonth}-${
                        date + "".length < 10 ? "0" + date : "" + date
                      }`;
                      handleDate(val);
                      setOpen(false);
                    }}
                  >
                    {date}
                  </DayText>
                );
              })}
            </StyleWeekRow>
          );
        })}

        {body.value?.length < 6 && (
          <>
            {[...Array(6 - body.value?.length)]?.map((i, idx) => {
              return (
                <StyleWeekRow>
                  {[...Array(7)]?.map((item, index) => {
                    const _next = body.value[body.value.length - 1].value;
                    if (!_next) return;
                    if (Number(_next)) return;
                    const _val = _next[_next.length - 1].value;
                    let _date = _next[_next.length - 1].date;

                    if (Number(moment(_val).daysInMonth()) === _date) _date = 0;

                    return (
                      <DayText
                        key={"nextD_" + idx * 7 + index + 1}
                        as="span"
                        className={cx(styles.day)}
                        onClick={handleNextMonth}
                      >
                        {_date + idx * 7 + index + 1}
                      </DayText>
                    );
                  })}
                </StyleWeekRow>
              );
            })}
          </>
        )}
      </>
    );
  };

  const monthItem = () => {
    return (
      <CP.Flex direction="column" gap={"10px"}>
        {[...Array(4)].map((item, idx) => (
          <CP.Flex gap={"6px"}>
            {[...Array(3)].map((item, index) => {
              const month = index + 1 + idx * 3;
              return (
                <StyleWeekRow
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "center"
                  }}
                >
                  <button onClick={() => onChangeMonth(month)}>
                    <S.StyledText weight={"600"}>{month + "월"}</S.StyledText>
                  </button>
                </StyleWeekRow>
              );
            })}
          </CP.Flex>
        ))}
      </CP.Flex>
    );
  };
  const yearItem = () => {
    return (
      <>
        <CP.Flex direction="column" gap={"10px"}>
          {[...Array(4)].map((item, idx) => (
            <CP.Flex gap={"6px"}>
              {[...Array(3)].map((item, index) => {
                const preYear = Number(
                  selcYear.substring(0, selcYear.length - 1) + 0
                );
                const _year = preYear - 1 + (index + idx * 3) + "";

                return (
                  <StyleWeekRow
                    style={{
                      display: "flex",
                      flex: 1,
                      justifyContent: "center"
                    }}
                  >
                    <button
                      onClick={() => {
                        if (type === "year") {
                          handleDate(_year + "-" + selcMonth);
                          setOpen(false);
                          return;
                        }
                        if (idx + index === 0) {
                          setSelcYear(
                            moment(selcYear, "YYYY")
                              .add(-10, "y")
                              .format("YYYY")
                          );
                        } else if (idx === 3 && index === 2) {
                          setSelcYear(
                            moment(selcYear, "YYYY").add(10, "y").format("YYYY")
                          );
                        } else {
                          setSelcYear(_year);
                          setViewType("month");
                        }
                      }}
                    >
                      <S.StyledText
                        weight={"600"}
                        color={
                          index + idx === 0 || (index === 2 && idx === 3)
                            ? "#ccc"
                            : "#222"
                        }
                      >
                        {_year}
                      </S.StyledText>
                    </button>
                  </StyleWeekRow>
                );
              })}
            </CP.Flex>
          ))}
        </CP.Flex>
      </>
    );
  };

  const datePickerItem = () => {
    return (
      <DatePickerWrapper ref={ref}>
        {headerItem()}

        <CP.Flex direction="column" justify="center" gap={"4px"}>
          {viewType === "day"
            ? dayItem()
            : viewType === "month"
              ? monthItem()
              : yearItem()}

          <S.StyledATag
            style={{ textAlign: "center", fontSize: 12 }}
            onClick={() => {
              navigation.setToday();
              setSelcMonth(moment().format("MM"));
              setSelcYear(moment().format("YYYY"));
            }}
          >
            오늘
          </S.StyledATag>
        </CP.Flex>
      </DatePickerWrapper>
    );
  };

  return (
    <Dropdown
      dropdownRender={datePickerItem}
      placement="top"
      trigger={["click"]}
      open={open}
    >
      {content ? (
        <button
          onClick={(e) => {
            if (disabled) return;

            setOpen(!open);
          }}
          style={{ background: "transparent" }}
        >
          {content}
        </button>
      ) : (
        <div
          tabIndex={0}
          onClick={(e) => {
            if (disabled) return;

            setOpen(!open);
          }}
          className={cx(styles.dateButton, { open })}
          style={{
            border: error ? "1px solid #FF6969" : "1px solid #999",
            background: disabled ? "#eaeaea" : "#fff",
            ...customStyle
          }}
        >
          <CalendarIcon stroke="#999" width={24} height={24} />
          <InputValue>
            {selectedDate
              ? type === "day"
                ? moment(selectedDate, "YYYY-MM-DD").format("YYYY.MM.DD")
                : moment(selectedDate, "YYYY-MM").format("YYYY.MM")
              : placeholder}
          </InputValue>
        </div>
      )}
    </Dropdown>
  );
}

const StyleWeekRow = styled.div`
  padding: 0px 8px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: space-between;
`;

const DayText = styled.div`
  padding: 8px 9.4px;
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 120%;
  color: ${({ theme }) => theme.colors.black222};
  &:nth-child(1) {
    color: #ff6969;
  }
  &:nth-child(7) {
    color: #0085ff;
  }
  &:hover {
    background-color: #f0eeff;
  }
`;
const Typography = styled.span``;

const InputValue = styled.span`
  flex: 1;
  color: #999;
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  white-space: nowrap;
`;

const CloseButton: any = styled(CloseIcon)`
  align-self: flex-end;
  margin: 8px;
  cursor: pointer;
`;

const TopWrapper = styled.div`
  padding: 8px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #f9f9f9;
`;

const TopItem = styled.div`
  display: flex;
  gap: 16px;
  justify-content: center;
  align-items: center;
`;

const Arrow = styled(ArrowIcon)`
  cursor: pointer;
`;

const PrevYearIcon = styled(Arrow)`
  transform: rotate(180deg);
  &:hover > path {
    stroke: #6c62d1;
  }
`;
const NextYearIcon = styled(Arrow)`
  &:hover > path {
    stroke: #6c62d1;
  }
`;
const PrevMonthIcon = styled(Arrow)`
  transform: rotate(180deg);
  &:hover > path {
    stroke: #6c62d1;
  }
`;
const NextMonthIcon = styled(Arrow)`
  &:hover > path {
    stroke: #6c62d1;
  }
`;

const YearAndMonth = styled(S.StyledText)`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: ${({ theme }) => theme.colors.black222};
`;

const DatePickerWrapper = styled.div`
  max-width: 290px;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 16px;
  padding: 8px 0px;

  & button {
    background: transparent;
  }
`;

const MonthPickerWrapper = styled(DatePickerWrapper)`
  & button {
    background: transparent;
  }
`;
