import Chart from "core/components/common/Chart";
import { alertComp as alert, confirmComp as confirm } from "./Alert";
import BottomMenu from "./BottomMenu";
import Button from "./Button";
import ButtonGroup from "./ButtonGroup";
import CheckBox from "./CheckBox";
import DataTable from "./DataTable";
import DatePicker from "./DatePicker2";
// import DatePicker from "./DatePicker";
import Divider from "./Divider";
import Flex from "./Flex";
import * as Icon from "./icons";
import Input from "./Input2";
import Loading from "./Loading";
import Modal from "./Modal";
import MultiFileInput from "./MultiFileInput";
import OrgTree from "./OrgTree";
import Radio from "./Radio";
import SearchInput from "./SearchInput";
import Select from "./Select";
import SmallCheckBox from "./SmallCheckBox";
import SmallInput from "./SmallInput";
import SmallRadio from "./SmallRadio";
import Tag from "./Tag";
import Title from "./Title";
import Tooltip from "./Tooltip";
import TotalDataTable from "./TotalDataTable";
import Tree from "./Tree";
import Upload from "./Upload";
// import Editor from './Editor/Editor';
import dynamic from "next/dynamic";
import Calendar from "./Calendar";
import MonthCalendar from "./Calendar/Month";
import YearCalendar from "./Calendar/Year";
import MonthPicker from "./MonthPicker";
import Period from "./Period";
import StaffSearchInput from "./StaffSearchInput";
import Toast from "./Toast";

const Editor = dynamic(() => import("./Editor/Editor"), {
  ssr: false
});

const CP: any = {
  Select,
  Radio,
  CheckBox,
  SmallCheckBox,
  SmallInput,
  SmallRadio,
  // Tag,
  Input,
  SearchInput,
  Button,
  ButtonGroup,
  Upload,
  DataTable,
  Title,
  DatePicker,
  Icon,
  BottomMenu,
  Modal,
  Divider,
  Tag,
  Flex,
  MultiFileInput,
  Tree,
  Loading,
  alert,
  confirm,
  Editor,
  Chart,
  Tooltip,
  TotalDataTable,
  MonthCalendar,
  YearCalendar,
  StaffSearchInput,
  MonthPicker,
  Period,
  OrgTree,
  Toast,
  Calendar
};

export default CP;
